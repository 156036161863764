import Moment from "moment";
import {
  auditStoreDefaults,
  battersBoxEdges,
  battersBoxes,
  depthP1,
  diffSzTopWaist,
  plateCorners,
  plateCoordinatesXYZ,
  strikeZoneBoundaries3D,
  foulLines,
  moundCircle
} from "./AuditPcStoreConstants";
import { CanvasUtilities } from "../../utilities/CanvasUtilities";
import { AlertConstants } from "../../components/common/alert/AlertConstants";
import MathUtil from "../../utilities/MathUtil";
import StringUtil from "../../utilities/StringUtil";
import { DateConstants } from "../../components/common/constants/DateConstants";
import { VideoConstants } from "../../components/common/constants/VideoConstants";

const CAL_REFERENCE = process.env.REACT_APP_CAL_REFERENCE ? process.env.REACT_APP_CAL_REFERENCE === "true" : false;

export default class AuditPcStoreFunctions {
  constructor(alertStore, auditPcStore, loadingStore, zeApi) {
    this.alertStore = alertStore;
    this.auditPcStore = auditPcStore;
    this.loadingStore = loadingStore;
    this.zeApi = zeApi;

    this.defaultZone = this.defaultZone.bind(this);
    this.snapToKeyframeOnNewPitch = this.snapToKeyframeOnNewPitch.bind(this);
    this.refreshPitch = this.refreshPitch.bind(this);
    this.trackTime = this.trackTime.bind(this);
  }

  clearAllUnusedCoordinates() {
    const { sortedPitches, sortedPitchIdx } = this.auditPcStore;
    const cleanThreshold = 5;
    if (sortedPitchIdx < cleanThreshold || sortedPitchIdx >= sortedPitches.length - 1) {
      return;
    }
    for (let idx = 0; idx <= sortedPitchIdx - cleanThreshold; idx++) {
      const pitch = sortedPitches[idx];
      if (pitch) {
        this.clearXyCoordinates(pitch);
      }
    }
  }

  clearAllCoordinates() {
    const { sortedPitches } = this.auditPcStore;

    for (let idx = 0; idx <= sortedPitches.length; idx++) {
      const pitch = sortedPitches[idx];
      if (pitch) {
        this.clearXyCoordinates(pitch);
      }
    }
  }

  clearXyCoordinates(pitch) {
    if (pitch.xyCoordinatesMap) {
      const clearPitch = {
        pitch: pitch
      };
      // Note: We deliberately do not clear out xyWaistKneeMap, because that is needed to save keyframe successfully
      this.auditPcStore.setXyCoordinatesMap(clearPitch);
      this.auditPcStore.setXyTargetDepthCoordinatesMap(clearPitch);
      this.auditPcStore.setPlateCoordinatesMap(clearPitch);
      this.auditPcStore.setMoundCircleMap(clearPitch);
      this.auditPcStore.setHomeSecondAxisMap(clearPitch);
      this.auditPcStore.setXySzCoordinatesMap(clearPitch);
      this.auditPcStore.setXyWaistKneeBoundsMap(clearPitch);
      this.auditPcStore.setFoulLineCoordinatesMap(clearPitch);
      this.auditPcStore.setBattersBoxesMap(clearPitch);
    }
  }

  comparePitches(pitchA, pitchB) {
    const sort = this.auditPcStore.sort;
    switch (sort.col) {
      case "At Bat":
        return sort.asc ? pitchA.atBatNumber - pitchB.atBatNumber : pitchB.atBatNumber - pitchA.atBatNumber;
      case "AB Pitch":
        return sort.asc ? pitchA.pitchOfAtBat - pitchB.pitchOfAtBat : pitchB.pitchOfAtBat - pitchA.pitchOfAtBat;
      case "Batter":
        return sort.asc
          ? pitchA.batterName.localeCompare(pitchB.batterName)
          : pitchB.batterName.localeCompare(pitchA.batterName);
      case "Batter Side":
        return sort.asc
          ? pitchA.batterSide.localeCompare(pitchB.batterSide)
          : pitchB.batterSide.localeCompare(pitchA.batterSide);
      case "Call":
        return sort.asc
          ? pitchA.umpireCallCode.localeCompare(pitchB.umpireCallCode)
          : pitchB.umpireCallCode.localeCompare(pitchA.umpireCallCode);
      case "Inning":
        let pitchAInning = pitchA.inning + (pitchA.topOfInning ? 0.1 : 0.2);
        let pitchBInning = pitchB.inning + (pitchB.topOfInning ? 0.1 : 0.2);
        return sort.asc ? pitchAInning - pitchBInning : pitchBInning - pitchAInning;
      case "Pitch":
        return sort.asc ? pitchA.pitchNumber - pitchB.pitchNumber : pitchB.pitchNumber - pitchA.pitchNumber;
      case "Pitch Type":
        return sort.asc
          ? pitchA.pitchType.localeCompare(pitchB.pitchType)
          : pitchB.pitchType.localeCompare(pitchA.pitchType);
      case "Pitcher":
        return sort.asc
          ? pitchA.pitcherName.localeCompare(pitchB.pitcherName)
          : pitchB.pitcherName.localeCompare(pitchA.pitcherName);
      case "Play ID":
        return sort.asc ? pitchA.playId.localeCompare(pitchB.playId) : pitchB.playId.localeCompare(pitchA.playId);
      default:
        return sort.asc ? pitchA.pitchNumber - pitchB.pitchNumber : pitchB.pitchNumber - pitchA.pitchNumber;
    }
  }

  convertChTimestamp(chStartTimestamp) {
    // TODO use actual year of game
    let year = 2020;
    let msThisYear = ((chStartTimestamp + 2.589) / ((30 * 1000) / 1001)) * 1000;
    let dateString = year + "-01-01T00:00:00Z";
    return Moment.utc(dateString).add(msThisYear, "ms");
  }

  convertToTopLeftOrigin(xy) {
    /*return {
      x: 960 - 960 * xy.x,
      y: 540 - 540 * xy.y
    };*/
    return {
      x: 960 + 960 * xy.x,
      y: 540 - 540 * xy.y
    };
  }

  convertToCenterOrigin(xy) {
    /*return {
      x: (960 - xy.x) / 960,
      y: (540 - xy.y) / 540
    };*/
    return {
      x: (xy.x - 960) / 960,
      y: (540 - xy.y) / 540
    };
  }

  copyProblemPitchesToClipboard() {
    let _this = this;
    let { gamePk, gameDisplayName, pitchNumbersMap, problemPitchInfo, problemPitchList } = this.auditPcStore;

    let gameSummary = gamePk + "\n" + gameDisplayName + "\n\n";
    let problemSummaries = [];
    problemPitchList.forEach(function(problem) {
      let summary = problem.title + "\t" + problem.total + " / " + problem.called + "\n";
      let info = problemPitchInfo[problem.key];
      info.forEach(function(pitchInfo) {
        let pitch = pitchNumbersMap[pitchInfo.pitchNumber];
        if (pitch) {
          summary = summary.concat(
            _this.getSequenceString(pitch),
            "\t",
            pitch.playId,
            "\t",
            pitch.umpireCallCode,
            "\n"
          );
        }
      });
      summary = summary.concat("\n");
      problemSummaries.push(summary);
    });
    let problemSummary = "".concat(gameSummary, ...problemSummaries).trim();

    StringUtil.copyToClipboard(problemSummary);
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Copied problem pitch data to clipboard."
    });
  }

  copyStrikeZonesToClipboard() {
    let _this = this;
    let { gamePk, gameDisplayName, gameDisplayTime, pitches } = _this.auditPcStore;

    let strikeZoneSummaries = [];
    strikeZoneSummaries.push(
      gamePk +
        "\n" +
        gameDisplayName +
        "\n" +
        (gameDisplayTime ? Moment(gameDisplayTime).format(DateConstants.DATE_FORMAT_WITH_TIME) : "") +
        "\n\nsequence,playId,edited,szTop,szBottom\n"
    );
    pitches.forEach(p => {
      strikeZoneSummaries.push(
        _this.getSequenceString(p) +
          "," +
          p.playId +
          "," +
          (p.dirty ? "true" : "false") +
          "," +
          (p.szTop ? MathUtil.roundToNPlaces(p.szTop, 3) : null) +
          "," +
          (p.szBottom ? MathUtil.roundToNPlaces(p.szBottom, 3) : null) +
          "\n"
      );
    });
    const strikeZoneSummary = "".concat(...strikeZoneSummaries).trim();

    StringUtil.copyToClipboard(strikeZoneSummary);
    _this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Copied strike zone data to clipboard."
    });
  }

  cutFrames(plays) {
    let cutFramesObj = {
      plays: [],
      feedType: "PITCHCAST"
    };
    plays.forEach(p => {
      cutFramesObj.plays.push({
        gamePk: this.auditPcStore.gamePk,
        playId: p.playId,
        feedType: cutFramesObj.feedType
      });
    });
    this.zeApi.cutFrames(cutFramesObj).then(data => {
      this.loadingStore.setLoading(false);
      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Successfully cut frames for " + plays.length + " pitches."
      });
    });
  }

  defaultZone() {
    const pitch = this.auditPcStore.selectedPitch;
    const players = this.auditPcStore.players;
    const player = players[pitch.batterId];
    this.updatePitchStrikeZone(pitch, [player.strikeZoneTop, player.strikeZoneBottom]);
    this.auditPcStore.setPitchDirty(pitch, true);
    this.getXyWaistKnee(pitch);
  }

  extractCalibrations() {
    this.zeApi.extractCalibrations(this.auditPcStore.gamePk);
  }

  getFrameIndex(videoTimestamp) {
    if (videoTimestamp !== 0 && !videoTimestamp) {
      videoTimestamp = 3.2;
    }
    return Math.floor(videoTimestamp * 60);
  }

  getCoordinates(pitch) {
    const _this = this;
    const { gamePk, targetDepth } = _this.auditPcStore;
    if (pitch && pitch.playId) {
      if (!this.coordinatesMapFilled(pitch)) {
        this.zeApi.getPitch(gamePk, pitch.playId).then(pitchWithTrajectory => {
          if (pitch && pitchWithTrajectory) {
            const { lastMeasuredData, releaseData, trajectory } = pitchWithTrajectory;
            pitch.xyzCoordinates = _this.getPitchPositionsXYZ(
              pitch,
              releaseData,
              trajectory,
              lastMeasuredData,
              targetDepth
            );
            _this.getXyCoordinates(pitch);
          }
        });
      } else {
        _this.getXyCoordinates(pitch);
      }
    }
  }

  getClosestCalibrationIndexWithCoordinates(xyCoordinatesMap, currentIndex) {
    if (!xyCoordinatesMap || !currentIndex || !Object.keys(xyCoordinatesMap)) {
      return currentIndex;
    }
    let distance = Number.MAX_SAFE_INTEGER;
    let index = currentIndex;
    Object.keys(xyCoordinatesMap).forEach(key => {
      let abs = Math.abs(key - currentIndex);
      if (abs < distance) {
        distance = abs;
        index = key;
      }
    });
    return index;
  }

  getCurrentFrameIndex() {
    const { videoRef } = this.auditPcStore;
    if (!videoRef) {
      return;
    }
    return this.getFrameIndex(videoRef.currentTime);
  }

  getPitchPositionsXYZ(pitch, release, trajectory, lastMeasuredData, targetDepth) {
    let positions = [];
    if (!(pitch && release && trajectory)) {
      return positions;
    }

    let lastMeasuredY = 1.417;
    if (lastMeasuredData && lastMeasuredData.position) {
      lastMeasuredY = lastMeasuredData.position.y;
    }
    if (this.isCalledPitch(pitch) && lastMeasuredY >= -1) {
      lastMeasuredY = -1;
    }
    let end = {
      time: this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        lastMeasuredY
      )
    };
    end.position = this.getPitchPositionXYZ(trajectory, end.time);
    let frontOfPlate = {
      time: this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        1.417
      )
    };
    frontOfPlate.position = this.getPitchPositionXYZ(trajectory, frontOfPlate.time, 0);
    let backOfPlate = {
      time: this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        0
      )
    };
    backOfPlate.position = this.getPitchPositionXYZ(trajectory, backOfPlate.time, 0);

    let previousPosition;
    let target = {};
    for (let y = 50; y >= -1; y--) {
      let time = this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        y
      );
      let position = this.getPitchPositionXYZ(trajectory, time);
      if (previousPosition) {
        if (this.isInBetween(previousPosition.y, position.y, frontOfPlate.position.y)) {
          positions.push(frontOfPlate.position);
        } else if (this.isInBetween(previousPosition.y, position.y, backOfPlate.position.y)) {
          positions.push(backOfPlate.position);
        }
        if (y === targetDepth) {
          target.position = position;
        }
      }
      positions.push(position);
      previousPosition = position;
    }

    return {
      positions: positions,
      target: target,
      frontOfPlate: frontOfPlate,
      backOfPlate: backOfPlate
    };
  }

  getPitchPositionXYZ(trajectory, t, fixedY) {
    let position = {};
    position.x = this.polynomial(trajectory.polynomialX[2], trajectory.polynomialX[1], trajectory.polynomialX[0], t);
    if (fixedY) {
      position.y = fixedY;
    } else {
      position.y = this.polynomial(trajectory.polynomialY[2], trajectory.polynomialY[1], trajectory.polynomialY[0], t);
    }
    position.z = this.polynomial(trajectory.polynomialZ[2], trajectory.polynomialZ[1], trajectory.polynomialZ[0], t);
    return position;
  }

  getProblemPitchInfo() {
    this.zeApi.getProblemPitchInfo(this.auditPcStore.gamePk).then(data => {
      this.auditPcStore.setProblemPitchInfo(data);
    });
  }

  getSequenceString(pitch) {
    return (pitch.topOfInning ? "T" : "B") + pitch.inning + "  " + pitch.atBatNumber + "-" + pitch.pitchOfAtBat;
  }

  coordinatesMapFilled(pitch) {
    return (
      pitch.xyCoordinatesMap &&
      pitch.xyTargetDepthCoordinatesMap &&
      pitch.plateCoordinatesMap &&
      pitch.xySzCoordinatesMap &&
      pitch.xyWaistKneeMap &&
      pitch.xyWaistKneeBoundsMap &&
      pitch.foulLineCoordinatesMap &&
      pitch.battersBoxesMap &&
      (CAL_REFERENCE
        ? // !pitch.moundCircleMap &&
          !pitch.homeSecondAxisMap
        : true)
    );
  }

  getXyCoordinates(pitch) {
    if (!this.coordinatesMapFilled(pitch)) {
      this.getXyPitch(pitch);
      this.getXyPlateOutline(pitch);
      this.getXySzBox(pitch);
      this.getXyWaistKnee(pitch);
      this.getXyWaistKneeBounds(pitch);
      this.getXyFoulLines(pitch);
      this.getXyBattersBoxes(pitch);
      if (CAL_REFERENCE) {
        // this.getXyMoundCircle(pitch);
        this.getXyHomeSecondAxis(pitch);
      }
    }
  }

  getXyBattersBoxes(pitch) {
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        battersBoxes,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(battersBoxesMap => {
        if (battersBoxesMap) {
          let battersBoxesMapTransformed = {};
          Object.keys(battersBoxesMap).forEach(key => {
            let battersBoxes = battersBoxesMap[key];
            battersBoxesMapTransformed[key] = battersBoxes.map(xy => this.convertToTopLeftOrigin(xy));
          });
          this.auditPcStore.setBattersBoxesMap({
            pitch: pitch,
            battersBoxesMap: battersBoxesMapTransformed
          });
        }
      });
  }

  getXyFoulLines(pitch) {
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        foulLines,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(foulLineCoordinatesMap => {
        if (foulLineCoordinatesMap) {
          let foulLineCoordinatesMapTransformed = {};
          Object.keys(foulLineCoordinatesMap).forEach(key => {
            let foulLineCoordinates = foulLineCoordinatesMap[key];
            foulLineCoordinatesMapTransformed[key] = foulLineCoordinates.map(xy => this.convertToTopLeftOrigin(xy));
          });
          this.auditPcStore.setFoulLineCoordinatesMap({
            pitch: pitch,
            foulLineCoordinatesMap: foulLineCoordinatesMapTransformed
          });
        }
      });
  }

  getXyHomeSecondAxis(pitch) {
    let xyzCoordinates = [];
    for (let i = 0; i < 50; i++) {
      xyzCoordinates.push({
        x: 0,
        y: i * 2,
        z: 0
      });
    }
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        xyzCoordinates,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(axisMap => {
        if (axisMap) {
          let axisMapTransformed = {};
          Object.keys(axisMap).forEach(key => {
            let axis = axisMap[key];
            axisMapTransformed[key] = axis.map(xy => this.convertToTopLeftOrigin(xy));
          });
          this.auditPcStore.setHomeSecondAxisMap({
            pitch: pitch,
            homeSecondAxisMap: axisMapTransformed
          });
        }
      });
  }

  getXyPitch(pitch) {
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        pitch.xyzCoordinates.positions,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(xyCoordinatesMap => {
        if (xyCoordinatesMap) {
          let xyCoordinatesTransformed = {};
          Object.keys(xyCoordinatesMap).forEach(key => {
            xyCoordinatesTransformed[key] = xyCoordinatesMap[key].map(xy => this.convertToTopLeftOrigin(xy));
          });
          this.auditPcStore.setXyCoordinatesMap({
            pitch: pitch,
            xyCoordinatesMap: xyCoordinatesTransformed
          });
          this.getXyTargetDepthCoordinates(pitch);
        }
      });
  }

  getXyTargetDepthCoordinates(pitch) {
    let xyCoordinatesMap = pitch.xyCoordinatesMap;
    if (!xyCoordinatesMap) {
      return;
    }
    let index = pitch.xyzCoordinates.positions.findIndex(pos => Math.round(pos.y) === this.auditPcStore.targetDepth);
    let xyTargetDepthCoordinatesMap = {};
    Object.keys(xyCoordinatesMap).forEach(key => {
      let xyCoordinates = xyCoordinatesMap[key];
      xyTargetDepthCoordinatesMap[key] = xyCoordinates[index];
    });
    this.auditPcStore.setXyTargetDepthCoordinatesMap({
      pitch: pitch,
      xyTargetDepthCoordinatesMap: xyTargetDepthCoordinatesMap
    });
  }

  getXyPlateOutline(pitch) {
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        plateCoordinatesXYZ,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(plateCoordinatesMap => {
        if (plateCoordinatesMap) {
          let plateCoordinatesMapTransformed = {};
          Object.keys(plateCoordinatesMap).forEach(key => {
            let plateCoordinates = plateCoordinatesMap[key];
            plateCoordinatesMapTransformed[key] = plateCoordinates.map(xy => this.convertToTopLeftOrigin(xy));
          });
          this.auditPcStore.setPlateCoordinatesMap({
            pitch: pitch,
            plateCoordinatesMap: plateCoordinatesMapTransformed
          });
        }
      });
  }

  getXySzBox(pitch) {
    let xyzSzCoordinates = this.getXyzSzCoordinates(pitch.szTop, pitch.szBottom);
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        xyzSzCoordinates,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(xySzCoordinatesMap => {
        if (xySzCoordinatesMap) {
          let xySzConverted = {};
          Object.keys(xySzCoordinatesMap).forEach(key => {
            let xySzTransformed = xySzCoordinatesMap[key].map(xy => this.convertToTopLeftOrigin(xy));
            xySzConverted[key] = {
              bottomLeft: xySzTransformed[0],
              topLeft: xySzTransformed[1],
              topRight: xySzTransformed[2],
              bottomRight: xySzTransformed[3]
            };
          });
          this.auditPcStore.setXySzCoordinatesMap({
            pitch: pitch,
            xySzCoordinatesMap: xySzConverted
          });
        }
      });
  }

  getXyWaistKnee(pitch) {
    let xyzWaistKneeCoordinates = this.getXyzWaistKneeCoordinates(pitch);
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        xyzWaistKneeCoordinates,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(xyWaistKneeMap => {
        if (xyWaistKneeMap) {
          let xyWaistKneeMapTransformed = {};
          Object.keys(xyWaistKneeMap).forEach(key => {
            let xyWaistKneeCh = xyWaistKneeMap[key];
            let xyWaistKneeCanvas = xyWaistKneeCh.map(xy => this.convertToTopLeftOrigin(xy));
            xyWaistKneeMapTransformed[key] = {
              waist: xyWaistKneeCanvas.slice(0, 2),
              knee: xyWaistKneeCanvas.slice(2, 4)
            };
          });
          this.auditPcStore.setXyWaistKneeMap({
            pitch: pitch,
            xyWaistKneeMap: xyWaistKneeMapTransformed
          });
        }
      });
  }

  getXyWaistKneeBounds(pitch) {
    let xyzWaistKneeCoordinates = this.getXyzWaistKneeBoundsCoordinates(pitch.batterSide);
    this.zeApi
      .forwardProjectV2(
        this.auditPcStore.gamePk,
        pitch.playId,
        xyzWaistKneeCoordinates,
        this.auditPcStore.smoothing,
        this.auditPcStore.roll
      )
      .then(xyWaistKneeMap => {
        if (xyWaistKneeMap) {
          let xyWaistKneeBoundsMapTransformed = {};
          Object.keys(xyWaistKneeMap).forEach(key => {
            let xyWaistKneeCh = xyWaistKneeMap[key];
            let xyWaistKneeCanvas = xyWaistKneeCh.map(xy => this.convertToTopLeftOrigin(xy));
            xyWaistKneeBoundsMapTransformed[key] = {
              waistUpper: xyWaistKneeCanvas.slice(0, 2),
              waistLower: xyWaistKneeCanvas.slice(2, 4),
              kneeUpper: xyWaistKneeCanvas.slice(4, 6),
              kneeLower: xyWaistKneeCanvas.slice(6, 8)
            };
          });
          this.auditPcStore.setXyWaistKneeBoundsMap({
            pitch: pitch,
            xyWaistKneeBoundsMap: xyWaistKneeBoundsMapTransformed
          });
        }
      });
  }

  getXyWaistKneeByIndex(pitch, index) {
    if (!(pitch && pitch.xyWaistKneeMap && index)) {
      return;
    }
    const xyWaistKneeMap = pitch.xyWaistKneeMap;
    return xyWaistKneeMap[index];
  }

  getXyzSzCoordinates(szTop, szBottom) {
    const { firstBaseFront, thirdBaseFront } = plateCorners;

    let coordinates = [];
    coordinates.push(
      {
        x: firstBaseFront.x,
        y: firstBaseFront.y,
        z: szBottom
      },
      {
        x: firstBaseFront.x,
        y: firstBaseFront.y,
        z: szTop
      },
      {
        x: thirdBaseFront.x,
        y: thirdBaseFront.y,
        z: szTop
      },
      {
        x: thirdBaseFront.x,
        y: thirdBaseFront.y,
        z: szBottom
      }
    );
    return coordinates;
  }

  getXyzWaistKneeBoundsCoordinates(batterSide) {
    const righty = batterSide === "R";
    const innerX = righty ? plateCorners.thirdBaseFront.x : plateCorners.firstBaseFront.x;
    const outerX = righty ? battersBoxEdges.right.outer : battersBoxEdges.left.outer;

    let coordinates = [];
    coordinates.push(
      {
        x: innerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szTopUpper
      },
      {
        x: outerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szTopUpper
      },
      {
        x: innerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szTopLower
      },
      {
        x: outerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szTopLower
      },
      {
        x: innerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szBottomUpper
      },
      {
        x: outerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szBottomUpper
      },
      {
        x: innerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szBottomLower
      },
      {
        x: outerX,
        y: depthP1,
        z: strikeZoneBoundaries3D.szBottomLower
      }
    );
    return coordinates;
  }

  getXyzWaistKneeCoordinates(pitch) {
    const righty = pitch.batterSide === "R";
    const innerX = righty ? plateCorners.thirdBaseFront.x : plateCorners.firstBaseFront.x;
    const outerX = righty ? battersBoxEdges.right.outer : battersBoxEdges.left.outer;
    const waist = pitch.szTop - 2.5 / 12;

    let coordinates = [];
    coordinates.push(
      {
        x: innerX,
        y: depthP1,
        z: waist
      },
      {
        x: outerX,
        y: depthP1,
        z: waist
      },
      {
        x: innerX,
        y: depthP1,
        z: pitch.szBottom
      },
      {
        x: outerX,
        y: depthP1,
        z: pitch.szBottom
      }
    );
    return coordinates;
  }

  handleRefreshPitch(pitch, data) {
    pitch.umpireCallCode = data.umpireCallCode;
    pitch.pitchType = data.pitchType;
    pitch.szTop = data.szTop;
    pitch.szBottom = data.szBottom;
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Pitch " + pitch.pitchNumber + " has been refreshed."
    });
  }

  initializeOrAnalyze(exclude) {
    const { dirtyPitches, gamePk, oobPitches } = this.auditPcStore;
    this.zeApi.getGames(gamePk).then(data => {
      if (data.entities && !data.entities.some(g => g.status === "FINALIZED")) {
        if (data.entities.some(g => g.status === "INITIALIZED")) {
          let oobPitchesIds = oobPitches.map(p => p.playId);
          const pitches = dirtyPitches.filter(p => {
            if (exclude) {
              return !oobPitchesIds.includes(p.playId);
            } else {
              return true;
            }
          });
          this.zeApi.analyzePitches(gamePk, pitches);
        } else {
          this.zeApi.initializeGame(gamePk);
        }
      }
    });
  }

  isCalledPitch(pitch) {
    return pitch && (pitch.umpireCall === "B" || pitch.umpireCall === "*B" || pitch.umpireCall === "C");
  }

  isUmpireCallCodeCalled(pitch) {
    return (
      pitch &&
      (pitch.umpireCallCode === "Ball" ||
        pitch.umpireCallCode === "Ball In Dirt" ||
        pitch.umpireCallCode === "Blocked Ball" ||
        pitch.umpireCallCode === "Called Strike")
    );
  }

  isInBetween(boundA, boundB, position) {
    return (boundA <= position && position <= boundB) || (boundB <= position && position <= boundA);
  }

  oobPitches(pitches, players) {
    return pitches
      .filter(p => {
        const player = players[p.batterId];
        return (
          p.szTop > player.strikeZoneTopMax ||
          p.szTop < player.strikeZoneTopMin ||
          p.szBottom > player.strikeZoneBottomMax ||
          p.szBottom < player.strikeZoneBottomMin
        );
      })
      .map(q => {
        const player = players[q.batterId];
        let pitch = Object.assign({}, q);
        pitch.topFlag = q.szTop > player.strikeZoneTopMax || q.szTop < player.strikeZoneTopMin;
        pitch.bottomFlag = q.szBottom > player.strikeZoneBottomMax || q.szBottom < player.strikeZoneBottomMin;
        return pitch;
      });
  }

  polynomial(a, b, c, t) {
    return a * Math.pow(t, 2) + b * t + c;
  }

  polynomialSolveForTime(a, b, c, y) {
    // convert to form a(t^2) + bt + c = 0
    c -= y;
    // now solve for t
    return -((b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a));
  }

  preload() {
    const { sortedPitches, sortedPitchIdx } = this.auditPcStore;
    if (sortedPitchIdx < 0 || sortedPitchIdx >= sortedPitches.length - 1) {
      return;
    }
    let count = 1;
    let videos = [];
    for (let idx = sortedPitchIdx + 1; count <= 2 && idx < sortedPitches.length; idx++) {
      const pitch = sortedPitches[idx];
      this.getCoordinates(pitch);
      if (pitch && pitch.videos) {
        videos.push(pitch.videos[0]);
      }
      count++;
    }
    this.auditPcStore.setPreloadVideos(videos);
  }

  refreshPitch() {
    const pitch = this.auditPcStore.selectedPitch;
    this.loadingStore.setLoading(true, "Refreshing", "Refreshing pitch", 50);
    this.zeApi.refreshPitch(this.auditPcStore.gamePk, pitch.playId).then(data => {
      this.handleRefreshPitch(pitch, data);
      this.loadingStore.setLoading(false, this.auditPcStore.windowText);
    });
  }

  saveAuditPitches() {
    const { selectedPitch } = this.auditPcStore;
    this.auditPcStore.setShowConfirmationModal(false);
    if (selectedPitch.dirty) {
      this.saveCurrentPitch(selectedPitch).then(() => {
        this.saveStrikeZones(false);
        this.saveKeyframes(false);
        this.saveConfiguration();
      });
    } else {
      this.saveStrikeZones(false);
      this.saveKeyframes(false);
      this.saveConfiguration();
    }
  }

  saveCalOnly() {
    const { sortedPitchIdx } = this.auditPcStore;
    this.loadingStore.setLoading(true, "Saving", "Saving Calibration Data", 75);
    this.saveConfiguration();
    this.saveKeyframes(true);
    this.auditPcStore.resetSelectedPitch();
    this.auditPcStore.getAuditInfo(sortedPitchIdx);
  }

  excludeAndSaveAuditPitches() {
    const { selectedPitch } = this.auditPcStore;
    this.auditPcStore.setShowConfirmationModal(false);
    if (selectedPitch.dirty) {
      this.saveCurrentPitch(selectedPitch).then(() => {
        this.saveStrikeZones(true);
        this.saveKeyframes(true);
        this.saveConfiguration();
      });
    } else {
      this.saveStrikeZones(true);
      this.saveKeyframes(true);
      this.saveConfiguration();
    }
  }

  saveCurrentPitch(currentPitch, nextPitch) {
    this.auditPcStore.setLoading(true);
    return new Promise((resolve, reject) => {
      if (currentPitch && currentPitch.dirty) {
        this.updateStrikeZone(currentPitch).then(() => {
          this.auditPcStore.setOobPitches(this.oobPitches(this.auditPcStore.dirtyPitches, this.auditPcStore.players));
          resolve();
        });
      }
      if (nextPitch) {
        if (this.auditPcStore.autoPaste) {
          this.auditPcStore.setCalibrationPanDown(nextPitch, currentPitch.calibrationPanDown);
          this.auditPcStore.setCalibrationPanRight(nextPitch, currentPitch.calibrationPanRight);
        }
        this.auditPcStore.setSelectedPitch(nextPitch);
      }
    });
  }

  saveKeyframes(exclude) {
    const { dirtyPitches, gamePk, oobPitches, strikeZoneEditable } = this.auditPcStore;
    const waistKneeLines = {};
    dirtyPitches.forEach(p => {
      const index = this.getFrameIndex(p.keyframeTs);
      const nearestIndex = this.getClosestCalibrationIndexWithCoordinates(p.xyWaistKneeMap, index);
      const waistKnee = this.getXyWaistKneeByIndex(p, nearestIndex);
      if (waistKnee) {
        const waistLine = p.deltaWaist
          ? waistKnee.waist.map(coord => CanvasUtilities.translateCoordinateXy(coord, 0, p.deltaWaist))
          : waistKnee.waist;
        const kneeLine = p.deltaKnee
          ? waistKnee.knee.map(coord => CanvasUtilities.translateCoordinateXy(coord, 0, p.deltaKnee))
          : waistKnee.knee;
        waistKneeLines[p.playId] = {
          waist: waistLine,
          knee: kneeLine
        };
      }
    });
    let oobPitchesIds = oobPitches.map(p => p.playId);

    const keyframePitches = dirtyPitches
      .filter(pitch => {
        return !exclude || !oobPitchesIds.includes(pitch.playId);
      })
      .filter(
        pitch =>
          pitch.szTop >= strikeZoneBoundaries3D.szTopLower &&
          pitch.szTop <= strikeZoneBoundaries3D.szTopUpper &&
          pitch.szBottom >= strikeZoneBoundaries3D.szBottomLower &&
          pitch.szBottom <= strikeZoneBoundaries3D.szBottomUpper
      )
      .map(p => {
        return {
          gamePk: gamePk,
          playId: p.playId,
          keyframeMs: p.keyframeTs ? p.keyframeTs * 1000 : 3200,
          waistLine: waistKneeLines[p.playId] ? waistKneeLines[p.playId].waist : null,
          kneeLine: waistKneeLines[p.playId] ? waistKneeLines[p.playId].knee : null,
          calibrationPanDown: p.calibrationPanDown,
          calibrationPanRight: p.calibrationPanRight
        };
      })
      .filter(kp => kp.waistLine && kp.kneeLine);
    this.zeApi
      .saveKeyframeTimes(gamePk, keyframePitches)
      .then(() => {
        if (strikeZoneEditable) {
          this.cutFrames(keyframePitches);
        } else {
          this.loadingStore.setLoading(false);
        }
      })
      .catch(() => {
        this.loadingStore.setLoading(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "An unexpected error occurred while saving calibration data."
        });
      });
  }

  saveConfiguration() {
    const { gamePk, roll, smoothing } = this.auditPcStore;
    const config = {
      gamePk: gamePk,
      rollDegrees: roll,
      smoothingFrames: smoothing,
      toolVersion: "v2"
    };
    this.zeApi
      .saveAuditConfiguration(config)
      .then(() => {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Successfully saved audit tool configuration."
        });
      })
      .catch(() => {
        this.loadingStore.setLoading(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "An unexpected error occurred while saving audit configuration."
        });
      });
  }

  saveStrikeZones(exclude) {
    const { dirtyPitches, gamePk, sortedPitchIdx, oobPitches } = this.auditPcStore;
    let oobPitchesIds = oobPitches.map(p => p.playId);
    const strikeZones = dirtyPitches
      .filter(pitch => {
        return !exclude || !oobPitchesIds.includes(pitch.playId);
      })
      .map(p => {
        return {
          gamePk: gamePk,
          playId: p.playId,
          szBottom: p.szBottom,
          szTop: p.szTop
        };
      });

    this.loadingStore.setLoading(true, "Audit", "Saving Strike Zones", 75);
    this.zeApi.saveStrikeZones(strikeZones).then(pitches => {
      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Successfully updated " + pitches.length + " strike zones."
      });
      this.initializeOrAnalyze();
      let pitchesToMark = exclude ? oobPitches : [];
      this.auditPcStore.resetSelectedPitch();
      this.auditPcStore.getAuditInfo(sortedPitchIdx, pitchesToMark);
    });
  }

  selectedPitchChanged() {
    let pitch = this.auditPcStore.selectedPitch;
    if (pitch && pitch !== {}) {
      let playId = pitch.playId;
      if (!window.location.hash && playId) {
        window.location.hash = playId;
      }
      this.getCoordinates(pitch);
    }
  }

  snapToKeyframe() {
    const { keyframeOffset, selectedPitch, targetDepth, videoRef } = this.auditPcStore;
    if (videoRef && videoRef.duration) {
      if (selectedPitch.updatedBy) {
        videoRef.currentTime = selectedPitch.keyframeTs;
      } else {
        videoRef.currentTime =
          3.2 + keyframeOffset * VideoConstants.ONE_FRAME + (auditStoreDefaults.targetDepth - targetDepth) * 0.008;
      }
    }
  }

  snapToKeyframeOnNewPitch() {
    if (this.auditPcStore.newPitchKeyframeFlag) {
      this.auditPcStore.setNewPitchKeyframeFlag(false);
      this.snapToKeyframe();
    }
  }

  snapToPlate() {
    const { keyframeOffset, selectedPitch, targetDepth, videoRef } = this.auditPcStore;
    if (videoRef && videoRef.duration) {
      if (selectedPitch.updatedOn) {
        videoRef.currentTime = selectedPitch.keyframeTs + targetDepth * 0.008;
      } else {
        videoRef.currentTime = 3.4 + keyframeOffset * VideoConstants.ONE_FRAME + targetDepth * 0.008;
      }
    }
  }

  trackTime(now, metadata) {
    const { setFrameInfo, videoRef } = this.auditPcStore;
    try {
      setFrameInfo(metadata);
    } finally {
      videoRef?.requestVideoFrameCallback(this.trackTime);
    }
  }

  updatePitchStrikeZone(pitch, heights) {
    pitch.szTop = heights[0] + diffSzTopWaist;
    pitch.szBottom = heights[1];
    pitch.deltaWaist = 0;
    pitch.deltaKnee = 0;
    this.auditPcStore.setPitchInList(pitch);
    return pitch;
  }

  updateStrikeZone(pitch) {
    if (!pitch || !pitch.xyCoordinatesMap || !this.auditPcStore) {
      return new Promise((resolve, reject) => {});
    }
    const { gamePk, roll } = this.auditPcStore;
    const index = this.getFrameIndex(pitch.keyframeTs);
    const nearestIndex = this.getClosestCalibrationIndexWithCoordinates(pitch.xyCoordinatesMap, index);
    const waistKnee = pitch.xyWaistKneeMap[nearestIndex];
    if (!(nearestIndex && waistKnee)) {
      this.auditPcStore.setPitchDirty(pitch, false);
      this.alertStore.addAlert({
        type: AlertConstants.TYPES.WARNING,
        text: "Pitch " + pitch.pitchNumber + " is missing calibration information, strike zone cannot be updated."
      });
      return new Promise((resolve, reject) => {});
    }
    const deltaWaist = pitch.deltaWaist ? pitch.deltaWaist : 0;
    const deltaKnee = pitch.deltaKnee ? pitch.deltaKnee : 0;
    const waistTranslated = CanvasUtilities.translateCoordinateXy(waistKnee.waist[0], 0, deltaWaist);
    const waistCenterOrigin = this.convertToCenterOrigin(waistTranslated);
    const kneeTranslated = CanvasUtilities.translateCoordinateXy(waistKnee.knee[0], 0, deltaKnee);
    const kneeCenterOrigin = this.convertToCenterOrigin(kneeTranslated);

    return new Promise((resolve, reject) => {
      let backProjectionRequest = {
        gamePk: gamePk,
        playId: pitch.playId,
        frameIndex: nearestIndex,
        roll: roll,
        imageCoordinates: [
          {
            imageCoordinate: waistCenterOrigin,
            depth: depthP1
          },
          {
            imageCoordinate: kneeCenterOrigin,
            depth: depthP1
          }
        ]
      };
      this.zeApi.backProjectV2(backProjectionRequest).then(heights => {
        this.updatePitchStrikeZone(pitch, heights);
        this.getXySzBox(pitch);
        this.getXyWaistKnee(pitch);
        resolve();
      });
    });
  }
}
