import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import HrTitle from "../../../common/title/HrTitle";
import ZeLabel from "../../../elements/form/ZeLabel";
import ZeNumberNoSpinner from "../../../elements/form/ZeNumberNoSpinner";
import { AlertConstants } from "../../../common/alert/AlertConstants";

const ApplyButton = styled.button.attrs({
  className: "btn btn-sm btn-primary float-right"
})``;

const RadioLabel = styled.label`
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;

const RadioInput = styled.input.attrs({
  type: "radio"
})`
  margin-right: 5px !important;
`;

class AuditAdvOptCalibration extends React.Component {
  constructor(props) {
    super(props);
    this.applyCalibrationHandler = this.applyCalibrationHandler.bind(this);
  }

  applyCalibrationHandler() {
    const {
      alertStore: { addAlert },
      auditStore: { applyCalibration, persistFieldOutline, saveCalibration, selectedPitch },
      routerStore: { isAuditCameraCalibrationTab }
    } = this.props.rootStore;
    if (isAuditCameraCalibrationTab || selectedPitch?.dirty) {
      (isAuditCameraCalibrationTab ? saveCalibration(false) : persistFieldOutline(selectedPitch, false))
        .then(() => {
          applyCalibration();
        })
        .catch(() => {
          addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: "Failed to persist calibration for selected pitch."
          });
        });
    } else {
      applyCalibration();
    }
  }

  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <div>
        <HrTitle title="Apply Calibration" bg="FFFFFF" />
        <div className="row">
          <div className="col-md-4 col-xs-6">
            <div className="form-group">
              <RadioLabel htmlFor="range">
                <RadioInput
                  name="Range"
                  id="range"
                  checked={auditStore.applyCalibrationValues.calibrationMode === 1}
                  onClick={e => auditStore.toggleCalibrationMode(1)}
                  onChange={() => {}}
                />
                Range
              </RadioLabel>
            </div>
            <div className="form-group">
              <RadioLabel htmlFor="range">
                <RadioInput
                  name="Range"
                  id="range"
                  checked={auditStore.applyCalibrationValues.calibrationMode === 2}
                  onClick={e => auditStore.toggleCalibrationMode(2)}
                  onChange={() => {}}
                />
                List
              </RadioLabel>
            </div>
            <div className="form-group">
              <RadioLabel htmlFor="range">
                <RadioInput
                  name="Range"
                  id="range"
                  checked={auditStore.applyCalibrationValues.calibrationMode === 3}
                  onClick={e => auditStore.toggleCalibrationMode(3)}
                  onChange={() => {}}
                />
                Entire Game
              </RadioLabel>
            </div>
          </div>
          <div className="col-md-8 col-xs-6">
            <div className="row">
              {auditStore.applyCalibrationValues.calibrationMode === 1 ? (
                <React.Fragment>
                  <div className="col-md-6">
                    <div className="form-group">
                      <ZeLabel>Range Start</ZeLabel>
                      <ZeNumberNoSpinner
                        value={auditStore.applyCalibrationValues.rangeStart}
                        onChange={auditStore.setRangeStart}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <ZeLabel>Range End</ZeLabel>
                      <ZeNumberNoSpinner
                        value={auditStore.applyCalibrationValues.rangeEnd}
                        onChange={auditStore.setRangeEnd}
                        disabled={false}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              {auditStore.applyCalibrationValues.calibrationMode === 2 ? (
                <div className="col">
                  <div className="form-group">
                    <ZeLabel>Pitches</ZeLabel>
                    <input
                      className="form-control"
                      placeholder="Comma Separated List of Pitches"
                      value={auditStore.applyCalibrationValues.list}
                      onChange={auditStore.setCameraCalibrationList}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="offset-md-4 offset-xs-6 col-md-8 col-xs-6">
                <div className="form-group">
                  <ApplyButton disabled={!auditStore.applyCalibrationEnabled} onClick={this.applyCalibrationHandler}>
                    Apply
                  </ApplyButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditAdvOptCalibration));
