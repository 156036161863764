import { action, autorun, computed, extendObservable, isObservable, observable, reaction } from "mobx";
import _ from "lodash";
import { base64ToImage } from "../../utilities/ImageConversionUtil";
import {
  auditStoreDefaults,
  calibrationFields,
  CalibrationStatus,
  hotkeyMap,
  rmseMarkerMap,
  strikeZoneBoundaries,
  szOobThreshold
} from "./AuditStoreConstants";
import { AlertConstants } from "../../components/common/alert/AlertConstants";
import { now } from "mobx-utils";
import axios from "axios";
import { HttpConstants } from "../../components/common/constants/HttpConstants";
import Moment from "moment/moment";
import { DateConstants } from "../../components/common/constants/DateConstants";
import { AuthConstants } from "../../components/login/AuthConstants";
import { VideoConstants } from "../../components/common/constants/VideoConstants";

const MOUND_IDS = [70, 71, 72];
const BATTERS_BOX_RIGHT_IDS = [80, 81, 82, 83];
const BATTERS_BOX_LEFT_IDS = [90, 91, 92, 93];

class AuditStore {
  constructor(routerStore, zeApi, loadingStore, authStore, alertStore, tmS3Api) {
    this.zeApi = zeApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.tmS3Api = tmS3Api;
    this.videoZoomFactor = 2;
    this.videoCenter = [270, 480];
    this.newPitchKeyframeFlag = false;
    this.adminRoles = [AuthConstants.USER_ROLES.ZE_SUPER_ADMIN, AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN];

    // bind functions as necessary
    this.applyCalibration = this.applyCalibration.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
    this.persistFieldOutline = this.persistFieldOutline.bind(this);
    this.saveCalibration = this.saveCalibration.bind(this);
    this.selectedPitchChanged = this.selectedPitchChanged.bind(this);

    this.defaults = auditStoreDefaults;

    extendObservable(this, {
      isAdmin: this.defaults["isAdmin"],
      controls: this.defaults["controls"],
      pitches: this.defaults["pitches"],
      players: this.defaults["players"],
      gamePk: this.defaults["gamePk"],
      gameDisplayName: this.defaults["gameDisplayName"],
      gameDisplayTime: this.defaults["gameDisplayTime"],
      auditEnabled: this.defaults["auditEnabled"],
      strikeZoneEditable: this.defaults["strikeZoneEditable"],
      enableZoom: this.defaults["enableZoom"],
      isVideoZoomedFlag: this.defaults["isVideoZoomed"],
      videoZoomTop: this.defaults["videoZoomTop"],
      videoZoomLeft: this.defaults["videoZoomLeft"],
      rowBoundaries: this.defaults["rowBoundaries"],
      selectedPitch: this.defaults["selectedPitch"],
      cameraCalibration: this.defaults["cameraCalibration"],
      rMSEColor: this.defaults["rMSEColor"],
      oobPitches: this.defaults["oobPitches"],
      oobPitchFilter: this.defaults["oobPitchesFilter"],
      calledFilter: this.defaults["calledFilter"],
      problemPitchInfo: this.defaults["problemPitchInfo"],
      problemPitchKeys: this.defaults["problemPitchKeys"],
      problemPitchKeyOrder: this.defaults["problemPitchKeyOrder"],
      problemPitchFilter: this.defaults["problemPitchFilter"],
      defaultKeyframeTs: this.defaults["keyframeTs"],
      plateTs: this.defaults["plateTs"],
      timingOffset: this.defaults["timingOffset"],
      stepSize: this.defaults["stepSize"],
      applyCalibrationValues: this.defaults["applyCalibrationValues"],
      selectedActionItem: this.defaults["selectedActionItem"],
      cameraCalStrikeZone: this.defaults["cameraCalStrikeZone"],
      researchImage: this.defaults["researchImage"],
      isStrikeZone: this.defaults["isStrikeZone"],
      currentRequests: this.defaults["currentRequests"],
      loading: this.defaults["loading"],
      calibrationStatus: this.defaults["calibrationStatus"],
      hoverAndClick: this.defaults["hoverAndClick"],
      autoPaste: this.defaults["autoPaste"],
      preload: this.defaults["preload"],
      preloadRange: this.defaults["preloadRange"],
      doAutoSave: this.defaults["doAutoSave"],
      autoSaveDate: this.defaults["autoSaveDate"],
      showCancelModal: this.defaults["showCancelModal"],
      showConfirmationModal: this.defaults["showConfirmationModal"],
      drawSecondLine: this.defaults["drawSecondLine"],
      confirmationModalTitle: this.defaults["confirmationModalTitle"],
      modalAction: this.defaults["modalAction"],
      cameraCalMarkersSequence: this.defaults["cameraCalMarkersSequence"],
      plateMarkerIndexes: this.defaults["plateMarkerIndexes"],
      moundMarkerIndexes: this.defaults["moundMarkerIndexes"],
      secondBaseMarkerIndexes: this.defaults["secondBaseMarkerIndexes"],
      cameraCalMarkers: this.defaults["cameraCalMarkers"],
      selectedCameraCal: this.defaults["selectedCameraCal"],
      marker: this.defaults["marker"],
      trackLine: this.defaults["trackLine"],
      callCorrectness: this.defaults["callCorrectness"],
      callCorrectnessTotals: this.defaults["callCorrectnessTotals"],
      videoRef: null,
      videoCurrentTime: this.defaults["videoCurrentTime"],
      videoDuration: this.defaults["videoDuration"],
      dragLine: this.defaults["dragLine"],
      dirtyCamCalMarkers: this.defaults["dirtyCamCalMarkers"],
      pitchListRef: null,
      venueId: this.defaults["venueId"],
      sort: {
        col: "pitchNumber",
        asc: true
      },

      // getter actions
      getFieldTranslationHorizontal: action(pitch => {
        return pitch.fieldOutlineTranslationAdjustment ? pitch.fieldOutlineTranslationAdjustment.horizontal : 0;
      }),
      getFieldTranslationVertical: action(pitch => {
        return pitch.fieldOutlineTranslationAdjustment ? pitch.fieldOutlineTranslationAdjustment.vertical : 0;
      }),

      // setter and toggle actions
      setAuditEnabled: action(value => {
        this.auditEnabled = value;
      }),
      setCameraCalibration: action(value => {
        let cameraCalibration = {};
        let self = this;
        calibrationFields.forEach(field => {
          cameraCalibration = self.checkAndSetCameraCalibration(cameraCalibration, value, field);
        });
        value.markers = cameraCalibration;
        this.cameraCalibration = value;
      }),
      setRMSEColor: action((previousRSME, currentRSME) => {
        if (previousRSME === currentRSME) {
          this.rMSEColor = "black";
        } else if (previousRSME < currentRSME) {
          this.rMSEColor = "red";
        } else {
          this.rMSEColor = "green";
        }
      }),
      setCameraCalibrationList: action(event => {
        this.applyCalibrationValues.list = event.target.value;
      }),
      setCameraCalSelectedMarker: action((value, visibleCheck) => {
        let marker = this.cameraCalMarkers.get(value);
        if (visibleCheck) {
          if (!marker.visible) {
            this.toggleCameraMarker(value);
          }
        }
        if (!this.cameraCalibration.markers[marker.key]) {
          this.cameraCalibration.markers[marker.key] = marker.default;
        }
        this.selectedCameraCal = value === this.selectedCameraCal ? -1 : value;
      }),
      setControls: action(value => {
        this.controls = value;
      }),
      setDrawSecondLine: action(value => {
        this.drawSecondLine = value;
      }),
      setStrikeZoneEditable: action(value => {
        this.strikeZoneEditable = value;
      }),
      setEnableZoom: action(value => {
        this.enableZoom = value;
      }),
      setFieldOutlineImage: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.fieldOutlineImage = value.fieldOutlineImage;
      }),
      setFieldOutlineTranslationAdjustment: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        if (value.fieldOutlineTranslationAdjustment.horizontal > 250) {
          value.fieldOutlineTranslationAdjustment.horizontal = 250;
        } else if (value.fieldOutlineTranslationAdjustment.horizontal < -250) {
          value.fieldOutlineTranslationAdjustment.horizontal = -250;
        }
        if (value.fieldOutlineTranslationAdjustment.vertical > 250) {
          value.fieldOutlineTranslationAdjustment.vertical = 250;
        } else if (value.fieldOutlineTranslationAdjustment.vertical < -250) {
          value.fieldOutlineTranslationAdjustment.vertical = -250;
        }
        value.pitch.fieldOutlineTranslationAdjustment = value.fieldOutlineTranslationAdjustment;
      }),
      setInitialKeyFrameTs: action(value => {
        this.selectedPitch.initialKeyframeTs = value;
      }),
      setKeyframe: action(() => {
        this.selectedPitch.keyframeTs = this.videoRef.currentTime;
      }),
      setKeyFrameTs: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.keyframeTs = value.keyframeTs;
      }),
      setTimingOffset: action(event => {
        this.timingOffset = event.target.value;
      }),
      setTimingOffsetFromVideo: action(event => {
        let keyFrame = this.selectedPitch.initialKeyframeTs
          ? this.selectedPitch.initialKeyframeTs
          : this.defaultKeyframeTs;

        this.timingOffset = this.videoRef.currentTime ? ((this.videoRef.currentTime - keyFrame) * 1000).toFixed(0) : 0;
      }),
      setLoading: action(value => {
        this.loading = value;
      }),
      setCalibrationStatus: action(value => {
        this.calibrationStatus = value;
      }),
      setShowConfirmationModal: action(value => {
        this.showConfirmationModal = value;
      }),
      setVideoCurrentTime: action(value => {
        this.videoCurrentTime = value;
      }),
      setVideoCurrentTimeFromListener: action(event => {
        let video = event.target;
        this.setVideoCurrentTime(video.currentTime);
        this.setVideoDuration(video.duration);
      }),
      setVideoDuration: action(value => {
        this.videoDuration = value;
      }),
      toggleOobPitchFilter: action(() => {
        this.oobPitchFilter = !this.oobPitchFilter;
      }),
      setPitchCoordinates: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.pitchCoordinates = value.pitchCoordinates;
      }),
      setPitchInList: action(pitch => {
        if (!pitch) {
          return;
        }
        let idx = this.pitches.findIndex(p => p.playId === pitch.playId);
        if (idx >= 0) {
          this.pitches[idx] = pitch;
          this.pitches = [...this.pitches];
        }
        if (this.selectedPitch && this.selectedPitch.playId === pitch.playId) {
          this.selectedPitch = pitch;
        }
      }),
      setPitchListRef: action(value => {
        if (value) {
          this.pitchListRef = value;
        }
      }),
      setPlateTs: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.plateTs = value.plateTs;
      }),
      setPlateCoordinates: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.plateCoordinates = value.plateCoordinates;
      }),
      setPlayers: action(value => {
        this.players = value;
      }),
      setRangeEnd: action(event => {
        this.applyCalibrationValues.rangeEnd = event.target.value;
      }),
      setRangeStart: action(event => {
        this.applyCalibrationValues.rangeStart = event.target.value;
      }),
      setSelectedActionItem: action(value => {
        this.selectedActionItem = value;
      }),
      setSelectedPitch: action(value => {
        this.newPitchKeyframeFlag = true;
        this.selectedPitch = value;
        this.setLoading(false);
      }),
      setSort: action(col => {
        if (col === this.sort.col) {
          if (this.sort.asc) {
            // sort descending
            this.sort.asc = false;
          } else {
            // clear sort
            this.sort.asc = true;
            this.sort.col = "";
          }
        } else {
          this.sort.asc = true;
          this.sort.col = col;
        }
      }),
      setStrikeZoneTopLeft: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.strikeZoneTopLeft = value.topLeftCoordinate;
      }),
      setStrikeZoneBottomRight: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.strikeZoneBottomRight = value.bottomRightCoordinate;
      }),
      setStrikeZoneLower: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.strikeZoneLower = value.strikeZoneLower;
      }),
      setKneeRowBoundaries: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.kneeRowBoundaries = value.kneeRowBoundaries;
      }),
      setStrikeZoneTopDifference: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.topDifference = value.pitch.waistRow - value.pitch.strikeZoneTopLeft[0];
      }),
      setStrikeZoneBottomDifference: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.bottomDifference = value.pitch.strikeZoneLower - value.pitch.strikeZoneBottomRight[0];
      }),
      setVideoRef: action(value => {
        if (value) {
          this.videoRef = value;
          // Fire snapToKeyframe once we have the metadata for the video element.
          // As a feature, we set the default video frame to be the frame where
          // the strike zone is set. The function relies on the duration of the video,
          // and consequently we have to wait until the duration metadata is available.
          this.videoRef.addEventListener("loadedmetadata", this.snapToKeyframeOnNewPitch);
          this.videoRef.addEventListener("timeupdate", this.setVideoCurrentTimeFromListener);
        }
      }),
      onSeekChange: action(value => {
        this.videoRef.currentTime = value / 1000;
      }),
      setVideoZoomLeft: action(event => {
        this.videoZoomLeft = Number.parseInt(event.target.value, 10);
      }),
      setVideoZoomTop: action(event => {
        this.videoZoomTop = Number.parseInt(event.target.value, 10);
      }),
      setWaistRow: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.waistRow = value.waistRow;
      }),
      setWaistRowBoundaries: action(value => {
        if (!value.pitch) {
          value.pitch = this.selectedPitch;
        }
        value.pitch.waistRowBoundaries = value.waistRowBoundaries;
      }),
      setResearchImage: action(data => {
        this.researchImage = data;
      }),
      toggleCallCorrectness: action(key => {
        this.callCorrectness[key] = !this.callCorrectness[key];
        if (key === "INCORRECT") {
          this.toggleCallCorrectness("CATCHER_INFLUENCE");
          this.toggleCallCorrectness("HIGH_BUFFER");
          this.toggleCallCorrectness("LOW_BUFFER");
          this.toggleCallCorrectness("LOW_CATCH");
        }
      }),
      togglePlateMarkers: action(() => {
        if (this.plateMarkersEnabled) {
          this.changePlateMarkers(false);
        } else {
          this.changePlateMarkers(true);
        }
      }),
      toggleMoundAnd2BMarkers: action(() => {
        if (this.moundMarkersEnabled || this.secondBaseMarkersEnabled) {
          this.changeMoundMarkers(false);
          this.changeSecondBaseMarkers(false);
        } else {
          this.changeMoundMarkers(true);
          this.changeSecondBaseMarkers(true);
        }
      }),
      changePlateMarkers: action(value => {
        this.plateMarkerIndexes.forEach(index => {
          this.cameraCalMarkers.get(index).visible = value;
        });
      }),
      changeMoundMarkers: action(value => {
        this.moundMarkerIndexes.forEach(index => {
          this.cameraCalMarkers.get(index).visible = value;
        });
      }),
      changeSecondBaseMarkers: action(value => {
        this.secondBaseMarkerIndexes.forEach(index => {
          this.cameraCalMarkers.get(index).visible = value;
        });
      }),
      toggleAllCameraCalibrationMarkers: action(tf => {
        let keys = this.cameraCalMarkers.keys();
        keys.forEach(k => {
          let value = this.cameraCalMarkers.get(k);
          value.visible = tf;
          this.cameraCalMarkers.set(k, value);
        });
      }),
      toggleCameraCalStrikeZone: action(() => {
        this.cameraCalStrikeZone = !this.cameraCalStrikeZone;
      }),
      toggleCalibrationMode: action(value => {
        this.applyCalibrationValues.calibrationMode = value;
      }),
      toggleCameraMarker: action(key => {
        let value = this.cameraCalMarkers.get(key);
        value.visible = !value.visible;
        this.cameraCalMarkers.set(key, value);
        if (this.selectedCameraCal === key) {
          this.selectedCameraCal = this.defaults["selectedCameraCal"];
        }
      }),
      toggleAutoPaste: action(() => {
        this.autoPaste = !this.autoPaste;
        this.setAllPreloadedFlags(false);
      }),
      toggleDoAutoSave: action(() => {
        this.doAutoSave = !this.doAutoSave;
      }),
      toggleHoverAndClick: action(() => {
        this.hoverAndClick = !this.hoverAndClick;
        this.dragLine = this.defaults["dragLine"];
      }),
      toggleIsVideoZoomed: action(() => {
        //prevent hot key event from changing on camera calibration screen
        if (this.routerStore.isAuditStrikeZoneTab) {
          this.isVideoZoomedFlag = !this.isVideoZoomedFlag;
        }
      }),
      toggleShowBoundaries: action(() => {
        //prevent hot key event from changing on camera calibration screen
        if (this.routerStore.isAuditStrikeZoneTab) {
          if (this.showBoundaries.top || this.showBoundaries.bottom) {
            this.setShowBoundaries({
              top: false,
              bottom: false
            });
          } else {
            this.setShowBoundaries({
              top: true,
              bottom: true
            });
          }
        }
      }),
      togglePreload: action(() => {
        if (this.preloadAllowed) {
          this.preload = !this.preload;
          if (this.preload && this.selectedPitch) {
            this.preloadPitches(this.sortedPitchIdx);
          } else {
            this.setAllPreloadedFlags(false);
          }
        }
      }),
      toggleTrackLine: action(() => {
        this.trackLine = !this.trackLine;
      }),
      updateAutoSaveDate: action(() => {
        this.autoSaveDate = new Date();
      }),
      markPitchDirty: action(pitch => {
        let dateStr = Moment(new Date()).format(DateConstants.DATE_FORMAT_WITH_TIME);
        let dirtyPitch = {
          ...pitch,
          dirty: true,
          updatedOn: dateStr,
          updatedBy: localStorage.getItem(AuthConstants.KEYS.NAME)
        };
        let idx = this.pitches.findIndex(p => p.playId === dirtyPitch.playId);
        if (idx >= 0) {
          this.pitches[idx] = dirtyPitch;
          this.pitches = [...this.pitches];
        }
        if (this.selectedPitch && this.selectedPitch.playId === dirtyPitch.playId) {
          this.setSelectedPitch(dirtyPitch);
        }
      }),

      // hotkey handlers
      bottomStrikeZoneDownHandler: action(evt => {
        evt.preventDefault();
        if (!this.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        this.selectedPitch.strikeZoneLower += this.stepSize;
        this.markPitchDirty(this.selectedPitch);
      }),
      bottomStrikeZoneUpHandler: action(evt => {
        evt.preventDefault();
        if (!this.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        if (this.isValidStrikeZoneUpMove(this.stepSize)) {
          this.selectedPitch.strikeZoneLower -= this.stepSize;
          this.markPitchDirty(this.selectedPitch);
        }
      }),
      decreaseStepSize: action(() => {
        if (this.stepSize === 1 / 16) {
          this.stepSize = 8;
        } else {
          this.stepSize = this.stepSize / 2;
        }
      }),
      downHandler: action(evt => {
        evt.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.moveCalibrationMarker("down");
        } else {
          this.fieldOutlineDownHandler(evt);
        }
      }),
      fieldOutlineDownHandler: action(evt => {
        evt.preventDefault();
        let fota = Object.assign({}, this.selectedPitch.fieldOutlineTranslationAdjustment);
        fota.vertical += this.stepSize;
        this.setFieldOutlineTranslationAdjustment({
          fieldOutlineTranslationAdjustment: fota,
          pitch: this.selectedPitch
        });
        this.markPitchDirty(this.selectedPitch);
      }),
      fieldOutlineUpHandler: action(evt => {
        evt.preventDefault();
        let fota = Object.assign({}, this.selectedPitch.fieldOutlineTranslationAdjustment);
        fota.vertical -= this.stepSize;
        this.setFieldOutlineTranslationAdjustment({
          fieldOutlineTranslationAdjustment: fota,
          pitch: this.selectedPitch
        });
        this.markPitchDirty(this.selectedPitch);
      }),
      increaseStepSize: action(() => {
        if (this.stepSize === 8) {
          this.stepSize = 1 / 16;
        } else {
          this.stepSize = this.stepSize * 2;
        }
      }),
      leftHandler: action(evt => {
        evt.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.moveCalibrationMarker("left");
        } else {
          let fota = Object.assign({}, this.selectedPitch.fieldOutlineTranslationAdjustment);
          fota.horizontal -= this.stepSize;
          this.setFieldOutlineTranslationAdjustment({
            fieldOutlineTranslationAdjustment: fota,
            pitch: this.selectedPitch
          });
          this.markPitchDirty(this.selectedPitch);
        }
      }),
      nextPitch: action(() => {
        let index = this.sortedPitchIdx + 1;
        let foundValidPitch = false;
        while (!foundValidPitch && index < this.sortedPitches.length) {
          let pitch = this.sortedPitches[index];
          if (pitch.playId) {
            this.saveCurrentPitch(this.sortedPitches[index]);
            this.scrollPitchTable(index);
            foundValidPitch = true;
          }
          index++;
        }
      }),
      nextPitchFromClick: action(pitch => {
        if (pitch.callCorrectness !== "UNKNOWN" || (pitch.playId && pitch.playId !== "")) {
          this.saveCurrentPitch(pitch);
        }
      }),
      saveCurrentPitch: action(nextPitch => {
        if (nextPitch.preloaded) {
          this.saveCurrentPitchPreload(nextPitch);
        } else {
          this.setAllPreloadedFlags(false);
          this.saveCurrentPitchNoPreload(nextPitch);
        }
      }),
      saveCurrentPitchPreload: action(nextPitch => {
        if (this.selectedPitch.dirty) {
          //strike zone or field outline data has changed
          let pitch = this.selectedPitch;
          let waistRowTranslated = this.waistRowTranslated;
          let strikeZoneLowerTranslated = this.strikeZoneLowerTranslated;
          this.persistFieldOutline(pitch, false).then(data => {
            this.persistStrikeZone(pitch, waistRowTranslated, strikeZoneLowerTranslated).then(d => {
              this.updatePitchStrikeZone(pitch, d);
              this.setOobPitches(this.getOobPitches(this.dirtyPitches, this.players));
            });
          });
        }
        if (this.autoPaste) {
          this.autoPastePreloadHandler(nextPitch);
        } else {
          this.setSelectedPitch(nextPitch);
        }
      }),
      saveCurrentPitchNoPreload: action(nextPitch => {
        this.dragLine = this.defaults["dragLine"];
        this.setLoading(true);
        let currentKeys = Object.keys(this.currentRequests);
        if (currentKeys.length > 0) {
          currentKeys.forEach(k => {
            let source = this.currentRequests[k];
            source.cancel(HttpConstants.CANCEL_REQUEST);
          });
        }
        if (this.selectedPitch.dirty && this.routerStore.isAuditStrikeZoneTab) {
          //strike zone or field outline data has changed
          let pitch = this.selectedPitch;
          let waistRowTranslated = this.waistRowTranslated;
          let strikeZoneLowerTranslated = this.strikeZoneLowerTranslated;
          this.persistFieldOutline(pitch, false).then(data => {
            this.persistStrikeZone(pitch, waistRowTranslated, strikeZoneLowerTranslated).then(d => {
              this.updatePitchStrikeZone(pitch, d);
              this.setOobPitches(this.getOobPitches(this.dirtyPitches, this.players));
            });
            if (this.autoPaste && nextPitch) {
              this.autoPasteHandler(nextPitch);
            } else {
              this.setSelectedPitch(nextPitch);
            }
          });
        } else {
          if (this.autoPaste && nextPitch) {
            this.autoPasteHandler(nextPitch);
          } else {
            this.setSelectedPitch(nextPitch);
          }
        }
      }),
      autoPasteHandler: action(nextPitch => {
        let applyCalibrationObj = {
          plays: [nextPitch.playId]
        };
        this.zeApi.applyCalibration(this.gamePk, this.lastTrackedPlay.playId, applyCalibrationObj).then(data => {
          this.zeApi.generateImages(this.gamePk, applyCalibrationObj).then(data => {
            this.setSelectedPitch(nextPitch);
          });
        });
      }),
      autoPastePreloadHandler: action(nextPitch => {
        let lastTrackedPlay = this.lastTrackedPlay;
        this.setFieldOutlineTranslationAdjustment({
          fieldOutlineTranslationAdjustment: JSON.parse(
            JSON.stringify(lastTrackedPlay.fieldOutlineTranslationAdjustment)
          ),
          pitch: nextPitch
        });

        let applyCalibrationObj = {
          plays: [nextPitch.playId]
        };
        this.zeApi.applyCalibration(this.gamePk, lastTrackedPlay.playId, applyCalibrationObj).then(data => {
          this.zeApi.generateImages(this.gamePk, applyCalibrationObj);
        });
        this.setSelectedPitch(nextPitch);
      }),
      playPauseHandler: action(evt => {
        if (evt) {
          evt.preventDefault();
          evt.stopPropagation();
        }
        if (this.videoRef) {
          this.videoRef.paused ? this.videoRef.play() : this.videoRef.pause();
        }
      }),
      preloadHotkeyHandler: action(() => {
        if (!this.routerStore.isAuditCameraCalibrationTab) {
          this.togglePreload();
        }
      }),
      previousPitch: action(() => {
        if (this.preload) {
          return;
        }
        let index = this.sortedPitchIdx - 1;
        let foundValidPitch = false;
        while (!foundValidPitch && index >= 0) {
          let pitch = this.sortedPitches[index];
          if (pitch.playId) {
            this.saveCurrentPitch(this.sortedPitches[index]);
            this.scrollPitchTable(index);
            foundValidPitch = true;
          }
          index--;
        }
      }),
      rightHandler: action(evt => {
        evt.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.moveCalibrationMarker("right");
        } else {
          let fota = Object.assign({}, this.selectedPitch.fieldOutlineTranslationAdjustment);
          fota.horizontal += this.stepSize;
          this.setFieldOutlineTranslationAdjustment({
            fieldOutlineTranslationAdjustment: fota,
            pitch: this.selectedPitch
          });
          this.markPitchDirty(this.selectedPitch);
        }
      }),
      stepBackward: action(() => {
        this.videoRef.currentTime = Math.max(this.videoRef.currentTime - VideoConstants.ONE_FRAME, 0);
      }),
      stepForward: action(() => {
        let { currentTime, duration } = this.videoRef;
        if (this.videoRef && this.videoRef.duration) {
          this.videoRef.currentTime = Math.min(currentTime + VideoConstants.ONE_FRAME, duration);
        }
      }),
      tabHandler: action(event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.routerStore.isAuditStrikeZoneTab) {
          this.routerStore.history.push("/audit/camera-calibration?gamePk=" + this.gamePk);
        } else if (this.routerStore.isAuditCameraCalibrationTab) {
          this.routerStore.history.push("/audit/research?gamePk=" + this.gamePk);
        } else if (this.routerStore.isAuditResearchTab) {
          this.routerStore.history.push("/audit/strike-zone?gamePk=" + this.gamePk);
        }
      }),
      topStrikeZoneDownHandler: action(evt => {
        evt.preventDefault();
        if (!this.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        if (this.isValidStrikeZoneDownMove(this.stepSize)) {
          this.selectedPitch.waistRow += this.stepSize;
          this.markPitchDirty(this.selectedPitch);
        }
      }),
      topStrikeZoneUpHandler: action(evt => {
        evt.preventDefault();
        if (!this.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        this.selectedPitch.waistRow -= this.stepSize;
        this.markPitchDirty(this.selectedPitch);
      }),
      upHandler: action(evt => {
        evt.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.moveCalibrationMarker("up");
        } else {
          this.fieldOutlineUpHandler(evt);
        }
      }),
      dirtyHandler: action(() => {
        if (this.selectedPitch) {
          if (this.selectedPitch.dirty) {
            this.selectedPitch.dirty = false;
          } else {
            this.markPitchDirty(this.selectedPitch);
          }
        }
      }),

      // other actions
      autoSave: action(() => {
        let pitches = this.autoSaveList;
        if (pitches.length > 0) {
          this.zeApi.autoSave(this.gamePk, pitches).then(data => {
            this.updateAutoSaveDate();
          });
        }
      }),
      cancelAudit: action(() => {
        this.showCancelModal = false;
        this.loadingStore.setLoading(true, "Canceling", "Canceling the current Audit", 75);
        this.getAuditInfo();
      }),
      cleanPitches: action(plays => {
        //iterate through pitches once versus looking up each pitch id in plays
        this.pitches.forEach(p => {
          if (p && plays.indexOf(p.playId) >= 0) {
            p.dirty = false;
          }
        });
        this.dirtyCamCalMarkers = this.defaults["dirtyCamCalMarkers"];
      }),
      cutFrames: action(plays => {
        let cutFramesObj = {
          plays: [],
          feedType: "CF"
        };
        plays.forEach(p => {
          cutFramesObj.plays.push({
            gamePk: this.gamePk,
            playId: p
          });
        });
        this.zeApi.cutFrames(cutFramesObj).then(data => {
          alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully cut frames for " + plays.length + " pitches."
          });
        });
      }),
      getAuditInfo: action((pitchIdx, pitchesToReplace) => {
        if (!pitchIdx) {
          pitchIdx = 0;
        }
        this.loadingStore.setLoading(true, "Loading", "Loading Audit Information", 75);
        this.pitches = this.defaults["pitches"];
        this.changeHasBeenMade = this.defaults["changeHasBeenMade"];
        this.zeApi.getPitchList(this.gamePk).then(data => {
          if (!data || data.error || (data.data && data.data.error)) {
            console.log(data);
          } else {
            data.pitches = data.pitches.map(p => this.addDefaultStrikeZoneLinesAndFieldOutline(p));
            this.pitches = data.pitches;
            this.callCorrectnessTotals = data.callCorrectnessTotals;
            this.gameDisplayName = data.displayName;
            this.gameDisplayTime = data.displayTime;
            this.venueId = data.venueId;
            if (data.cameraPan) {
              this.videoZoomTop = data.cameraPan.videoZoomTop;
              this.videoZoomLeft = data.cameraPan.videoZoomLeft;
            }
            if (data.gameAuditConfiguration) {
              if (data.gameAuditConfiguration.auditCameraAngle === "BROADCAST") {
                this.setEnableZoom(false);
                this.toggleIsVideoZoomed();
              }
              this.setAuditEnabled(data.gameAuditConfiguration.auditEnabled);
              this.setStrikeZoneEditable(data.gameAuditConfiguration.strikeZoneEditable);
            }
            if (this.sortedPitches.length > 0) {
              this.setSelectedPitch(this.sortedPitches[pitchIdx]);
              this.scrollPitchTable(pitchIdx);
            }
            if (pitchesToReplace && pitchesToReplace.length) {
              for (const pitch of pitchesToReplace) {
                let ix = this.pitches.findIndex(p => {
                  return p.playId === pitch.playId;
                });
                this.pitches[ix] = pitch;
              }
              this.oobPitchFilter = true;
            }
          }
          this.loadingStore.setWindowTitle(this.windowText);
          this.loadingStore.setLoading(false);
        });
        this.zeApi.getPlayersByGamePk(this.gamePk).then(players => {
          const playersMap = players.reduce(function(map, player) {
            player.strikeZoneTopMax = player.strikeZoneTop + player.heightInFeet * szOobThreshold.top;
            player.strikeZoneTopMin = player.strikeZoneTop - player.heightInFeet * szOobThreshold.top;
            player.strikeZoneBottomMax = player.strikeZoneBottom + player.heightInFeet * szOobThreshold.bottom;
            player.strikeZoneBottomMin = player.strikeZoneBottom - player.heightInFeet * szOobThreshold.bottom;
            map[player.id] = player;
            return map;
          }, {});
          this.setPlayers(playersMap);
        });
      }),
      getCameraCalibration: action(playId => {
        if (this.routerStore.isAuditCameraCalibrationTab) {
          let p = this.selectedPitch;
          if (!this.isStrikeZone && p.gamePk && playId) {
            let source = axios.CancelToken.source();
            this.currentRequests["getCalibration"] = source;
            this.zeApi.getLegacyCalibration(p.gamePk, playId, source).then(d => {
              delete this.currentRequests["getCalibration"];
              this.setCameraCalibration(d);
              this.setLoading(false);
            });
          }
        }
      }),
      getFieldOutlineAndStrikeZone: action((p, preload) => {
        let _this = this;
        let foSource = axios.CancelToken.source();
        this.currentRequests["fieldOutline"] = foSource;
        this.zeApi
          .getFieldOutline(p.gamePk, p.playId, foSource)
          .then(d => {
            this.setFieldOutlineTranslationAdjustment({
              fieldOutlineTranslationAdjustment: d.translationAdjustment,
              pitch: p
            });
            delete this.currentRequests["fieldOutline"];
            this.zeApi.getFieldOutlineFromS3(p.gamePk, p.playId).then(img => {
              this.setFieldOutlineImage({
                fieldOutlineImage: img,
                pitch: p
              });
            });
            let szSource = axios.CancelToken.source();
            this.currentRequests["strikeZone"] = szSource;
            this.zeApi
              .getStrikeZone(p.gamePk, p.playId, szSource)
              .then(d => {
                delete this.currentRequests["strikeZone"];
                this.setStrikeZoneData(d, p);
                p.preloaded = preload;
                this.setLoading(false);
              })
              .catch(function(error) {
                _this.setLoading(false);
              });
          })
          .catch(function(error) {
            _this.setLoading(false);
          });
      }),
      getProblemPitchInfo: action(() => {
        this.zeApi.getProblemPitchInfo(this.gamePk).then(data => {
          this.setProblemPitchInfo(data);
        });
      }),
      handleRestoreData: action(data => {
        this.loadingStore.setLoading(true, "Restoring", "Restoring backup", 75);
        data.forEach(d => {
          let pitch = _.find(this.pitches, p => {
            return p.playId === d.playId;
          });
          pitch.keyframeTs = d.keyframeTs;
          pitch.pitchCoordinates = d.pitchCoordinates;
          pitch.strikeZoneTopLeft = d.strikeZoneTopLeft;
          pitch.strikeZoneBottomRight = d.strikeZoneBottomRight;
          pitch.waistRow = d.waistRow;
          pitch.strikeZoneLower = d.strikeZoneLower;
          pitch.szTop = d.szTop;
          pitch.szBottom = d.szBottom;
          pitch.playId = d.playId;
          if (d.fieldOutlineTranslationAdjustment) {
            this.setFieldOutlineTranslationAdjustment({
              fieldOutlineTranslationAdjustment: d.fieldOutlineTranslationAdjustment,
              pitch: pitch
            });
          }
          const { vertical, horizontal } = pitch.fieldOutlineTranslationAdjustment;
          const waistRowTranslated = this.translateWaistRow(pitch.waistRow, vertical, horizontal);
          const kneeRowTranslated = this.translateWaistRow(pitch.strikeZoneLower, vertical, horizontal);
          this.markPitchDirty(pitch);
          this.persistPitchChanges(pitch, false, waistRowTranslated, kneeRowTranslated);
          if (this.selectedPitch.playId === pitch.playId) {
            this.setSelectedPitch(pitch);
          }
        });
        this.loadingStore.setLoading(false, this.windowText);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Game has been restored!"
        });
      }),
      refreshPitch: action(() => {
        const pitch = this.selectedPitch;
        this.loadingStore.setLoading(true, "Refreshing", "Refreshing pitch", 50);
        this.zeApi.refreshPitch(this.gamePk, this.selectedPitch.playId).then(data => {
          this.handleRefreshPitch(pitch, data);
          this.loadingStore.setLoading(false, this.windowText);
        });
      }),
      resetAuditStore: action(() => {
        this.pitches = this.defaults["pitches"];
        this.gamePk = this.defaults["gamePk"];
        this.gameDisplayName = this.defaults["gameDisplayName"];
        this.gameDisplayTime = this.defaults["gameDisplayTime"];
        this.selectedPitch = this.defaults["selectedPitch"];
        this.sort = {
          col: "pitchNumber",
          asc: true
        };
        this.cameraCalMarkers = this.defaults["cameraCalMarkers"];
        this.dirtyCamCalMarkers = this.defaults["dirtyCamCalMarkers"];
        this.autoPaste = this.defaults["autoPaste"];
        this.autoSaveDate = this.defaults["autoSaveDate"];
        this.doAutoSave = this.defaults["doAutoSave"];
        this.callCorrectness = this.defaults["callCorrectness"];
        this.callCorrectnessTotals = this.defaults["callCorrectnessTotals"];
        this.problemPitchFilter = this.defaults["problemPitchFilter"];
        this.problemPitchInfo = this.defaults["problemPitchInfo"];
      }),
      restore: action(() => {
        this.loadingStore.setLoading(true, "Fetching", "Fetching backup", 50);
        this.zeApi.restore(this.gamePk).then(data => {
          this.handleRestoreData(data);
        });
      }),
      saveAuditPitches: action(exclude => {
        this.showConfirmationModal = false;
        this.loadingStore.setLoading(true, "Audit", "Finalizing Audit", 75);
        this.persistFieldOutline(this.selectedPitch, false).then(data => {
          this.persistStrikeZone(this.selectedPitch, this.waistRowTranslated, this.strikeZoneLowerTranslated).then(
            d => {
              this.updatePitchStrikeZone(this.selectedPitch, d);
              let pitches = this.dirtyPitches.filter(
                pitch =>
                  pitch.szTop >= strikeZoneBoundaries.szTopLower &&
                  pitch.szTop <= strikeZoneBoundaries.szTopUpper &&
                  pitch.szBottom >= strikeZoneBoundaries.szBottomLower &&
                  pitch.szBottom <= strikeZoneBoundaries.szBottomUpper
              );
              if (exclude) {
                let oobPitchIds = this.oobPitches.map(p => p.playId);
                pitches = pitches.filter(pitch => {
                  return !oobPitchIds.includes(pitch.playId);
                });
              }
              let oobPitches = this.oobPitches;
              let saveObj = {
                gamePk: this.gamePk,
                pitches: [],
                cameraPan: {
                  videoZoomTop: this.videoZoomTop,
                  videoZoomLeft: this.videoZoomLeft
                },
                venueId: this.venueId
              };
              let plays = [];
              pitches.forEach(p => {
                let keyFrameMs = p.keyframeTs ? p.keyframeTs * 1000 : this.defaults["keyframeTs"].toString();
                let fieldTranslationVertical = this.getFieldTranslationVertical(p);
                let fieldTranslationHorizontal = this.getFieldTranslationHorizontal(p);
                let pitchCoordinatesTranslated = this.translatePitchCoordinates(
                  p.pitchCoordinates,
                  fieldTranslationVertical,
                  fieldTranslationHorizontal
                );
                let strikeZoneTopLeftTranslated = this.translateCoordinate2D(
                  p.strikeZoneTopLeft,
                  fieldTranslationVertical,
                  fieldTranslationHorizontal
                );
                let strikeZoneBottomRightTranslated = this.translateCoordinate2D(
                  p.strikeZoneBottomRight,
                  fieldTranslationVertical,
                  fieldTranslationHorizontal
                );
                let waistRowTranslated = this.translateWaistRow(
                  p.waistRow,
                  fieldTranslationVertical,
                  fieldTranslationHorizontal
                );
                let strikeZoneLowerTranslated = this.translateStrikeZoneLower(
                  p.strikeZoneLower,
                  fieldTranslationVertical,
                  fieldTranslationHorizontal
                );
                let savePitch = {
                  play_id: p.playId,
                  strike_zone_top: p.szTop,
                  strike_zone_bottom: p.szBottom,
                  keyframeMs: keyFrameMs,
                  pitchCoordinates: pitchCoordinatesTranslated,
                  waistRow: waistRowTranslated,
                  kneeRow: strikeZoneLowerTranslated,
                  szTopLeft: strikeZoneTopLeftTranslated,
                  szBottomRight: strikeZoneBottomRightTranslated,
                  updatedBy: p.updatedBy,
                  updatedOn: p.updatedOn
                };
                plays.push(p.playId);
                saveObj.pitches.push(savePitch);
              });
              this.showConfirmationModal = false;
              this.loadingStore.setLoading(true, "Saving", "Saving Strike Zones", 75);
              this.zeApi.saveAudit(saveObj, this.gamePk).then(data => {
                this.alertStore.addAlert({
                  type: AlertConstants.TYPES.SUCCESS,
                  text: "Successfully updated " + pitches.length + " strike zones."
                });
                this.initializeOrAnalyze(pitches);
                this.cutFrames(plays);
                this.getAuditInfo(this.sortedPitchIdx, oobPitches);

                this.cleanPitches(plays);
              });
            }
          );
        });
      }),
      scrollPitchTable: action(index => {
        let tbody = this.pitchListRef.children[1];
        let activeRow = tbody.children[index];
        if (!activeRow) {
          return;
        }

        let halfTableHeight = Math.floor(tbody.offsetHeight / 2);
        let autoScrollThreshold = Math.ceil(tbody.offsetHeight / 3);
        let offset = Math.max(activeRow.offsetTop - halfTableHeight, 0);
        let targetScrollTop = tbody.scrollTopMax ? Math.min(offset, tbody.scrollTopMax) : offset;

        if (Math.abs(activeRow.offsetTop - (tbody.scrollTop + halfTableHeight)) > autoScrollThreshold) {
          tbody.scrollTop = targetScrollTop;
        }
      }),
      translatePitchCoordinates: action((pitchCoordinates, fieldTranslationVertical, fieldTranslationHorizontal) => {
        if (!pitchCoordinates) {
          return pitchCoordinates;
        } else {
          fieldTranslationVertical = fieldTranslationVertical ? fieldTranslationVertical : 0;
          fieldTranslationHorizontal = fieldTranslationHorizontal ? fieldTranslationHorizontal : 0;
          let translatedBefore = [];
          for (let idx = 0; idx < pitchCoordinates.before.length; idx++) {
            let point = pitchCoordinates.before[idx];
            translatedBefore.push(
              this.translateCoordinate2D(point, fieldTranslationVertical, fieldTranslationHorizontal)
            );
          }
          let translatedOver = [];
          for (let idx = 0; idx < pitchCoordinates.over.length; idx++) {
            let point = pitchCoordinates.over[idx];
            translatedOver.push(
              this.translateCoordinate2D(point, fieldTranslationVertical, fieldTranslationHorizontal)
            );
          }
          let translatedAfter = [];
          for (let idx = 0; idx < pitchCoordinates.after.length; idx++) {
            let point = pitchCoordinates.after[idx];
            translatedAfter.push(
              this.translateCoordinate2D(point, fieldTranslationVertical, fieldTranslationHorizontal)
            );
          }
          let translatedMidpoint = pitchCoordinates.midpoint
            ? this.translateCoordinate2D(
                pitchCoordinates.midpoint,
                fieldTranslationVertical,
                fieldTranslationHorizontal
              )
            : null;
          return {
            before: translatedBefore,
            over: translatedOver,
            after: translatedAfter,
            midpoint: translatedMidpoint
          };
        }
      }),
      translateStrikeZoneLower: action((strikeZoneLower, fieldTranslationVertical) => {
        if (!strikeZoneLower || !fieldTranslationVertical) {
          return strikeZoneLower;
        } else {
          return strikeZoneLower + fieldTranslationVertical;
        }
      }),
      translateWaistRow: action((waistRow, fieldTranslationVertical) => {
        if (!waistRow || !fieldTranslationVertical) {
          return waistRow;
        } else {
          return waistRow + fieldTranslationVertical;
        }
      }),
      updateFromUrlParams: action(search => {
        const params = new URLSearchParams(search);
        if (params.has("gamePk") && this.gamePk !== params.get("gamePk") && this.routerStore.isAuditTab) {
          this.gamePk = params.get("gamePk");
          this.getAuditInfo();
          this.getProblemPitchInfo();
        }
      }),
      selectStrikeZoneTop: action(() => {
        if (routerStore.isAuditStrikeZoneTab) {
          this.setSelectedActionItem(1);
        }
      }),
      selectStrikeZoneBottom: action(() => {
        if (routerStore.isAuditStrikeZoneTab) {
          this.setSelectedActionItem(2);
        }
      }),
      selectFieldOutline: action(() => {
        if (routerStore.isAuditStrikeZoneTab) {
          this.setSelectedActionItem(3);
        }
      }),
      setProblemPitchInfo: action(data => {
        this.problemPitchInfo = data;
      }),
      snapToKeyframeOnNewPitch: action(() => {
        if (this.newPitchKeyframeFlag) {
          this.newPitchKeyframeFlag = false;
          this.snapToKeyframe();
        }
      }),
      snapToKeyframe: action(() => {
        if (this.videoRef && this.videoRef.duration) {
          this.videoRef.load();
          if (!this.selectedPitch || !this.selectedPitch.keyframeTs) {
            this.videoRef.currentTime = 3.2 + this.timingOffset / 1000;
          } else if (!this.autoPaste || this.selectedPitch.updatedOn) {
            this.videoRef.currentTime = this.selectedPitch.keyframeTs;
          } else {
            this.videoRef.currentTime = this.selectedPitch.keyframeTs
              ? this.selectedPitch.keyframeTs + this.timingOffset / 1000
              : this.defaultKeyframeTs + this.timingOffset / 1000;
          }
        }
      }),
      snapToPlate: action(() => {
        if (this.videoRef && this.videoRef.duration) {
          this.videoRef.load();
          if (this.selectedPitch.updatedOn) {
            this.videoRef.currentTime = this.selectedPitch.keyframeTs + 0.2 + this.timingOffset / 1000;
          } else {
            this.videoRef.currentTime = 3.4 + this.timingOffset / 1000;
          }
        }
      }),
      changeCalibrationMarkerForward: action(() => {
        if (this.selectedCameraCal === -1) {
          this.setCameraCalSelectedMarker(this.cameraCalMarkersSequence[0], false);
        } else {
          let index = this.cameraCalMarkersSequence.indexOf(this.selectedCameraCal);
          if (index === this.cameraCalMarkersSequence.length - 1) {
            this.setCameraCalSelectedMarker(this.cameraCalMarkersSequence[0], false);
          } else {
            this.setCameraCalSelectedMarker(this.cameraCalMarkersSequence[index + 1], false);
          }
        }
        if (!this.cameraCalMarkers.get(this.selectedCameraCal).visible) {
          this.changeCalibrationMarkerForward();
        }
      }),
      changeCalibrationMarkerBackwards: action(() => {
        if (this.selectedCameraCal === -1) {
          this.setCameraCalSelectedMarker(
            this.cameraCalMarkersSequence[this.cameraCalMarkersSequence.length - 1],
            false
          );
        } else {
          let index = this.cameraCalMarkersSequence.indexOf(this.selectedCameraCal);
          if (index === 0) {
            this.setCameraCalSelectedMarker(
              this.cameraCalMarkersSequence[this.cameraCalMarkersSequence.length - 1],
              false
            );
          } else {
            this.setCameraCalSelectedMarker(this.cameraCalMarkersSequence[index - 1], false);
          }
        }
        if (!this.cameraCalMarkers.get(this.selectedCameraCal).visible) {
          this.changeCalibrationMarkerBackwards();
        }
      }),
      strikeZoneDragStart: action(event => {
        if (!this.isUmpireCallCodeCalled(this.selectedPitch)) {
          return;
        }
        if (this.routerStore.isAuditStrikeZoneTab) {
          const { x, y } = event.currentTarget.getBoundingClientRect();
          const { clientX, clientY } = event;
          let canvasX = clientX - x;
          let canvasY = clientY - y;
          //use 0 and 500 as limits you can drag the strike zones
          if (canvasY > 0 && canvasY < 500) {
            if (this.isStrikeZoneClose(this.waistRowAdj, canvasX, canvasY)) {
              this.dragLine = "top";
            } else if (this.isStrikeZoneClose(this.strikeZoneLowerAdj, canvasX, canvasY)) {
              this.dragLine = "bottom";
            }
          }
        }
      }),
      strikeZoneDragEnd: action(event => {
        if (!this.isUmpireCallCodeCalled(this.selectedPitch)) {
          return;
        }
        this.setKeyframe();
        this.dragLine = null;
      }),
      strikeZoneDrag: action(event => {
        if (!this.isUmpireCallCodeCalled(this.selectedPitch)) {
          return;
        }
        if (this.routerStore.isAuditStrikeZoneTab) {
          const { y } = event.currentTarget.getBoundingClientRect();
          const { clientY } = event;
          let canvasY = clientY - y;
          //use 0 and 500 as limits you can drag the strike zones
          if (canvasY > 0 && canvasY < 500) {
            if (this.dragLine === "top") {
              let change = this.calculateStrikeZoneChange(canvasY, this.waistRowAdj);
              if (this.isValidStrikeZoneUpMove(change)) {
                this.selectedPitch.waistRow += change;
                this.markPitchDirty(this.selectedPitch);
              }
            } else if (this.dragLine === "bottom") {
              let change = this.calculateStrikeZoneChange(canvasY, this.strikeZoneLowerAdj);
              if (this.isValidStrikeZoneDownMove(change)) {
                this.selectedPitch.strikeZoneLower += change;
                this.markPitchDirty(this.selectedPitch);
              }
            }
          }
        }
      }),
      clickStrikeZone: action(event => {
        if (this.routerStore.isAuditCameraCalibrationTab) {
          const { x, y } = event.currentTarget.getBoundingClientRect();
          const { clientX, clientY } = event;
          let canvasX = clientX - x;
          let canvasY = clientY - y;
          this.moveCalibrationMarkerToPoint(canvasX, canvasY);
        }
        if (!this.strikeZoneEditable || !this.isUmpireCallCodeCalled(this.selectedPitch)) {
          return;
        }
        if (this.dragLine) {
          this.setKeyframe();
          const { y } = event.currentTarget.getBoundingClientRect();
          const { clientY } = event;
          let canvasY = clientY - y;
          if (this.dragLine === "top") {
            let change = this.calculateStrikeZoneChange(canvasY, this.waistRowAdj);
            if (this.isValidStrikeZoneUpMove(change)) {
              this.selectedPitch.waistRow += change;
              this.markPitchDirty(this.selectedPitch);
            }
          } else if (this.dragLine === "bottom") {
            let change = this.calculateStrikeZoneChange(canvasY, this.strikeZoneLowerAdj);
            if (this.isValidStrikeZoneDownMove(change)) {
              this.selectedPitch.strikeZoneLower += change;
              this.markPitchDirty(this.selectedPitch);
            }
          }
        }
      }),
      isPitchDirty: playId => {
        let pitches = _.find(this.pitches, function(o) {
          return o.playId === playId && o.dirty;
        });
        return typeof pitches !== "undefined";
      },
      getResearchInfo: action(() => {
        if (this.routerStore.isAuditResearchTab) {
          this.setLoading(true);
          this.tmS3Api.getImage(this.gamePk, this.selectedPitch.playId).then(data => {
            this.setResearchImage(data);
            this.setLoading(false);
          });
        }
      }),

      // computeds
      applyCalibrationEnabled: computed(() => {
        if (this.selectedPitch && this.selectedPitch.playId) {
          switch (this.applyCalibrationValues.calibrationMode) {
            case 1:
              return (
                this.applyCalibrationValues.rangeStart &&
                this.applyCalibrationValues.rangeEnd &&
                Number(this.applyCalibrationValues.rangeEnd) >= Number(this.applyCalibrationValues.rangeStart)
              );
            case 2:
              return this.applyCalibrationValues.list && this.applyCalibrationValues.list.length > 0;
            case 3:
              return true;
            default:
              break;
          }
        }
        return false;
      }),
      autoSaveList: computed(() => {
        let pitches = this.dirtyPitches;
        let saveList = [];
        pitches.forEach(p => {
          let keyframeTs = p.keyframeTs ? p.keyframeTs : this.defaults["keyframeTs"].toString();
          let saveObj = {
            keyframeTs: keyframeTs,
            pitchCoordinates: p.pitchCoordinates,
            strikeZoneTopLeft: p.strikeZoneTopLeft,
            strikeZoneBottomRight: p.strikeZoneBottomRight,
            waistRow: p.waistRow,
            strikeZoneLower: p.strikeZoneLower,
            szTop: p.szTop,
            szBottom: p.szBottom,
            playId: p.playId,
            fieldOutlineTranslationAdjustment: p.fieldOutlineTranslationAdjustment
          };
          saveList.push(saveObj);
        });
        return saveList;
      }),
      callCorrectnessFilterChecked: computed(() => {
        let checked = false;
        Object.keys(this.callCorrectness).forEach(key => {
          checked = checked || this.callCorrectness[key];
        });
        return checked;
      }),
      callCorrectnessFilterTotals: computed(() => {
        if (this.callCorrectnessTotals) {
          return {
            ACCEPTABLE: this.callCorrectnessTotals.ACCEPTABLE,
            CORRECT: this.callCorrectnessTotals.CORRECT,
            INCORRECT:
              (this.callCorrectnessTotals.INCORRECT ? this.callCorrectnessTotals.INCORRECT : 0) +
              (this.callCorrectnessTotals.CATCHER_INFLUENCE ? this.callCorrectnessTotals.CATCHER_INFLUENCE : 0) +
              (this.callCorrectnessTotals.HIGH_BUFFER ? this.callCorrectnessTotals.HIGH_BUFFER : 0) +
              (this.callCorrectnessTotals.LOW_BUFFER ? this.callCorrectnessTotals.LOW_BUFFER : 0) +
              (this.callCorrectnessTotals.LOW_CATCH ? this.callCorrectnessTotals.LOW_CATCH : 0),
            UNKNOWN: this.callCorrectnessTotals.UNKNOWN
          };
        } else {
          return {};
        }
      }),
      callCorrectnessPopulated: computed(() => {
        let populated = false;
        this.pitches.forEach(pitch => {
          if (pitch.callCorrectness !== "UNKNOWN") {
            populated = true;
          }
        });
        return populated;
      }),
      calledPitches: computed(() => {
        return this.pitches.filter(p => p.callCorrectness !== "UNKNOWN");
      }),
      cameraMarkers: computed(() => {
        const markers = {};
        this.cameraCalMarkers.keys().forEach(key => {
          let marker = this.cameraCalMarkers.get(key);
          //ensures changes are picked up when visible is toggled
          if (marker.visible) {
          }
          markers[key] = marker;
        });
        return markers;
      }),
      dirtyPitches: computed(() => {
        return _.filter(this.pitches, o => o.dirty);
      }),
      fieldOutlineImage: computed(() => {
        return this.selectedPitch && this.selectedPitch.fieldOutlineImage
          ? base64ToImage(this.selectedPitch.fieldOutlineImage)
          : null;
      }),
      fieldTranslationHorizontal: computed(() => {
        return this.selectedPitch && this.selectedPitch.fieldOutlineTranslationAdjustment
          ? this.selectedPitch.fieldOutlineTranslationAdjustment.horizontal
          : 0;
      }),
      fieldTranslationVertical: computed(() => {
        return this.selectedPitch && this.selectedPitch.fieldOutlineTranslationAdjustment
          ? this.selectedPitch.fieldOutlineTranslationAdjustment.vertical
          : 0;
      }),
      isVideoZoomed: computed(() => {
        return this.isVideoZoomedFlag && !this.routerStore.isAuditCameraCalibrationTab;
      }),
      lastTrackedPlay: computed(() => {
        return this.getLastTrackedPitch(this.selectedPitchIndex);
      }),
      setOobPitches: action(values => {
        this.oobPitches = values;
      }),
      pitchCoordinatesAdj: computed(() => {
        if (
          !this.isVideoZoomed ||
          !this.selectedPitch ||
          !this.selectedPitch.pitchCoordinates ||
          !this.pitchCoordinatesTranslated
        ) {
          return this.pitchCoordinatesTranslated;
        } else {
          return {
            before: this.adjustCoordinatesArrayForZoom(this.pitchCoordinatesTranslated.before, true),
            over: this.adjustCoordinatesArrayForZoom(this.pitchCoordinatesTranslated.over, true),
            after: this.adjustCoordinatesArrayForZoom(this.pitchCoordinatesTranslated.after, true),
            midpoint: this.adjustCoordinatesForZoom(this.pitchCoordinatesTranslated.midpoint, true)
          };
        }
      }),
      pitchCoordinatesTranslated: computed(() => {
        if (!this.selectedPitch) {
          return {};
        } else {
          return this.translatePitchCoordinates(
            this.selectedPitch.pitchCoordinates,
            this.fieldTranslationVertical,
            this.fieldTranslationHorizontal
          );
        }
      }),
      pitchesMap: computed(() => {
        let pitchesMap = {};
        this.pitches.forEach(function(pitch) {
          if (pitch.playId) {
            pitchesMap[pitch.playId] = pitch;
          }
        });
        return pitchesMap;
      }),
      pitchNumbersMap: computed(() => {
        let pitchNumbersMap = {};
        this.pitches.forEach(function(pitch) {
          if (pitch.pitchNumber) {
            pitchNumbersMap[pitch.pitchNumber] = pitch;
          }
        });
        return pitchNumbersMap;
      }),
      plateCoordinatesAdjusted: computed(() => {
        if (this.selectedPitch.plateCoordinates) {
          let coordinates = Object.assign({}, this.selectedPitch.plateCoordinates);
          if (this.isVideoZoomed) {
            coordinates.backtip = this.adjustCoordinatesForZoom(coordinates.backtip);
            coordinates.midLeft = this.adjustCoordinatesForZoom(coordinates.midLeft);
            coordinates.midRight = this.adjustCoordinatesForZoom(coordinates.midRight);
            coordinates.frontLeft = this.adjustCoordinatesForZoom(coordinates.frontLeft);
            coordinates.frontRight = this.adjustCoordinatesForZoom(coordinates.frontRight);
          }
          coordinates.backtip = this.adjustForFieldOutlineTransition(coordinates.backtip, this.isVideoZoomed);
          coordinates.midLeft = this.adjustForFieldOutlineTransition(coordinates.midLeft, this.isVideoZoomed);
          coordinates.midRight = this.adjustForFieldOutlineTransition(coordinates.midRight, this.isVideoZoomed);
          coordinates.frontLeft = this.adjustForFieldOutlineTransition(coordinates.frontLeft, this.isVideoZoomed);
          coordinates.frontRight = this.adjustForFieldOutlineTransition(coordinates.frontRight, this.isVideoZoomed);
          return coordinates;
        } else {
          return null;
        }
      }),
      plateMarkersEnabled: computed(() => {
        let enabled = true;
        this.plateMarkerIndexes.forEach(index => {
          if (!this.cameraCalMarkers.get(index).visible) {
            enabled = false;
          }
        });
        return enabled;
      }),
      moundMarkersEnabled: computed(() => {
        let enabled = true;
        this.moundMarkerIndexes.forEach(index => {
          if (!this.cameraCalMarkers.get(index).visible) {
            enabled = false;
          }
        });
        return enabled;
      }),
      secondBaseMarkersEnabled: computed(() => {
        let enabled = true;
        this.secondBaseMarkerIndexes.forEach(index => {
          if (!this.cameraCalMarkers.get(index).visible) {
            enabled = false;
          }
        });
        return enabled;
      }),
      preloadAllowed: computed(() => {
        return !this.routerStore.isAuditCameraCalibrationTab;
      }),
      preloadQuantity: computed(() => {
        return this.pitches.filter(pitch => pitch.preloaded).length;
      }),
      problemPitchList: computed(() => {
        let list = [];
        this.problemPitchKeyOrder.forEach(key => {
          if (this.problemPitchInfo.hasOwnProperty(key) && this.problemPitchInfo[key].length > 0) {
            let title = this.problemPitchKeys[key];
            if (title) {
              let pitches = this.problemPitchInfo[key];
              let called = _.filter(pitches, o => {
                return o.called;
              });
              list.push({
                key: key,
                title: title,
                called: called.length,
                total: pitches.length
              });
            }
          }
        });
        return list;
      }),
      secondBaseLineCoordinates: computed(() => {
        let coordinates = [
          [0, 0],
          [0, 0]
        ];
        let markers = this.cameraCalibration.markers;
        if (
          markers &&
          markers.secondBaseFront &&
          markers.homeplateTipLeft &&
          markers.homeplateTipRight &&
          this.cameraMarkers[4].visible &&
          this.cameraMarkers[5].visible &&
          this.cameraMarkers[13].visible
        ) {
          let startY = (markers.homeplateTipRight[0] - markers.homeplateTipLeft[0]) / 2 + markers.homeplateTipLeft[0];
          let startX = (markers.homeplateTipRight[1] - markers.homeplateTipLeft[1]) / 2 + markers.homeplateTipLeft[1];
          coordinates = [
            [startY, startX],
            [markers.secondBaseFront[0], markers.secondBaseFront[1]]
          ];
        }
        return coordinates;
      }),
      secondBaseLineEquation: computed(() => {
        let equation = {
          slope: 0,
          intersect: 0
        };
        let markers = this.cameraCalibration.markers;
        if (
          markers &&
          markers.secondBaseFront &&
          markers.homeplateTipLeft &&
          markers.homeplateTipRight &&
          this.cameraMarkers[4].visible &&
          this.cameraMarkers[5].visible &&
          this.cameraMarkers[13].visible
        ) {
          let hpLeft = this.addFieldOutlineToCameraCal(markers.homeplateTipLeft, this.selectedPitchFieldTranslation);
          let hpRight = this.addFieldOutlineToCameraCal(markers.homeplateTipRight, this.selectedPitchFieldTranslation);
          let second = this.addFieldOutlineToCameraCal(markers.secondBaseFront, this.selectedPitchFieldTranslation);
          let startRow = (hpRight[0] - hpLeft[0]) / 2 + hpLeft[0];
          let startCol = (hpRight[1] - hpLeft[1]) / 2 + hpLeft[1];
          let slope = (startRow - second[0]) / (startCol - second[1]);
          let intersect = startRow - startCol * slope;
          equation = {
            slope: slope,
            intersect: intersect
          };
        }
        return equation;
      }),
      selectedMarkerCoordinates: computed(() => {
        const id = this.selectedCameraCal;
        if (id > -1) {
          let key = this.cameraCalMarkers.get(id).key;
          if (this.cameraCalibration && this.cameraCalibration.markers) {
            let calibration = this.cameraCalibration.markers[key];
            if (MOUND_IDS.indexOf(id) >= 0) {
              if (id === 70) {
                return calibration[0];
              } else if (id === 71) {
                return calibration[1];
              } else if (id === 72) {
                return calibration[2];
              }
            } else if (BATTERS_BOX_LEFT_IDS.indexOf(id) >= 0) {
              if (id === 90) {
                return calibration[0];
              } else if (id === 91) {
                return calibration[1];
              } else if (id === 92) {
                return calibration[2];
              } else if (id === 93) {
                return calibration[3];
              }
            } else if (BATTERS_BOX_RIGHT_IDS.indexOf(id) >= 0) {
              if (id === 80) {
                return calibration[0];
              } else if (id === 81) {
                return calibration[1];
              } else if (id === 82) {
                return calibration[2];
              } else if (id === 83) {
                return calibration[3];
              }
            }
            return calibration ? calibration : this.cameraCalMarkers.get(id).default;
          }
        }
        return [0, 0];
      }),
      selectedPitchFieldTranslation: computed(() => {
        return this.selectedPitch.fieldOutlineTranslationAdjustment
          ? this.selectedPitch.fieldOutlineTranslationAdjustment
          : { vertical: 0, horizontal: 0 };
      }),
      selectedPitchIndex: computed(() => {
        return _.findIndex(this.pitches, o => o.playId === this.selectedPitch.playId);
      }),
      sortedPitches: computed(() => {
        if (this.pitches) {
          let initialList = this.calledFilter ? this.calledPitches : this.pitches;
          let pitches = Array.prototype.slice.call(initialList);

          if (this.sort.col) {
            pitches.sort(this.comparePitches.bind(this));
          }
          let problemPitches = [];
          let problemPitchFilterUsed = false;
          Object.keys(this.problemPitchFilter).forEach(key => {
            if (this.problemPitchFilter[key]) {
              problemPitchFilterUsed = true;
              problemPitches = _.union(problemPitches, this.problemPitchInfo[key]);
            }
          });
          let callCorrectnessFilterUsed = false;
          let callCorrectnessPitches = [];
          Object.keys(this.callCorrectness).forEach(key => {
            if (this.callCorrectness[key]) {
              callCorrectnessFilterUsed = true;
              let matchingPitches = _.filter(pitches, o => {
                return o.callCorrectness === key;
              });
              callCorrectnessPitches.push(...matchingPitches);
            }
          });

          let filteredPitches = problemPitchFilterUsed ? problemPitches : pitches;
          filteredPitches = callCorrectnessFilterUsed
            ? _.intersectionBy(filteredPitches, callCorrectnessPitches, "playId")
            : filteredPitches;

          filteredPitches = this.oobPitchFilter
            ? _.intersectionBy(filteredPitches, this.oobPitches, "playId")
            : filteredPitches;
          let pitchNumbers = filteredPitches.map(p => p.pitchNumber);
          pitches = pitches.filter(p => pitchNumbers.indexOf(p.pitchNumber) >= 0);
          return pitches;
        } else {
          return [];
        }
      }),
      sortedPitchIdx: computed(() => {
        let index = this.sortedPitches.findIndex(pitch => pitch[this.sort.col] === this.selectedPitch[this.sort.col]);
        return index >= 0 ? index : -1;
      }),
      stepSizeZoomAdjusted: computed(() => {
        return this.isVideoZoomed ? this.stepSize * 2 : this.stepSize;
      }),
      strikeZoneBottomRightAdj: computed(() => {
        if (!this.isVideoZoomed || !this.strikeZoneBottomRightTranslated) {
          return this.strikeZoneBottomRightTranslated;
        } else {
          return this.adjustCoordinatesForZoom(this.strikeZoneBottomRightTranslated);
        }
      }),
      strikeZoneBottomRightTranslated: computed(() => {
        if (!this.selectedPitch || !this.selectedPitch.strikeZoneBottomRight) {
          return this.selectedPitch.strikeZoneBottomRight;
        } else {
          let difference = this.selectedPitch.bottomDifference ? this.selectedPitch.bottomDifference : 0;
          let coordinates = [
            this.selectedPitch.strikeZoneLower * 1.0 - difference,
            this.selectedPitch.strikeZoneBottomRight[1]
          ];
          return this.translateCoordinate2D(
            coordinates,
            this.fieldTranslationVertical,
            this.fieldTranslationHorizontal
          );
        }
      }),
      strikeZoneLowerAdj: computed(() => {
        if (!this.isVideoZoomed || !this.strikeZoneLowerTranslated) {
          return this.strikeZoneLowerTranslated;
        } else {
          return this.adjustCoordinateForZoom(this.strikeZoneLowerTranslated, true);
        }
      }),
      strikeZoneLowerTranslated: computed(() => {
        return !this.selectedPitch
          ? this.selectedPitch
          : this.translateStrikeZoneLower(this.selectedPitch.strikeZoneLower, this.fieldTranslationVertical);
      }),
      strikeZoneTopLeftAdj: computed(() => {
        if (!this.isVideoZoomed || !this.strikeZoneTopLeftTranslated) {
          return this.strikeZoneTopLeftTranslated;
        } else {
          return this.adjustCoordinatesForZoom(this.strikeZoneTopLeftTranslated);
        }
      }),
      strikeZoneTopLeftTranslated: computed(() => {
        if (!this.selectedPitch) {
          return this.selectedPitch;
        } else {
          let difference = this.selectedPitch.topDifference ? this.selectedPitch.topDifference : 0;
          let coordinates = [this.selectedPitch.waistRow * 1.0 - difference, this.selectedPitch.strikeZoneTopLeft[1]];
          return this.translateCoordinate2D(
            coordinates,
            this.fieldTranslationVertical,
            this.fieldTranslationHorizontal
          );
        }
      }),
      toggleCalledFilter: action(() => {
        this.calledFilter = !this.calledFilter;
      }),
      toggleProblemPitchFilter: action(key => {
        this.problemPitchFilter[key] = !this.problemPitchFilter[key];
      }),
      toggleValue: computed(() => {
        let values = this.cameraCalMarkers.values();
        let trueCount = _.filter(values, o => o.visible).length;
        return trueCount === 0;
      }),
      videoZoomed: computed(() => {
        return this.isVideoZoomed && this.routerStore.isAuditStrikeZoneTab;
      }),
      waistRowAdj: computed(() => {
        if (!this.isVideoZoomed || !this.waistRowTranslated) {
          return this.waistRowTranslated;
        } else {
          return this.adjustCoordinateForZoom(this.waistRowTranslated, true);
        }
      }),
      waistRowTranslated: computed(() => {
        return !this.selectedPitch
          ? this.selectedPitch
          : this.translateWaistRow(this.selectedPitch.waistRow, this.fieldTranslationVertical);
      }),
      windowText: computed(() => {
        return (
          "LT " +
          this.gameDisplayName.split(" ")[2] +
          " " +
          (this.gameDisplayTime ? Moment(this.gameDisplayTime).format(DateConstants.DATE_FORMAT_WITH_TIME) : "")
        );
      }),
      calibrationRMSEList: computed(() => {
        let list = [];
        if (this.cameraCalibration.markerSError) {
          for (let key of Object.keys(this.cameraCalibration.markerSError)) {
            let markerId = rmseMarkerMap[key];
            let marker = this.cameraCalMarkers.get(markerId);
            if (marker && marker.visible) {
              list.push({
                name: marker.name,
                value: this.cameraCalibration.markerSError[key]
              });
            }
          }
        }
        return list;
      }),
      worstCalibrationRMSE: computed(() => {
        if (this.cameraCalibration && this.cameraCalibration.markerSError) {
          let obj = { name: "", value: 0.0 };
          for (let key of Object.keys(this.cameraCalibration.markerSError)) {
            let val = this.cameraCalibration.markerSError[key];
            if (val > obj.value) {
              let markerId = rmseMarkerMap[key];
              let marker = this.cameraCalMarkers.get(markerId);
              obj = { name: marker.name, value: val, id: markerId };
            }
          }
          return obj;
        } else {
          return { name: "", value: 0.0 };
        }
      })
    });

    this.hotkeyMap = hotkeyMap;

    this.hotkeyHandlers = {
      nextPitch: event => {
        event.preventDefault();
        this.nextPitch(event);
      },
      prevPitch: event => {
        event.preventDefault();
        this.previousPitch(event);
      },
      stepBackward: event => {
        event.preventDefault();
        this.stepBackward(event);
      },
      stepForward: event => {
        event.preventDefault();
        this.stepForward(event);
      },
      upHandler: event => {
        event.preventDefault();
        this.upHandler(event);
      },
      downHandler: event => {
        event.preventDefault();
        this.downHandler(event);
      },
      leftHandler: event => {
        event.preventDefault();
        this.leftHandler(event);
      },
      rightHandler: event => {
        event.preventDefault();
        this.rightHandler(event);
      },
      toggleZoom: event => {
        event.preventDefault();
        if (this.enableZoom) {
          this.toggleIsVideoZoomed(event);
        }
      },
      toggleHoverAndClick: event => {
        event.preventDefault();
        this.toggleHoverAndClick(event);
      },
      toggleShowBoundaries: event => {
        event.preventDefault();
        this.toggleShowBoundaries();
      },
      toggleAutoPaste: event => {
        event.preventDefault();
        this.toggleAutoPaste(event);
      },
      preloadHotkeyHandler: event => {
        event.preventDefault();
        this.preloadHotkeyHandler(event);
      },
      toggleDoAutoSave: event => {
        event.preventDefault();
        this.toggleDoAutoSave(event);
      },
      toggleCameraCalStrikeZone: event => {
        event.preventDefault();
        this.toggleCameraCalStrikeZone(event);
      },
      topStrikeZoneUpHandler: event => {
        event.preventDefault();
        this.topStrikeZoneUpHandler(event);
      },
      topStrikeZoneDownHandler: event => {
        event.preventDefault();
        this.topStrikeZoneDownHandler(event);
      },
      bottomStrikeZoneUpHandler: event => {
        event.preventDefault();
        this.bottomStrikeZoneUpHandler(event);
      },
      bottomStrikeZoneDownHandler: event => {
        event.preventDefault();
        this.bottomStrikeZoneDownHandler(event);
      },
      plate: event => {
        event.preventDefault();
        this.snapToPlate();
      },
      keyframe: event => {
        event.preventDefault(event);
        this.snapToKeyframe();
      },
      playPauseHandler: event => {
        event.preventDefault();
        this.playPauseHandler(event);
      },
      trackLineHandler: event => {
        event.preventDefault();
        this.toggleTrackLine(event);
      },
      decreaseStepSize: event => {
        event.preventDefault();
        this.decreaseStepSize(event);
      },
      increaseStepSize: event => {
        event.preventDefault();
        this.increaseStepSize(event);
      },
      tabHandler: event => {
        event.preventDefault();
        this.tabHandler(event);
      },
      dirtyHandler: event => {
        event.preventDefault();
        this.dirtyHandler(event);
      },
      prevMarker: event => {
        event.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.changeCalibrationMarkerBackwards();
        }
      },
      nextMarker: event => {
        event.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.changeCalibrationMarkerForward();
        }
      },
      saveCurrentPitchCalibration: event => {
        event.preventDefault();
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.saveCalibration(true);
        } else {
          this.persistFieldOutline(this.selectedPitch, true);
        }
      }
    };

    reaction(
      () => this.selectedPitch,
      () => {
        this.selectedPitchChanged();
      }
    );

    reaction(
      () => this.sortedPitches,
      () => {
        let selectedPitchExists = _.filter(this.sortedPitches, p => {
          return p.playId === this.selectedPitch.playId;
        });
        if (selectedPitchExists.length === 0 && this.sortedPitches.length > 0) {
          this.setSelectedPitch(this.sortedPitches[0]);
        }
      }
    );

    reaction(
      () => this.preloadAllowed,
      () => {
        if (!this.preloadAllowed) {
          this.preload = false;
        }
      }
    );

    reaction(
      () => this.autoPaste,
      () => {
        this.setAllPreloadedFlags(false);
        if (this.preload && this.selectedPitch) {
          this.preloadPitches(this.sortedPitchIdx);
        }
      }
    );

    reaction(
      () => this.routerStore.isAuditResearchTab,
      () => {
        if (this.routerStore.isAuditResearchTab) {
          this.getResearchInfo();
        }
      }
    );

    reaction(
      () => this.routerStore.isAuditCameraCalibrationTab,
      () => {
        if (this.routerStore.isAuditCameraCalibrationTab) {
          this.setLoading(true);
          this.getCameraCalibration(this.selectedPitch.playId);
        }
      }
    );

    reaction(
      () => this.routerStore.isAuditStrikeZoneTab,
      () => {
        if (this.routerStore.isAuditStrikeZoneTab) {
          this.newPitchKeyframeFlag = true;
        }
      }
    );

    reaction(
      () => this.pitchListRef,
      () => {
        this.scrollPitchTable(this.sortedPitchIdx);
      }
    );

    reaction(
      () => now(),
      () => {
        if (this.routerStore.isAuditTab && this.doAutoSave) {
          let d = new Date(now());
          if (d.getSeconds() % 60 === 0) {
            this.autoSave();
          }
        }
      }
    );

    autorun(() => {
      this.isAdmin = authStore.containsAuthorities(this.adminRoles);
      if (authStore.isLoggedIn && this.routerStore.isAuditTab) {
        this.isStrikeZone = this.routerStore.location.pathname.indexOf("strike-zone") >= 0;
        this.updateFromUrlParams(this.routerStore.location.search);
      } else {
        this.resetAuditStore();
      }
    });
  }

  // other functions
  addDefaultStrikeZoneLinesAndFieldOutline(p) {
    p.waistRow = null;
    p.strikeZoneLower = null;
    p.strikeZoneTopLeft = [];
    p.strikeZoneBottomRight = [];
    p.pitchCoordinates = null;
    p.fieldOutlineImage = null;
    p.fieldOutlineTranslationAdjustment = null;
    p.waistRowBoundaries = [];
    p.kneeRowBoundaries = [];
    p.preloaded = false;
    p.videoLoaded = false;
    return observable(p);
  }

  addFieldOutlineToCameraCal(tempMarker, fieldTranslation) {
    let newMarker = [];
    let marker = isObservable(tempMarker) ? tempMarker.toJS() : tempMarker;
    if (Array.isArray(marker) && marker.length > 0) {
      if (isObservable(marker[0])) {
        for (let i = 0; i < marker.length; i++) {
          let tempMarker = [];
          tempMarker[0] = marker[i][0] + fieldTranslation.vertical;
          tempMarker[1] = marker[i][1] + fieldTranslation.horizontal;
          newMarker[i] = tempMarker;
        }
      } else {
        newMarker[0] = marker[0] + fieldTranslation.vertical;
        newMarker[1] = marker[1] + fieldTranslation.horizontal;
      }
    } else {
      newMarker = marker;
    }
    return newMarker;
  }

  adjustCoordinatesArrayForZoom(coordinatePairs) {
    let adjustedCoordinates = [];
    for (let i = 0; i < coordinatePairs.length; i++) {
      adjustedCoordinates.push(this.adjustCoordinatesForZoom(coordinatePairs[i]));
    }
    return adjustedCoordinates;
  }

  adjustCoordinateForZoom(singleCoordinate, isRow) {
    let adjustVideoZoomTop = this.videoZoomTop;
    let adjustVideoZoomLeft = this.videoZoomLeft;
    if (this.selectedPitch && this.selectedPitch.cameraPan) {
      adjustVideoZoomTop = this.selectedPitch.cameraPan.videoZoomTop;
      adjustVideoZoomLeft = this.selectedPitch.cameraPan.videoZoomLeft;
    }

    if (isRow) {
      return this.videoCenter[0] + (singleCoordinate - this.videoCenter[0]) * this.videoZoomFactor + adjustVideoZoomTop;
    } else {
      return (
        this.videoCenter[1] + (singleCoordinate - this.videoCenter[1]) * this.videoZoomFactor + adjustVideoZoomLeft
      );
    }
  }

  adjustCoordinatesForZoom(coordinatePair) {
    let adjustVideoZoomTop = this.videoZoomTop;
    let adjustVideoZoomLeft = this.videoZoomLeft;
    if (this.selectedPitch && this.selectedPitch.cameraPan) {
      adjustVideoZoomTop = this.selectedPitch.cameraPan.videoZoomTop;
      adjustVideoZoomLeft = this.selectedPitch.cameraPan.videoZoomLeft;
    }

    let adjustedCoordinates = [0, 0];
    adjustedCoordinates[0] =
      this.videoCenter[0] + (coordinatePair[0] - this.videoCenter[0]) * this.videoZoomFactor + adjustVideoZoomTop;
    adjustedCoordinates[1] =
      this.videoCenter[1] + (coordinatePair[1] - this.videoCenter[1]) * this.videoZoomFactor + adjustVideoZoomLeft;
    return adjustedCoordinates;
  }

  adjustForFieldOutlineTransition(coordinatePair, zoomed) {
    let adjustedCoordinates = [0, 0];
    let vertical = zoomed
      ? this.selectedPitch.fieldOutlineTranslationAdjustment.vertical * 2
      : this.selectedPitch.fieldOutlineTranslationAdjustment.vertical;
    let horizontal = zoomed
      ? this.selectedPitch.fieldOutlineTranslationAdjustment.horizontal * 2
      : this.selectedPitch.fieldOutlineTranslationAdjustment.horizontal;
    adjustedCoordinates[0] = coordinatePair[0] + vertical;
    adjustedCoordinates[1] = coordinatePair[1] + horizontal;
    return adjustedCoordinates;
  }

  applyCalibration() {
    let plays = this.calculateCalibrationPlays(this.applyCalibrationValues, this.sortedPitches);
    let applyCalibrationObj = {
      plays: plays
    };
    this.loadingStore.setLoading(
      true,
      "Applying",
      "Applying calibration from pitch " +
        this.selectedPitch.pitchNumber +
        " to " +
        plays.length +
        (plays.length !== 1 ? " pitches." : " pitch."),
      75
    );
    this.zeApi.applyCalibration(this.gamePk, this.selectedPitch.playId, applyCalibrationObj).then(data => {
      if (!data || data.error || (data.data && data.data.error)) {
        this.loadingStore.setLoading(false, this.windowText);
        console.log(data);
      } else {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text:
            "Calibration successfully applied to " +
            data.data.length +
            (data.data.length !== 1 ? " pitches." : " pitch.")
        });
        this.generateImages(plays, true);
        this.cleanPitches(plays);
      }
    });
  }

  calculateCalibrationPlays(applyCalibrationValues, sortedPitches) {
    let plays = [];
    if (applyCalibrationValues.calibrationMode === 2) {
      let split = applyCalibrationValues.list.split(",");
      for (let idx of split) {
        plays.push(sortedPitches[idx]["playId"]);
      }
    } else {
      let rangeStart = applyCalibrationValues.calibrationMode === 1 ? applyCalibrationValues.rangeStart - 1 : 0;
      let rangeEnd =
        applyCalibrationValues.calibrationMode === 1 ? applyCalibrationValues.rangeEnd : sortedPitches.length - 1;
      for (let idx = rangeStart; idx < rangeEnd; idx++) {
        if (sortedPitches[idx] && sortedPitches[idx]["playId"]) {
          plays.push(sortedPitches[idx]["playId"]);
        }
      }
    }
    return plays;
  }

  calculateStrikeZoneChange(canvasY, szY) {
    return this.isVideoZoomed ? (canvasY - szY) / this.videoZoomFactor : canvasY - szY;
  }

  //function checks if the existing camera calibration has a field and if not sets the field to not visible and the default value
  checkAndSetCameraCalibration(finalCalibration, currentCalibration, field) {
    let calibrations = _.filter(this.cameraCalMarkers.values(), o => o.key === field);
    let exists = !!currentCalibration.markers[field];
    if (exists) {
      finalCalibration[field] = currentCalibration.markers[field];
    } else {
      finalCalibration[field] = Array.isArray(calibrations) ? calibrations[0].default : calibrations.default;
    }
    if (Array.isArray(calibrations)) {
      calibrations.forEach(o => {
        if (o.visible !== exists) {
          this.toggleCameraMarker(o.id);
        }
      });
    } else {
      if (calibrations.visible !== exists) {
        this.toggleCameraMarker(calibrations.id);
      }
    }
    return finalCalibration;
  }

  comparePitches(pitchA, pitchB) {
    switch (this.sort.col) {
      case "At Bat":
        return this.sort.asc ? pitchA.atBatNumber - pitchB.atBatNumber : pitchB.atBatNumber - pitchA.atBatNumber;
      case "AB Pitch":
        return this.sort.asc ? pitchA.pitchOfAtBat - pitchB.pitchOfAtBat : pitchB.pitchOfAtBat - pitchA.pitchOfAtBat;
      case "Batter":
        return this.sort.asc
          ? pitchA.batterName.localeCompare(pitchB.batterName)
          : pitchB.batterName.localeCompare(pitchA.batterName);
      case "Batter Side":
        return this.sort.asc
          ? pitchA.batterSide.localeCompare(pitchB.batterSide)
          : pitchB.batterSide.localeCompare(pitchA.batterSide);
      case "Call":
        return this.sort.asc
          ? pitchA.umpireCallCode.localeCompare(pitchB.umpireCallCode)
          : pitchB.umpireCallCode.localeCompare(pitchA.umpireCallCode);
      case "Inning":
        let pitchAInning = pitchA.inning + (pitchA.topOfInning ? 0.1 : 0.2);
        let pitchBInning = pitchB.inning + (pitchB.topOfInning ? 0.1 : 0.2);
        return this.sort.asc ? pitchAInning - pitchBInning : pitchBInning - pitchAInning;
      case "Pitch":
        return this.sort.asc ? pitchA.pitchNumber - pitchB.pitchNumber : pitchB.pitchNumber - pitchA.pitchNumber;
      case "Pitch Type":
        return this.sort.asc
          ? pitchA.pitchType.localeCompare(pitchB.pitchType)
          : pitchB.pitchType.localeCompare(pitchA.pitchType);
      case "Pitcher":
        return this.sort.asc
          ? pitchA.pitcherName.localeCompare(pitchB.pitcherName)
          : pitchB.pitcherName.localeCompare(pitchA.pitcherName);
      case "Play ID":
        return this.sort.asc ? pitchA.playId.localeCompare(pitchB.playId) : pitchB.playId.localeCompare(pitchA.playId);
      default:
        return this.sort.asc ? pitchA.pitchNumber - pitchB.pitchNumber : pitchB.pitchNumber - pitchA.pitchNumber;
    }
  }

  generateImages(plays, showLoading) {
    let generateImagesObj = {
      plays: plays
    };

    if (showLoading) {
      this.loadingStore.setLoading(
        true,
        "Generating",
        "Generating images for " + plays.length + (plays.length !== 1 ? " pitches." : " pitch."),
        75
      );
    }

    this.setCalibrationStatus(CalibrationStatus.GENERATING);
    this.zeApi.generateImages(this.gamePk, generateImagesObj).then(data => {
      this.setCalibrationStatus(CalibrationStatus.IDLE);
      if (!data || data.error || (data.data && data.data.error)) {
        console.log(data);
      } else {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text:
            "Images successfully generated for " +
            data.successPlays.length +
            (data.successPlays.length !== 1 ? " pitches." : " pitch.")
        });
        if (data.errorPlays && data.errorPlays.length > 0) {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.WARNING,
            text:
              "Error generating images for " +
              data.errorPlays.length +
              (data.errorPlays.length !== 1 ? " pitches." : " pitch.") +
              ": " +
              data.errorPlays.join(", ")
          });
        }
      }
      this.loadingStore.setLoading(false, this.windowText);
    });
  }

  getLastTrackedPitch(currentIndex) {
    let currentPitch = this.sortedPitches[currentIndex];
    if (!currentPitch) {
      return null;
    } else if (currentPitch.accuracy.tracked) {
      return currentPitch;
    } else if (currentIndex <= 0) {
      return null;
    }
    return this.getLastTrackedPitch(--currentIndex);
  }

  getTranslation(pitch, applyZoom) {
    const translationAdjustment =
      pitch && pitch.fieldOutlineTranslationAdjustment ? pitch.fieldOutlineTranslationAdjustment : null;
    const vertical = translationAdjustment
      ? applyZoom
        ? translationAdjustment.vertical * 2
        : translationAdjustment.vertical
      : 0;
    const horizontal = translationAdjustment
      ? applyZoom
        ? translationAdjustment.horizontal * 2
        : translationAdjustment.horizontal
      : 0;
    return {
      vertical: vertical,
      horizontal: horizontal
    };
  }

  handleRefreshPitch(pitch, data) {
    pitch.umpireCallCode = data.umpireCallCode;
    pitch.pitchType = data.pitchType;
    pitch.szTop = data.szTop;
    pitch.szBottom = data.szBottom;
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Pitch " + pitch.pitchNumber + " has been refreshed."
    });
  }

  initializeOrAnalyze(pitches) {
    this.zeApi.getGames(this.gamePk).then(data => {
      if (data.entities && !data.entities.some(g => g.status === "FINALIZED")) {
        if (data.entities.some(g => g.status === "INITIALIZED")) {
          this.zeApi.analyzePitches(this.gamePk, pitches);
        } else {
          this.zeApi.initializeGame(this.gamePk);
        }
      }
    });
  }

  isStrikeZoneClose(line, x, y) {
    let lineLength = this.isVideoZoomed ? 200 : 100;
    if (this.selectedPitch.batterSide === "R") {
      let coordinateX = this.isVideoZoomed ? this.strikeZoneBottomRightAdj[1] : this.strikeZoneBottomRightTranslated[1];
      return x - coordinateX < lineLength && x > coordinateX && Math.abs(line - y) < 5;
    } else {
      let coordinateX = this.isVideoZoomed ? this.strikeZoneTopLeftAdj[1] : this.strikeZoneTopLeftTranslated[1];
      return coordinateX - x < lineLength && coordinateX > x && Math.abs(line - y) < 5;
    }
  }

  isUmpireCallCodeCalled(pitch) {
    return (
      pitch &&
      (pitch.umpireCallCode === "Ball" ||
        pitch.umpireCallCode === "Ball In Dirt" ||
        pitch.umpireCallCode === "Blocked Ball" ||
        pitch.umpireCallCode === "Called Strike")
    );
  }

  isValidStrikeZoneDownMove(change) {
    return this.selectedPitch.strikeZoneLower + change - 5 > this.selectedPitch.waistRow;
  }

  isValidStrikeZoneUpMove(change) {
    return this.selectedPitch.waistRow + change + 5 < this.selectedPitch.strikeZoneLower;
  }

  loadVideos(pitchIdx) {
    let lastIdx = Math.min(this.pitches.length - 1, pitchIdx + this.preloadRange);
    let plays = [];
    let videoUrls = [];
    for (let idx = pitchIdx + 1; idx <= lastIdx; idx++) {
      if (this.sortedPitches[idx] && !this.sortedPitches[idx].videoLoaded) {
        plays.push(this.sortedPitches[idx]);
        videoUrls.push(this.sortedPitches[idx].cfVideoUrl);
        this.sortedPitches[idx].videoLoaded = true;
      }
    }
    this.preloadVideos = videoUrls;
  }

  moveCalibrationMarker(direction) {
    if (!(this.selectedCameraCal && this.selectedMarkerCoordinates)) {
      return;
    }
    switch (direction) {
      case "up":
        this.selectedMarkerCoordinates[0] -= this.stepSize;
        break;
      case "down":
        this.selectedMarkerCoordinates[0] += this.stepSize;
        break;
      case "left":
        this.selectedMarkerCoordinates[1] -= this.stepSize;
        break;
      case "right":
        this.selectedMarkerCoordinates[1] += this.stepSize;
        break;
      default:
        break;
    }
    let pitchMarkers = this.dirtyCamCalMarkers[this.selectedPitch.pitchNumber]
      ? this.dirtyCamCalMarkers[this.selectedPitch.pitchNumber]
      : {};
    pitchMarkers[this.selectedCameraCal] = true;
    this.dirtyCamCalMarkers[this.selectedPitch.pitchNumber] = pitchMarkers;
  }

  moveCalibrationMarkerToPoint(x, y) {
    if (!(this.selectedCameraCal && this.selectedMarkerCoordinates)) {
      return;
    }
    this.selectedMarkerCoordinates[0] = y;
    this.selectedMarkerCoordinates[1] = x;
    let pitchMarkers = this.dirtyCamCalMarkers[this.selectedPitch.pitchNumber]
      ? this.dirtyCamCalMarkers[this.selectedPitch.pitchNumber]
      : {};
    pitchMarkers[this.selectedCameraCal] = true;
    this.dirtyCamCalMarkers[this.selectedPitch.pitchNumber] = pitchMarkers;
  }

  persistPitchChanges(pitch, newDefault, waistRow, kneeRow) {
    this.persistFieldOutline(pitch, newDefault).then(data => {
      this.persistStrikeZone(pitch, waistRow, kneeRow).then(d => {
        pitch.dirty = true;
        this.updatePitchStrikeZone(pitch, d);
      });
    });
  }

  persistFieldOutline(p, newDefault) {
    let fieldOutline = {
      translationAdjustment: {
        vertical: 0,
        horizontal: 0
      },
      newDefault: newDefault
    };
    fieldOutline.translationAdjustment.horizontal = p.fieldOutlineTranslationAdjustment
      ? p.fieldOutlineTranslationAdjustment.horizontal
      : 0;
    fieldOutline.translationAdjustment.vertical = p.fieldOutlineTranslationAdjustment
      ? p.fieldOutlineTranslationAdjustment.vertical
      : 0;
    return this.zeApi.updateFieldOutline(fieldOutline, p.gamePk, p.playId);
  }

  persistStrikeZone(p, waistRow, kneeRow) {
    let strikeZone = {
      waistRow: "",
      kneeRow: ""
    };
    strikeZone.waistRow = waistRow;
    strikeZone.kneeRow = kneeRow;
    return this.zeApi.updateStrikeZone(strikeZone, p.gamePk, p.playId);
  }

  preloadPitchesData(sourcePitch, targetPitches) {
    if (targetPitches && targetPitches.length > 0) {
      targetPitches.forEach(targetPitch => {
        if (!targetPitch.accuracy.tracked) {
          this.loadingStore.setLoading(false, this.windowText);
          return;
        }
        this.getFieldOutlineAndStrikeZone(targetPitch, true);
      });
    }
  }

  preloadPitchesDataAutoPaste(sourcePitch, targetPitches) {
    if (targetPitches && targetPitches.length > 0) {
      let plays = [];
      targetPitches.forEach(targetPitch => {
        this.setFieldOutlineTranslationAdjustment({
          fieldOutlineTranslationAdjustment: JSON.parse(JSON.stringify(sourcePitch.fieldOutlineTranslationAdjustment)),
          pitch: targetPitch
        });
        plays.push(targetPitch.playId);
      });

      let applyCalibrationObj = {
        plays: plays
      };

      this.zeApi.applyCalibration(this.gamePk, sourcePitch.playId, applyCalibrationObj).then(data => {
        this.zeApi.generateImages(this.gamePk, applyCalibrationObj).then(data => {
          targetPitches.forEach(targetPitch => {
            if (!targetPitch.accuracy.tracked) {
              return;
            }
            this.setFieldOutlineImage({
              pitch: targetPitch,
              fieldOutlineImage: sourcePitch.fieldOutlineImage
            });
            this.zeApi.getStrikeZone(this.gamePk, targetPitch.playId).then(szData => {
              this.setStrikeZoneData(szData, targetPitch);
              targetPitch.preloaded = true;
              if (this.preloadQuantity >= targetPitches.length) {
                this.loadingStore.setLoading(false, this.windowText);
              }
            });
          });
        });
      });
    }
  }

  preloadPitches(pitchIdx) {
    let lastIdx = Math.min(this.sortedPitches.length - 1, pitchIdx + this.preloadRange);
    let sourcePitch = this.sortedPitches[pitchIdx];
    let plays = [];
    for (let idx = pitchIdx + 1; idx <= lastIdx; idx++) {
      if (!this.sortedPitches[idx].preloaded) {
        plays.push(this.sortedPitches[idx]);
      }
    }
    if (sourcePitch.accuracy.tracked) {
      if (this.autoPaste) {
        this.preloadPitchesDataAutoPaste(sourcePitch, plays);
      } else {
        this.preloadPitchesData(sourcePitch, plays);
      }
    }
  }

  async saveCalibration(defaultValue) {
    let markers = {};
    this.cameraCalMarkers.forEach((value, keyStr, map) => {
      let key = keyStr * 1;
      if (value.visible && this.cameraCalMarkersSequence.indexOf(key) >= 0) {
        markers[value.key] = this.cameraCalibration.markers[value.key];
      }
    });
    let calibrationObj = {
      markers: markers,
      newDefaultCalibration: defaultValue
    };
    let pitch = this.selectedPitch;
    let plays = [pitch.playId];
    let previousRMSE = this.cameraCalibration.rMSE;
    this.setCalibrationStatus(CalibrationStatus.SAVING);
    return new Promise((resolve, reject) => {
      this.zeApi.saveCalibration(this.gamePk, this.selectedPitch.playId, calibrationObj).then(response => {
        if (!response || response.error || response.data?.error) {
          this.setCalibrationStatus(CalibrationStatus.IDLE);
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: "Error: Calibration not saved."
          });
          reject(false);
        } else if (response.data.errors.length) {
          this.setCalibrationStatus(CalibrationStatus.IDLE);
          this.setCameraCalibration(response.data);
          this.setRMSEColor(previousRMSE, response.data.rMSE);
          reject(false);
        } else {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Calibration saved successfully."
          });
          this.setCameraCalibration(response.data);
          this.setRMSEColor(previousRMSE, response.data.rMSE);
          this.setFieldOutlineTranslationAdjustment({
            fieldOutlineTranslationAdjustment: this.defaults["fieldOutlineTranslationAdjustment"],
            pitch: this.selectedPitch
          });
          this.generateImages(plays, false);
          this.selectedPitch.dirty = false;
          this.dirtyCamCalMarkers = this.defaults["dirtyCamCalMarkers"];
          this.persistFieldOutline(pitch, defaultValue)
            .then(data => {
              resolve(true);
              this.getFieldOutlineAndStrikeZone(this.selectedPitch, false);
            })
            .catch(error => {
              reject(false);
            });
        }
      });
    });
  }

  saveCalOnly() {
    let centerfields = this.pitches
      .filter(p => p.pitchCoordinates)
      .map(p => {
        let keyFrameMs = p.keyframeTs ? p.keyframeTs * 1000 : this.defaults["keyframeTs"].toString();
        let fieldTranslationVertical = this.getFieldTranslationVertical(p);
        let fieldTranslationHorizontal = this.getFieldTranslationHorizontal(p);
        let pitchCoordinatesTranslated = this.translatePitchCoordinates(
          p.pitchCoordinates,
          fieldTranslationVertical,
          fieldTranslationHorizontal
        );
        let strikeZoneTopLeftTranslated = this.translateCoordinate2D(
          p.strikeZoneTopLeft,
          fieldTranslationVertical,
          fieldTranslationHorizontal
        );
        let strikeZoneBottomRightTranslated = this.translateCoordinate2D(
          p.strikeZoneBottomRight,
          fieldTranslationVertical,
          fieldTranslationHorizontal
        );
        let waistRowTranslated = this.translateWaistRow(
          p.waistRow,
          fieldTranslationVertical,
          fieldTranslationHorizontal
        );
        let strikeZoneLowerTranslated = this.translateStrikeZoneLower(
          p.strikeZoneLower,
          fieldTranslationVertical,
          fieldTranslationHorizontal
        );
        return {
          play_id: p.playId,
          strike_zone_top: p.szTop,
          strike_zone_bottom: p.szBottom,
          keyframeMs: keyFrameMs,
          pitchCoordinates: pitchCoordinatesTranslated,
          waistRow: waistRowTranslated,
          kneeRow: strikeZoneLowerTranslated,
          szTopLeft: strikeZoneTopLeftTranslated,
          szBottomRight: strikeZoneBottomRightTranslated,
          cameraPan: {
            videoZoomTop: this.videoZoomTop,
            videoZoomLeft: this.videoZoomLeft
          },
          updatedBy: p.updatedBy,
          updatedOn: p.updatedOn
        };
      });
    this.loadingStore.setLoading(true, "Saving", "Saving Calibration Data", 75);
    this.zeApi
      .saveCenterfields(this.gamePk, centerfields)
      .then(data => {
        this.loadingStore.setLoading(false);
      })
      .catch(() => {
        this.loadingStore.setLoading(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "An unexpected error occurred while saving calibration data."
        });
      });
  }

  //if the selected pitch changed make sure you get
  selectedPitchChanged() {
    if (this.selectedPitch && this.selectedPitch !== {}) {
      let playId = this.selectedPitch.playId;
      if (!window.location.hash && playId) {
        window.location.hash = playId;
      }
      this.getCameraCalibration(playId);
      if (this.selectedPitch.preloaded) {
        this.getResearchInfo();
        this.selectedPitch.preloaded = false;
      } else if (!this.selectedPitch.dirty && this.selectedPitch.gamePk && playId) {
        this.setLoading(true);
        this.getResearchInfo();
        this.getFieldOutlineAndStrikeZone(this.selectedPitch);
      } else {
        this.setLoading(false);
      }
      if (this.preload) {
        this.preloadPitches(this.sortedPitchIdx);
      }
    }
    this.loadVideos(this.sortedPitchIdx);
  }

  setAllPreloadedFlags(flagValue) {
    this.pitches.forEach(function(p) {
      p.preloaded = flagValue;
    });
  }

  setStrikeZoneData(data, pitch) {
    if (data.plateCoordinates) {
      this.setPlateCoordinates({
        plateCoordinates: data.plateCoordinates,
        pitch: pitch
      });
    }
    this.setWaistRow({
      waistRow: data.waistRow,
      pitch: pitch
    });
    this.setWaistRowBoundaries({
      waistRowBoundaries: data.waistRowBoundaries,
      pitch: pitch
    });
    this.setStrikeZoneLower({
      strikeZoneLower: data.kneeRow,
      pitch: pitch
    });
    this.setKneeRowBoundaries({
      kneeRowBoundaries: data.kneeRowBoundaries,
      pitch: pitch
    });
    this.setPitchCoordinates({
      pitchCoordinates: data.pitchCoordinates,
      pitch: pitch
    });
    this.setStrikeZoneTopLeft({
      topLeftCoordinate: data.topLeftCoordinate,
      pitch: pitch
    });
    this.setStrikeZoneBottomRight({
      bottomRightCoordinate: data.bottomRightCoordinate,
      pitch: pitch
    });
    this.setStrikeZoneTopDifference({ pitch: pitch });
    this.setStrikeZoneBottomDifference({ pitch: pitch });
    if (data.keyframeSec && !pitch.keyframeTs) {
      this.setKeyFrameTs({
        keyframeTs: data.keyframeSec,
        pitch: pitch
      });
      if (pitch.playId === this.selectedPitch.playId) {
        this.snapToKeyframe();
      }
    }
    if (data.plateSec) {
      this.setPlateTs({
        plateTs: data.plateSec + this.timingOffset / 1000,
        pitch: pitch
      });
    }
    this.updatePitchStrikeZone(pitch, data);
  }

  translateCoordinate2D(originalCoordinate, adjRow, adjCol) {
    return [originalCoordinate[0] + adjRow, originalCoordinate[1] + adjCol];
  }

  updatePitchStrikeZone(pitch, data) {
    pitch.szTop = data.dimensionsInFeet.top;
    pitch.szBottom = data.dimensionsInFeet.bottom;
    pitch.strikeZoneTopLeft = data.topLeftCoordinate;
    pitch.strikeZoneBottomRight = data.bottomRightCoordinate;
    this.setPitchInList(pitch);
  }

  getOobPitches(dirtyPitches, players) {
    return dirtyPitches
      .filter(p => {
        const player = players[p.batterId];
        return (
          p.szTop > player.strikeZoneTopMax ||
          p.szTop < player.strikeZoneTopMin ||
          p.szBottom > player.strikeZoneBottomMax ||
          p.szBottom < player.strikeZoneBottomMin
        );
      })
      .map(q => {
        const player = players[q.batterId];
        let pitch = Object.assign({}, q);
        pitch.topFlag = q.szTop > player.strikeZoneTopMax || q.szTop < player.strikeZoneTopMin;
        pitch.bottomFlag = q.szBottom > player.strikeZoneBottomMax || q.szBottom < player.strikeZoneBottomMin;
        return pitch;
      });
  }
}

export default AuditStore;
