import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ZeTable from "../../elements/table/ZeTable";

const TableCell = styled.td`
  font-size: 10px;
  font-weight: 300;
  height: 21px;
  padding: 2px 4px !important;
`;

const TableHeader = styled.th`
  font-size: 10px;
  font-weight: 300;
  height: 21px;
  padding: 2px 4px !important;
`;

const GameViewerHeaderUmpireTable = ({ rootStore: { gameViewerStore } }) => {
  const calculatePercent = (amt, total) => {
    return ((amt / total) * 100).toFixed(2);
  };
  const { game, gameDetails, gameScores, hasEdgeFilter, isMilbGame } = gameViewerStore;
  const { absMode } = game;
  const isChallenge = absMode === "challengeABS";
  return (
    <ZeTable className="table-sm">
      <tbody>
        <tr>
          <TableHeader>Total</TableHeader>
          <TableCell>{gameDetails.total}</TableCell>
        </tr>
        <tr>
          <TableHeader>Correct</TableHeader>
          <TableCell>
            {gameDetails.correct}&nbsp;/&nbsp;
            {calculatePercent(gameDetails.correct, gameDetails.total)}%
          </TableCell>
        </tr>
        {!isMilbGame && !(absMode && absMode !== "noABS") && (
          <tr>
            <TableHeader>Acceptable</TableHeader>
            <TableCell>
              {gameDetails.acceptable}&nbsp;/&nbsp;
              {calculatePercent(gameDetails.acceptable, gameDetails.total)}%
            </TableCell>
          </tr>
        )}
        <tr>
          <TableHeader>Incorrect</TableHeader>
          <TableCell>
            {gameDetails.incorrect + gameDetails.adjusted}&nbsp;/&nbsp;
            {calculatePercent(gameDetails.incorrect + gameDetails.adjusted, gameDetails.total)}%
          </TableCell>
        </tr>
        {isChallenge && (
          <tr>
            <TableHeader>Challenges</TableHeader>
            <TableCell>
              {gameDetails.challenges}&nbsp;/&nbsp;
              {calculatePercent(gameDetails.challenges, gameDetails.total)}%
            </TableCell>
          </tr>
        )}
        {isChallenge && (
          <tr>
            <TableHeader>Calls Confirmed</TableHeader>
            <TableCell>{gameDetails.callsConfirmed}</TableCell>
          </tr>
        )}
        {isChallenge && (
          <tr>
            <TableHeader>Calls Overturned</TableHeader>
            <TableCell>{gameDetails.callsOverturned}</TableCell>
          </tr>
        )}
        {!isMilbGame && (
          <tr>
            <TableHeader>Adjusted</TableHeader>
            <TableCell>{gameDetails.adjusted}</TableCell>
          </tr>
        )}
        {!isMilbGame && (
          <tr>
            <TableHeader>Total Incorrect</TableHeader>
            <TableCell>{gameDetails.incorrect}</TableCell>
          </tr>
        )}
        {hasEdgeFilter ? (
          <tr>
            <TableHeader>Edge</TableHeader>
            <TableCell>{gameScores.edge}</TableCell>
          </tr>
        ) : null}
      </tbody>
    </ZeTable>
  );
};

export default inject("rootStore")(observer(GameViewerHeaderUmpireTable));
