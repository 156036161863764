export const auditStoreDefaults = {
  autoPaste: false,
  autoSaveDate: null,
  autoSave: false,
  callCorrectness: {
    ACCEPTABLE: false,
    CATCHER_INFLUENCE: false,
    CORRECT: false,
    HIGH_BUFFER: false,
    INCORRECT: false,
    LOW_BUFFER: false,
    LOW_CATCH: false,
    UNKNOWN: false
  },
  callCorrectnessTotals: {},
  calledFilter: false,
  confirmationModalTitle: "Confirm Changes",
  controls: false,
  roll: 0.0,
  currentRequests: {},
  frameInfo: null,
  gameDisplayName: "",
  gameDisplayTime: "",
  gamePk: "",
  isAdmin: false,
  isStrikeZone: false,
  isVideoZoomed: true,
  keyframeOffset: 0,
  loading: false,
  modalAction: null,
  newPitchKeyframeFlag: false,
  oobPitches: [],
  oobPichFilter: false,
  pitches: [],
  players: {},
  preloadVideos: [],
  problemPitchFilter: {
    broadcastVideo: false,
    centerfieldVideo: false,
    missingCalibration: false,
    opticalTracking: false,
    pitchcastVideo: false,
    reducedConfidence: false,
    untrackedBip: false,
    untrackedPitch: false,
    withoutGuid: false,
    withoutSpin: false
  },
  problemPitchInfo: {},
  problemPitchKeys: {
    broadcastVideo: "Broadcast",
    centerfieldVideo: "Legacy CF",
    missingCalibration: "No Calibration",
    opticalTracking: "Optical",
    pitchcastVideo: "Pitchcast",
    reducedConfidence: "Reduced Confidence",
    untrackedBip: "BIP",
    untrackedPitch: "Untracked",
    withoutGuid: "No Play ID",
    withoutSpin: "No Spin"
  },
  problemPitchKeyOrder: [
    "untrackedPitch",
    "withoutGuid",
    "reducedConfidence",
    "missingCalibration",
    "pitchcastVideo",
    "centerfieldVideo",
    "broadcastVideo",
    "opticalTracking",
    "withoutSpin"
  ],
  researchImage: null,
  selectedPitch: {},
  showBoundaries: {
    top: false,
    bottom: false
  },
  showCancelModal: false,
  showConfirmationModal: false,
  showPitchArc: true,
  stepSize: 0.5,
  smoothing: 0,
  targetDepth: 31,
  videoCurrentTime: 0,
  videoDuration: 0
};

export const hotkeyMap = {
  nextPitch: "s",
  prevPitch: "a",
  stepBackward: "z",
  stepForward: "x",
  upHandler: "up",
  downHandler: "down",
  leftHandler: "left",
  rightHandler: "right",
  autoPasteHandler: "v",
  topStrikeZoneUpHandler: "-",
  topStrikeZoneDownHandler: "[",
  bottomStrikeZoneUpHandler: "=",
  bottomStrikeZoneDownHandler: "]",
  playPauseHandler: "space",
  tabHandler: "`",
  dirtyHandler: "b",
  keyframe: "d",
  plate: "f",
  pitchArc: "/",
  decreaseStepSize: "e",
  increaseStepSize: "r"
};

export const battersBoxEdges = {
  right: {
    inner: -1.20833,
    outer: -5.20833
  },
  left: {
    inner: 1.20833,
    outer: 5.20833
  }
};

export const battersBoxes = [
  {
    x: 1.2083,
    y: 3.7083,
    z: 0
  },
  {
    x: 5.2083,
    y: 3.7083,
    z: 0
  },
  {
    x: 5.2083,
    y: -2.2917,
    z: 0
  },
  {
    x: 1.2083,
    y: -2.2917,
    z: 0
  },
  {
    x: -1.2083,
    y: 3.7083,
    z: 0
  },
  {
    x: -5.2083,
    y: 3.7083,
    z: 0
  },
  {
    x: -5.2083,
    y: -2.2917,
    z: 0
  },
  {
    x: -1.2083,
    y: -2.2917,
    z: 0
  }
];

export const moundCircle = {
  x: 0,
  y: 59,
  z: 0
};

export const depthP1 = -1.15;
export const diffSzTopWaist = 2.5 / 12;

export const foulLines = [
  {
    x: 3.7083,
    y: 3.7083,
    z: 0
  },
  {
    x: 30,
    y: 30,
    z: 0
  },
  {
    x: -3.7083,
    y: 3.7083,
    z: 0
  },
  {
    x: -30,
    y: 30,
    z: 0
  }
];

export const plateCorners = {
  firstBaseFront: {
    x: 0.70833,
    y: 1.41667,
    z: 0
  },
  firstBaseBack: {
    x: 0.70833,
    y: 0.70833,
    z: 0
  },
  origin: {
    x: 0,
    y: 0,
    z: 0
  },
  thirdBaseBack: {
    x: -0.70833,
    y: 0.70833,
    z: 0
  },
  thirdBaseFront: {
    x: -0.70833,
    y: 1.41667,
    z: 0
  }
};

export const plateCoordinatesXYZ = [
  plateCorners.firstBaseFront,
  plateCorners.firstBaseBack,
  plateCorners.origin,
  plateCorners.thirdBaseBack,
  plateCorners.thirdBaseFront
];

export const strikeZoneBoundaries3D = {
  szTopUpper: 4.333,
  szTopLower: 2.333,
  szBottomUpper: 2,
  szBottomLower: 1
};

export const szOobThreshold = {
  top: 0.05,
  bottom: 0.03
};
