import { action, autorun, computed, extendObservable, reaction } from "mobx";
import _ from "lodash";
import { auditStoreDefaults, hotkeyMap, szOobThreshold } from "./AuditPcStoreConstants";
import Moment from "moment/moment";
import { DateConstants } from "../../components/common/constants/DateConstants";
import { AuthConstants } from "../../components/login/AuthConstants";
import AuditPcStoreFunctions from "./AuditPcStoreFunctions";
import { now } from "mobx-utils";
import { VideoConstants } from "../../components/common/constants/VideoConstants";

class AuditPcStore {
  constructor(routerStore, zeApi, loadingStore, authStore, alertStore) {
    this.zeApi = zeApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.adminRoles = [AuthConstants.USER_ROLES.ZE_SUPER_ADMIN, AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN];

    // bind functions as necessary
    this.functions = new AuditPcStoreFunctions(alertStore, this, loadingStore, zeApi);

    this.defaults = auditStoreDefaults;

    extendObservable(this, {
      auditEnabled: this.defaults["auditEnabled"],
      autoPaste: this.defaults["autoPaste"],
      autoSave: this.defaults["autoSave"],
      autoSaveDate: this.defaults["autoSaveDate"],
      callCorrectness: this.defaults["callCorrectness"],
      callCorrectnessTotals: this.defaults["callCorrectnessTotals"],
      calledFilter: this.defaults["calledFilter"],
      correctX: this.defaults["correctX"],
      correctY: this.defaults["correctY"],
      correctZ: this.defaults["correctZ"],
      roll: this.defaults["roll"],
      rollTimeoutId: null,
      frameInfo: this.defaults["frameInfo"],
      gameDisplayName: this.defaults["gameDisplayName"],
      gameDisplayTime: this.defaults["gameDisplayTime"],
      gamePk: this.defaults["gamePk"],
      isAdmin: this.defaults["isAdmin"],
      keyframeOffset: this.defaults["keyframeOffset"],
      loading: this.defaults["loading"],
      modalAction: this.defaults["modalAction"],
      newPitchKeyframeFlag: this.defaults["newPitchKeyframeFlag"],
      oobPitches: this.defaults["oobPitches"],
      oobPitchFilter: this.defaults["oobPitchFilter"],
      pitches: this.defaults["pitches"],
      pitchListRef: null,
      players: this.defaults["players"],
      preloadVideos: this.defaults["preloadVideos"],
      problemPitchFilter: this.defaults["problemPitchFilter"],
      problemPitchKeyOrder: this.defaults["problemPitchKeyOrder"],
      problemPitchKeys: this.defaults["problemPitchKeys"],
      problemPitchInfo: this.defaults["problemPitchInfo"],
      selectedPitch: this.defaults["selectedPitch"],
      showCancelModal: this.defaults["showCancelModal"],
      showConfirmationModal: this.defaults["showConfirmationModal"],
      showPitchArc: this.defaults["showPitchArc"],
      smoothing: this.defaults["smoothing"],
      smoothingTimeoutId: null,
      sort: {
        col: "pitchNumber",
        asc: true
      },
      stepSize: this.defaults["stepSize"],
      strikeZoneEditable: this.defaults["strikeZoneEditable"],
      targetDepth: this.defaults["targetDepth"],
      videoRef: null,
      videoCurrentTime: this.defaults["videoCurrentTime"],
      videoDuration: this.defaults["videoDuration"],

      // setter and toggle actions
      markPitchDirty: action((pitch, dirtyValue) => {
        let dateStr = Moment(new Date()).format(DateConstants.DATE_FORMAT_WITH_TIME);
        let dirtyPitch = {
          ...pitch,
          dirty: dirtyValue,
          updatedOn: dateStr,
          updatedBy: localStorage.getItem(AuthConstants.KEYS.NAME)
        };
        let idx = this.pitches.findIndex(p => p.playId === dirtyPitch.playId);
        if (idx >= 0) {
          this.pitches[idx] = dirtyPitch;
          this.pitches = [...this.pitches];
        }
        if (this.selectedPitch && this.selectedPitch.playId === dirtyPitch.playId) {
          this.setSelectedPitch(dirtyPitch);
        }
      }),
      onSeekChange: action(value => {
        this.videoRef.currentTime = value / 1000;
      }),
      onRollChange: action(value => {
        this.roll = Math.round(value * 1000) / 1000;

        clearTimeout(this.rollTimeoutId);
        this.rollTimeoutId = setTimeout(() => {
          this.functions.clearAllCoordinates();
          this.functions.selectedPitchChanged();
          this.functions.preload();
        }, 100);
      }),
      onSmoothingChange: action(value => {
        this.smoothing = value;

        clearTimeout(this.smoothingTimeoutId);
        this.smoothingTimeoutId = setTimeout(() => {
          this.functions.clearAllCoordinates();
          this.functions.selectedPitchChanged();
          this.functions.preload();
        }, 100);
      }),
      setAuditEnabled: action(value => {
        this.auditEnabled = value;
      }),
      setBattersBoxesMap: action(value => {
        value.pitch.battersBoxesMap = value.battersBoxesMap;
      }),
      setCalibrationPanDown: action((pitch, value) => {
        pitch.calibrationPanDown = value;
      }),
      setCalibrationPanRight: action((pitch, value) => {
        pitch.calibrationPanRight = value;
      }),
      setDeltaKnee: action((pitch, delta) => {
        pitch.deltaKnee = delta;
      }),
      setDeltaWaist: action((pitch, delta) => {
        pitch.deltaWaist = delta;
      }),
      setFoulLineCoordinatesMap: action(value => {
        value.pitch.foulLineCoordinatesMap = value.foulLineCoordinatesMap;
      }),
      setFrameInfo: action(value => {
        this.frameInfo = value;
      }),
      setKeyframe: action(() => {
        this.selectedPitch.keyframeTs = this.frameInfo?.mediaTime ?? this.videoRef.currentTime;
      }),
      setHomeSecondAxisMap: action(value => {
        value.pitch.homeSecondAxisMap = value.homeSecondAxisMap;
      }),
      setKeyframeOffset: action(event => {
        this.keyframeOffset = Number.parseInt(event.target.value, 10);
      }),
      setLoading: action(value => {
        this.loading = value;
      }),
      setMoundCircleMap: action(value => {
        value.pitch.moundCircleMap = value.moundCircleMap;
      }),
      setNewPitchKeyframeFlag: action(value => {
        this.newPitchKeyframeFlag = value;
      }),
      setOobPitches: action(pitches => {
        this.oobPitches = pitches;
      }),
      toggleOobPitchFilter: action(() => {
        this.oobPitchFilter = !this.oobPitchFilter;
      }),
      setPitchDirty: action((pitch, dirty) => {
        pitch.dirty = dirty;
      }),
      setPitchInList: action(pitch => {
        if (!pitch) {
          return;
        }
        let idx = this.pitches.findIndex(p => p.playId === pitch.playId);
        if (idx >= 0) {
          this.pitches[idx] = pitch;
          this.pitches = [...this.pitches];
        }
        if (this.selectedPitch && this.selectedPitch.playId === pitch.playId) {
          this.selectedPitch = pitch;
        }
      }),
      setPitchListRef: action(value => {
        if (value) {
          this.pitchListRef = value;
        }
      }),
      setPlateCoordinatesMap: action(value => {
        value.pitch.plateCoordinatesMap = value.plateCoordinatesMap;
      }),
      setPlayers: action(value => {
        this.players = value;
      }),
      setPreloadVideos: action(value => {
        this.preloadVideos = value;
      }),
      setProblemPitchInfo: action(data => {
        this.problemPitchInfo = data;
      }),
      setSelectedLine: action(value => {
        this.selectedLine = value;
      }),
      setShowConfirmationModal: action(value => {
        this.showConfirmationModal = value;
      }),
      setSelectedPitch: action(value => {
        this.setNewPitchKeyframeFlag(true);
        this.selectedPitch = value;
        this.setLoading(false);
      }),
      setShowPitchArc: action(value => {
        this.showPitchArc = value;
      }),
      setSort: action(col => {
        if (col === this.sort.col) {
          if (this.sort.asc) {
            // sort descending
            this.sort.asc = false;
          } else {
            // clear sort
            this.sort.asc = true;
            this.sort.col = "";
          }
        } else {
          this.sort.asc = true;
          this.sort.col = col;
        }
      }),
      setStrikeZoneEditable: action(value => {
        this.strikeZoneEditable = value;
      }),
      setTargetDepth: action(event => {
        this.targetDepth = Number.parseFloat(event.target.value, 10);
      }),
      setVideoCurrentTime: action(value => {
        this.videoCurrentTime = value;
      }),
      setVideoCurrentTimeFromListener: action(event => {
        let video = event.target;
        this.setVideoCurrentTime(video.currentTime);
        this.setVideoDuration(video.duration);
      }),
      setVideos: action((pitch, videos) => {
        if (pitch) {
          pitch.videos = videos;
        }
      }),
      setVideoDuration: action(value => {
        this.videoDuration = value;
      }),
      setXyCoordinatesMap: action(value => {
        value.pitch.xyCoordinatesMap = value.xyCoordinatesMap;
      }),
      setXySzCoordinatesMap: action(value => {
        value.pitch.xySzCoordinatesMap = value.xySzCoordinatesMap;
      }),
      setXyTargetDepthCoordinatesMap: action(value => {
        value.pitch.xyTargetDepthCoordinatesMap = value.xyTargetDepthCoordinatesMap;
      }),
      setXyWaistKneeBoundsMap: action(value => {
        value.pitch.xyWaistKneeBoundsMap = value.xyWaistKneeBoundsMap;
      }),
      setXyWaistKneeMap: action(value => {
        value.pitch.xyWaistKneeMap = value.xyWaistKneeMap;
      }),
      setVideoRef: action(value => {
        if (value) {
          this.videoRef = value;
          // Fire snapToKeyframe once we have the metadata for the video element.
          // As a feature, we set the default video frame to be the frame where
          // the strike zone is set. The function relies on the duration of the video,
          // and consequently we have to wait until the duration metadata is available.
          this.videoRef.addEventListener("loadedmetadata", this.functions.snapToKeyframeOnNewPitch);
          this.videoRef.addEventListener("timeupdate", this.setVideoCurrentTimeFromListener);
          this.videoRef.requestVideoFrameCallback(this.functions.trackTime);
        }
      }),
      setVideoZoomLeft: action(event => {
        this.videoZoomLeft = Number.parseInt(event.target.value, 10);
      }),
      setVideoZoomTop: action(event => {
        this.videoZoomTop = Number.parseInt(event.target.value, 10);
      }),
      toggleAutoPaste: action(event => {
        this.autoPaste = !this.autoPaste;
      }),
      toggleAutoSave: action(() => {
        this.autoSave = !this.autoSave;
      }),
      toggleCallCorrectness: action(key => {
        this.callCorrectness[key] = !this.callCorrectness[key];
        if (key === "INCORRECT") {
          this.toggleCallCorrectness("CATCHER_INFLUENCE");
          this.toggleCallCorrectness("HIGH_BUFFER");
          this.toggleCallCorrectness("LOW_BUFFER");
          this.toggleCallCorrectness("LOW_CATCH");
        }
      }),
      toggleCalledFilter: action(() => {
        this.calledFilter = !this.calledFilter;
      }),
      toggleProblemPitchFilter: action(key => {
        this.problemPitchFilter[key] = !this.problemPitchFilter[key];
      }),
      updateAutoSaveDate: action(() => {
        this.autoSaveDate = new Date();
      }),

      // hotkey handlers
      bottomStrikeZoneDownHandler: action(evt => {
        if (!this.functions.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        const deltaKnee = this.selectedPitch.deltaKnee ? this.selectedPitch.deltaKnee : 0;
        this.setDeltaKnee(this.selectedPitch, deltaKnee + this.stepSize);
        this.markPitchDirty(this.selectedPitch, true);
      }),
      bottomStrikeZoneUpHandler: action(evt => {
        if (!this.functions.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        const deltaKnee = this.selectedPitch.deltaKnee ? this.selectedPitch.deltaKnee : 0;
        this.setDeltaKnee(this.selectedPitch, deltaKnee - this.stepSize);
        this.markPitchDirty(this.selectedPitch, true);
      }),
      increaseStepSize: action(() => {
        if (this.stepSize === 8) {
          this.stepSize = 1 / 16;
        } else {
          this.stepSize = this.stepSize * 2;
        }
      }),
      decreaseStepSize: action(() => {
        if (this.stepSize === 1 / 16) {
          this.stepSize = 8;
        } else {
          this.stepSize = this.stepSize / 2;
        }
      }),
      cancelAudit: action(() => {
        this.showCancelModal = false;
        this.loadingStore.setLoading(true, "Canceling", "Canceling the current Audit", 75);
        this.resetSelectedPitch();
        this.getAuditInfo();
      }),
      dirtyHandler: action(() => {
        if (this.selectedPitch) {
          if (this.selectedPitch.dirty) {
            this.markPitchDirty(this.selectedPitch, false);
          } else {
            this.markPitchDirty(this.selectedPitch, true);
            this.setKeyframe();
          }
        }
      }),
      nextPitch: action(() => {
        let index = this.sortedPitchIdx + 1;
        let foundValidPitch = false;
        while (!foundValidPitch && index < this.sortedPitches.length) {
          let pitch = this.sortedPitches[index];
          if (pitch.playId) {
            this.functions.saveCurrentPitch(this.selectedPitch, this.sortedPitches[index]);
            this.scrollPitchTable(index);
            foundValidPitch = true;
          }
          index++;
        }
      }),
      nextPitchFromClick: action(nextPitch => {
        if (nextPitch.callCorrectness !== "UNKNOWN" || (nextPitch.playId && nextPitch.playId !== "")) {
          this.functions.saveCurrentPitch(this.selectedPitch, nextPitch);
        }
      }),
      panCalibrationVertical: action(direction => {
        if (this.selectedPitch) {
          const vertical = this.selectedPitch.calibrationPanDown + direction * this.stepSize;
          this.setCalibrationPanDown(this.selectedPitch, vertical);
          this.markPitchDirty(this.selectedPitch, true);
        }
      }),
      panCalibrationHorizontal: action(direction => {
        if (this.selectedPitch) {
          const horizontal = this.selectedPitch.calibrationPanRight + direction * this.stepSize;
          this.setCalibrationPanRight(this.selectedPitch, horizontal);
          this.markPitchDirty(this.selectedPitch, true);
        }
      }),
      playPauseHandler: action(evt => {
        if (evt) {
          evt.preventDefault();
          evt.stopPropagation();
        }
        if (this.videoRef) {
          this.videoRef.paused ? this.videoRef.play() : this.videoRef.pause();
        }
      }),
      previousPitch: action(() => {
        let index = this.sortedPitchIdx - 1;
        let foundValidPitch = false;
        while (!foundValidPitch && index >= 0) {
          let pitch = this.sortedPitches[index];
          if (pitch.playId) {
            this.functions.saveCurrentPitch(this.selectedPitch, this.sortedPitches[index]);
            this.scrollPitchTable(index);
            foundValidPitch = true;
          }
          index--;
        }
      }),
      stepBackward: action(() => {
        this.videoRef.currentTime = Math.max(this.videoRef.currentTime - VideoConstants.ONE_FRAME, 0);
      }),
      stepForward: action(() => {
        let { currentTime, duration } = this.videoRef;
        if (this.videoRef && this.videoRef.duration) {
          this.videoRef.currentTime = Math.min(currentTime + VideoConstants.ONE_FRAME, duration);
        }
      }),
      topStrikeZoneDownHandler: action(evt => {
        if (!this.functions.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        const deltaWaist = this.selectedPitch.deltaWaist ? this.selectedPitch.deltaWaist : 0;
        this.setDeltaWaist(this.selectedPitch, deltaWaist + this.stepSize);
        this.markPitchDirty(this.selectedPitch, true);
      }),
      topStrikeZoneUpHandler: action(evt => {
        if (!this.functions.isUmpireCallCodeCalled(this.selectedPitch) || !this.strikeZoneEditable) {
          return;
        }
        this.setKeyframe();
        const deltaWaist = this.selectedPitch.deltaWaist ? this.selectedPitch.deltaWaist : 0;
        this.setDeltaWaist(this.selectedPitch, deltaWaist - this.stepSize);
        this.markPitchDirty(this.selectedPitch, true);
      }),

      // other actions
      getAuditInfo: action((pitchIdx, markPitches) => {
        if (!pitchIdx) {
          pitchIdx = 0;
        }
        this.loadingStore.setLoading(true, "Loading", "Loading Audit Information", 75);
        this.pitches = this.defaults["pitches"];
        this.zeApi.getAuditV2Data(this.gamePk, "TEAM", "PITCHCAST").then(responses => {
          if (responses && responses.length >= 3) {
            if (responses[0]) {
              this.smoothing = responses[0].smoothingFrames;
              this.roll = responses[0].rollDegrees;
              this.setAuditEnabled(responses[0].auditEnabled);
              this.setStrikeZoneEditable(responses[0].strikeZoneEditable);
            }
            if (!responses[1] || responses[1].error || (responses[1].data && responses[1].data.error)) {
              console.log(responses[1]);
            } else {
              this.callCorrectnessTotals = responses[1].callCorrectnessTotals;
              this.gameDisplayName = responses[1].displayName;
              this.gameDisplayTime = responses[1].displayTime;
              if (responses[2]) {
                const playersMap = responses[2].reduce(function(map, player) {
                  player.strikeZoneTopMax = player.strikeZoneTop + player.heightInFeet * szOobThreshold.top;
                  player.strikeZoneTopMin = player.strikeZoneTop - player.heightInFeet * szOobThreshold.top;
                  player.strikeZoneBottomMax = player.strikeZoneBottom + player.heightInFeet * szOobThreshold.bottom;
                  player.strikeZoneBottomMin = player.strikeZoneBottom - player.heightInFeet * szOobThreshold.bottom;
                  map[player.id] = player;
                  return map;
                }, {});
                this.setPlayers(playersMap);
              }
              if (responses[3]) {
                responses[1].pitches
                  .filter(p => responses[3][p.playId])
                  .forEach(p => this.setVideos(p, responses[3][p.playId].videos));
              }
              this.pitches = responses[1].pitches;
              if (markPitches && markPitches.length) {
                for (const pitch of markPitches) {
                  let ix = this.pitches.findIndex(p => {
                    return p.playId === pitch.playId;
                  });
                  this.pitches[ix] = pitch;
                }
                this.oobPitchFilter = true;
              }
            }
          }
          this.loadingStore.setWindowTitle(this.windowText);
          document.title = this.windowText;
          this.loadingStore.setLoading(false);
          if (this.sortedPitches.length) {
            this.setSelectedPitch(this.sortedPitches[pitchIdx]);
            this.scrollPitchTable(pitchIdx);
          }
        });
      }),
      resetAuditStore: action(() => {
        this.pitches = this.defaults["pitches"];
        this.gamePk = this.defaults["gamePk"];
        this.gameDisplayName = this.defaults["gameDisplayName"];
        this.gameDisplayTime = this.defaults["gameDisplayTime"];
        this.selectedPitch = this.defaults["selectedPitch"];
        this.sort = {
          col: "pitchNumber",
          asc: true
        };
      }),
      scrollPitchTable: action(index => {
        let tbody = this.pitchListRef.children[1];
        let activeRow = tbody.children[index];
        if (!activeRow) {
          return;
        }

        let halfTableHeight = Math.floor(tbody.offsetHeight / 2);
        let autoScrollThreshold = Math.ceil(tbody.offsetHeight / 3);
        let offset = Math.max(activeRow.offsetTop - halfTableHeight, 0);
        let targetScrollTop = tbody.scrollTopMax ? Math.min(offset, tbody.scrollTopMax) : offset;

        if (Math.abs(activeRow.offsetTop - (tbody.scrollTop + halfTableHeight)) > autoScrollThreshold) {
          tbody.scrollTop = targetScrollTop;
        }
      }),
      resetSelectedPitch: action(() => {
        this.setSelectedPitch(this.defaults["selectedPitch"]);
      }),
      updateFromUrlParams: action(search => {
        const params = new URLSearchParams(search);
        if (params.has("gamePk") && this.gamePk !== params.get("gamePk") && this.routerStore.isAuditTabV2) {
          this.gamePk = params.get("gamePk");
          this.getAuditInfo();
          this.functions.getProblemPitchInfo();
        }
      }),
      backupPitchOffsets: action(autoSaving => {
        if (!autoSaving) {
          this.loadingStore.setLoading(true, "Loading", "Loading Backup Offset Information", 75);
        }
        let transformed = this.pitches
          .filter(p => p.dirty)
          .map(pitch => {
            return {
              gamePk: this.gamePk,
              playId: pitch.playId,
              deltaKnee: pitch.deltaKnee,
              szTop: pitch.szTop,
              szBottom: pitch.szBottom,
              deltaWaist: pitch.deltaWaist,
              timestamp: pitch.keyframeTs,
              updatedBy: pitch.updatedBy,
              updatedOn: pitch.updatedOn,
              calibrationPanDown: pitch.calibrationPanDown,
              calibrationPanRight: pitch.calibrationPanRight
            };
          });
        zeApi.backupPCOffsets(this.gamePk, transformed).then(data => {
          if (!autoSaving) {
            this.loadingStore.setLoading(false);
          }
          this.updateAutoSaveDate();
        });
      }),
      restorePitchOffsets: action(() => {
        this.loadingStore.setLoading(true, "Loading", "Loading Backup Offset Information", 75);

        zeApi.restorePCOffsets(this.gamePk).then(data => {
          data.forEach(backup => {
            let pitch = this.pitchesMap[backup.playId];
            pitch.dirty = true;
            pitch.calibrationPanDown = backup.calibrationPanDown;
            pitch.calibrationPanRight = backup.calibrationPanRight;
            pitch.updatedBy = backup.updatedBy;
            pitch.updatedOn = backup.updatedOn;
            pitch.keyframeTs = backup.timestamp;

            if (backup.deltaWaist) {
              pitch.deltaWaist = backup.deltaWaist;
            }
            if (backup.deltaKnee) {
              pitch.deltaKnee = backup.deltaKnee;
            }

            if (backup.szTop) {
              pitch.szTop = backup.szTop;
            }
            if (backup.szBottom) {
              pitch.szBottom = backup.szBottom;
            }
          });
          this.loadingStore.setLoading(false);
        });
      }),

      // computeds
      callCorrectnessFilterChecked: computed(() => {
        let checked = false;
        Object.keys(this.callCorrectness).forEach(key => {
          checked = checked || this.callCorrectness[key];
        });
        return checked;
      }),
      callCorrectnessFilterTotals: computed(() => {
        if (this.callCorrectnessTotals) {
          return {
            ACCEPTABLE: this.callCorrectnessTotals.ACCEPTABLE,
            CORRECT: this.callCorrectnessTotals.CORRECT,
            INCORRECT:
              (this.callCorrectnessTotals.INCORRECT ? this.callCorrectnessTotals.INCORRECT : 0) +
              (this.callCorrectnessTotals.CATCHER_INFLUENCE ? this.callCorrectnessTotals.CATCHER_INFLUENCE : 0) +
              (this.callCorrectnessTotals.HIGH_BUFFER ? this.callCorrectnessTotals.HIGH_BUFFER : 0) +
              (this.callCorrectnessTotals.LOW_BUFFER ? this.callCorrectnessTotals.LOW_BUFFER : 0) +
              (this.callCorrectnessTotals.LOW_CATCH ? this.callCorrectnessTotals.LOW_CATCH : 0),
            UNKNOWN: this.callCorrectnessTotals.UNKNOWN
          };
        } else {
          return {};
        }
      }),
      callCorrectnessPopulated: computed(() => {
        let populated = false;
        this.pitches.forEach(pitch => {
          if (pitch.callCorrectness !== "UNKNOWN") {
            populated = true;
          }
        });
        return populated;
      }),
      calledPitches: computed(() => {
        return this.pitches.filter(p => p.callCorrectness !== "UNKNOWN");
      }),
      dirtyPitches: computed(() => {
        return _.filter(this.pitches, o => o.dirty);
      }),
      pitchesMap: computed(() => {
        let pitchesMap = {};
        this.pitches.forEach(function(pitch) {
          if (pitch.playId) {
            pitchesMap[pitch.playId] = pitch;
          }
        });
        return pitchesMap;
      }),
      pitchNumbersMap: computed(() => {
        let pitchNumbersMap = {};
        this.pitches.forEach(function(pitch) {
          if (pitch.pitchNumber) {
            pitchNumbersMap[pitch.pitchNumber] = pitch;
          }
        });
        return pitchNumbersMap;
      }),
      problemPitchList: computed(() => {
        let list = [];
        this.problemPitchKeyOrder.forEach(key => {
          if (this.problemPitchInfo.hasOwnProperty(key) && this.problemPitchInfo[key].length > 0) {
            let title = this.problemPitchKeys[key];
            if (title) {
              let pitches = this.problemPitchInfo[key];
              let called = _.filter(pitches, o => {
                return o.called;
              });
              list.push({
                key: key,
                title: title,
                called: called.length,
                total: pitches.length
              });
            }
          }
        });
        return list;
      }),
      selectedPitchIndex: computed(() => {
        return _.findIndex(this.pitches, o => o.playId === this.selectedPitch.playId);
      }),
      sortedPitches: computed(() => {
        if (this.pitches) {
          let initialList = this.calledFilter ? this.calledPitches : this.pitches;
          let pitches = Array.prototype.slice.call(initialList);

          // sort
          if (this.sort.col) {
            pitches.sort(this.functions.comparePitches.bind(this.functions));
          }
          // filter
          let problemPitches = [];
          let problemPitchFilterUsed = false;
          Object.keys(this.problemPitchFilter).forEach(key => {
            if (this.problemPitchFilter[key]) {
              problemPitchFilterUsed = true;
              problemPitches = _.union(problemPitches, this.problemPitchInfo[key]);
            }
          });

          let callCorrectnessFilterUsed = false;
          let callCorrectnessPitches = [];
          Object.keys(this.callCorrectness).forEach(key => {
            if (this.callCorrectness[key]) {
              callCorrectnessFilterUsed = true;
              let matchingPitches = _.filter(pitches, o => {
                return o.callCorrectness === key;
              });
              callCorrectnessPitches.push(...matchingPitches);
            }
          });

          let filteredPitches = problemPitchFilterUsed ? problemPitches : pitches;
          filteredPitches = callCorrectnessFilterUsed
            ? _.intersectionBy(filteredPitches, callCorrectnessPitches, "playId")
            : filteredPitches;

          filteredPitches = this.oobPitchFilter
            ? _.intersectionBy(filteredPitches, this.oobPitches, "playId")
            : filteredPitches;

          let pitchNumbers = filteredPitches.map(p => p.pitchNumber);
          pitches = pitches.filter(p => pitchNumbers.indexOf(p.pitchNumber) >= 0);
          return pitches;
        } else {
          return [];
        }
      }),
      sortedPitchIdx: computed(() => {
        let index = this.sortedPitches.findIndex(pitch => pitch[this.sort.col] === this.selectedPitch[this.sort.col]);
        return index >= 0 ? index : -1;
      }),
      windowText: computed(() => {
        return (
          "AT " +
          this.gameDisplayName.split(" ")[2] +
          " " +
          (this.gameDisplayTime ? Moment(this.gameDisplayTime).format(DateConstants.DATE_FORMAT_WITH_TIME) : "")
        );
      })
    });

    this.hotkeyMap = hotkeyMap;

    this.hotkeyHandlers = {
      autoPasteHandler: event => {
        event.preventDefault();
        this.toggleAutoPaste();
      },
      bottomStrikeZoneDownHandler: event => {
        event.preventDefault();
        this.bottomStrikeZoneDownHandler(event);
      },
      bottomStrikeZoneUpHandler: event => {
        event.preventDefault();
        this.bottomStrikeZoneUpHandler(event);
      },
      dirtyHandler: event => {
        event.preventDefault();
        this.dirtyHandler(event);
      },
      downHandler: event => {
        event.preventDefault();
        this.panCalibrationVertical(1);
      },
      keyframe: event => {
        event.preventDefault();
        this.functions.snapToKeyframe();
      },
      leftHandler: event => {
        event.preventDefault();
        this.panCalibrationHorizontal(-1);
      },
      nextPitch: event => {
        event.preventDefault();
        this.nextPitch(event);
      },
      pitchArc: event => {
        event.preventDefault();
        this.setShowPitchArc(!this.showPitchArc);
      },
      plate: event => {
        event.preventDefault();
        this.functions.snapToPlate();
      },
      playPauseHandler: event => {
        event.preventDefault();
        this.playPauseHandler(event);
      },
      prevPitch: event => {
        event.preventDefault();
        this.previousPitch(event);
      },
      rightHandler: event => {
        event.preventDefault();
        this.panCalibrationHorizontal(1);
      },
      stepBackward: event => {
        event.preventDefault();
        this.stepBackward(event);
      },
      stepForward: event => {
        event.preventDefault();
        this.stepForward(event);
      },
      topStrikeZoneDownHandler: event => {
        event.preventDefault();
        this.topStrikeZoneDownHandler(event);
      },
      topStrikeZoneUpHandler: event => {
        event.preventDefault();
        this.topStrikeZoneUpHandler(event);
      },
      upHandler: event => {
        event.preventDefault();
        this.panCalibrationVertical(-1);
      },
      decreaseStepSize: event => {
        event.preventDefault();
        this.decreaseStepSize(event);
      },
      increaseStepSize: event => {
        event.preventDefault();
        this.increaseStepSize(event);
      }
    };

    reaction(
      () => (this.selectedPitch ? this.selectedPitch.playId : ""),
      () => {
        this.functions.selectedPitchChanged();
        this.functions.preload();
        this.functions.clearAllUnusedCoordinates();
      }
    );

    reaction(
      () => this.sortedPitches,
      () => {
        let selectedPitchExists = _.filter(this.sortedPitches, p => {
          return p.playId === this.selectedPitch.playId;
        });
        if (selectedPitchExists.length === 0 && this.sortedPitches.length > 0) {
          this.setSelectedPitch(this.sortedPitches[0]);
        }
      }
    );

    reaction(
      () => this.pitchListRef,
      () => {
        this.scrollPitchTable(this.sortedPitchIdx);
      }
    );

    reaction(
      () => this.targetDepth,
      () => {
        const pitch = this.selectedPitch;
        let position = pitch.xyzCoordinates.positions.find(pos => Math.round(pos.y) === this.targetDepth);
        if (position) {
          this.functions.getXyTargetDepthCoordinates(pitch);
        } else {
          this.setXyTargetDepthCoordinatesMap({
            pitch: pitch,
            xyTargetDepthCoordinatesMap: null
          });
          this.functions.getCoordinates(pitch);
        }
      }
    );

    reaction(
      () => now(),
      () => {
        if (this.routerStore.isAuditTabV2 && this.autoSave) {
          let d = new Date(now());
          if (d.getSeconds() % 60 === 0) {
            this.backupPitchOffsets(true);
          }
        }
      }
    );

    autorun(() => {
      this.isAdmin = authStore.containsAuthorities(this.adminRoles);
      if (authStore.isLoggedIn && this.routerStore.isAuditTabV2) {
        this.isStrikeZone = this.routerStore.location.pathname.indexOf("strike-zone") >= 0;
        this.updateFromUrlParams(this.routerStore.location.search);
      } else {
        this.resetAuditStore();
      }
    });
  }
}

export default AuditPcStore;
