import { action, autorun, computed, extendObservable, observable, reaction } from "mobx";
import { CoordinateUtility } from "../../utilities/CoordinateUtility";
import Moment from "moment-timezone";
import { DateConstants } from "../../components/common/constants/DateConstants";
import { AlertConstants } from "../../components/common/alert/AlertConstants";
import { callCorrectnessDisplayText, hotkeyMap, queryParams } from "./GameViewerStoreConstants";
import GameViewerFunctions from "../../components/gameviewer/content/filter/pitchList/GameViewerFunctions";
import { now } from "mobx-utils";
import { CallCorrectnessConstants } from "../../components/common/constants/CallCorrectnessConstants";

const EDGE_FILTER = process.env.REACT_APP_EDGE_FILTER === "true";

class GameViewerStore {
  constructor(authStore, routerStore, zeApi, loadingStore, alertStore) {
    this.routerStore = routerStore;
    this.zeApi = zeApi;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.authStore = authStore;

    this.videoCenter = [270, 480];
    this.szVideoCenter = [540, 960];

    this.functions = new GameViewerFunctions(authStore, alertStore, this, loadingStore, zeApi);

    this.defaults = {
      absFeedback: {
        reasonId: null,
        reasons: [],
        description: null
      },
      activePitchElem: null,
      adjustPitchPopoverOpen: false,
      auditConfiguration: {
        gamePk: -1,
        auditEnabled: false,
        auditCameraAngle: "",
        strikeZoneEditable: false
      },
      autoplay: false,
      batterFilter: observable([]),
      bufferConfiguration: null,
      calledFilter: "called",
      cartoonAngle: "centerfield",
      checkPitches: false,
      correctnessFilter: {
        acceptable: false,
        correct: false,
        incorrect: true,
        edge: false
      },
      currentVideo: {},
      cameraSettings: {},
      displaySz: false,
      disputePopoverOpen: false,
      disputeReason: "",
      game: {},
      gamePk: -1,
      gameContexts: [],
      handFilter: "All",
      hideVideoPopoverOpen: false,
      isMilbGame: false,
      keyframeOffset: 0,
      keyframeUpdateTs: 0,
      markPitchPopoverOpen: false,
      markPitchesListPopoverOpen: false,
      hoverPitches: observable([]),
      pitcherFilter: observable([]),
      pitchCoordinates: {},
      pitchList: [],
      pitchListFilter: "",
      selectedBoxScore: "",
      selectedPitch: null,
      selectedUmpireStances: observable.map(),
      showCanvas: true,
      showFeedbackPopover: false,
      showUmpireStances: false,
      szPredict: observable.map(),
      pitchListRef: null,
      pitchTableRef: null,
      umpire: {},
      umpireId: -1,
      updatedPitches: [],
      videoRef: null,
      videoCurrentTime: 0,
      videoDuration: 0,
      videoExpanded: false,
      videoWidth: 960,
      videoZoomTop: 150,
      videoZoomLeft: 0,
      zoneBasis: null
    };

    extendObservable(this, {
      absFeedback: this.defaults["absFeedback"],
      activePitchElem: this.defaults["activePitchElem"],
      adjustPitchPopoverOpen: this.defaults["adjustPitchPopoverOpen"],
      auditConfiguration: this.defaults["auditConfiguration"],
      autoplay: this.defaults["autoplay"],
      batterFilter: this.defaults["batterFilter"],
      bufferConfiguration: this.defaults["bufferConfiguration"],
      calledFilter: this.defaults["calledFilter"],
      cartoonAngle: this.defaults["cartoonAngle"],
      checkPitches: this.defaults["checkPitches"],
      correctnessFilter: this.defaults["correctnessFilter"],
      currentVideo: this.defaults["currentVideo"],
      cameraSettings: this.defaults["cameraSettings"],
      displaySz: this.defaults["displaySz"],
      disputePopoverOpen: this.defaults["disputePopoverOpen"],
      disputeReason: this.defaults["disputeReason"],
      game: this.defaults["game"],
      gamePk: this.defaults["gamePk"],
      gameContexts: this.defaults["gameContexts"],
      handFilter: this.defaults["handFilter"],
      hideVideoPopoverOpen: this.defaults["hideVideoPopoverOpen"],
      isMilbGame: this.defaults["isMilbGame"],
      keyframeOffset: this.defaults["keyframeOffset"],
      keyframeUpdateTs: this.defaults["keyframeUpdateTs"],
      markPitchPopoverOpen: this.defaults["markPitchPopoverOpen"],
      markPitchesListPopoverOpen: this.defaults["markPitchesListPopoverOpen"],
      hoverPitches: this.defaults["hoverPitches"],
      navToPitchNumber: this.defaults["navTo"],
      pitcherFilter: this.defaults["pitcherFilter"],
      pitchCoordinates: this.defaults["pitchCoordinates"],
      pitchList: this.defaults["pitchList"],
      pitchListFilter: this.defaults["pitchListFilter"],
      openAtBats: observable.map(),
      selectedBoxScore: this.defaults["selectedBoxScore"],
      selectedPitch: this.defaults["selectedPitch"],
      selectedUmpireStances: this.defaults["selectedUmpireStances"],
      showCanvas: this.defaults["showCanvas"],
      showFeedbackPopover: this.defaults["showFeedbackPopover"],
      showUmpireStances: this.defaults["showUmpireStances"],
      szPredict: this.defaults["szPredict"],
      pitchListRef: this.defaults["pitchListRef"],
      pitchTableRef: this.defaults["pitchTableRef"],
      umpire: this.defaults["umpire"],
      umpireId: this.defaults["umpireId"],
      updatedPitches: this.defaults["updatedPitches"],
      videoRef: this.defaults["videoRef"],
      videoCurrentTime: this.defaults["videoCurrentTime"],
      videoDuration: this.defaults["videoDuration"],
      videoExpanded: this.defaults["videoExpanded"],
      videoWidth: this.defaults["videoWidth"],
      videoZoomTop: this.defaults["videoZoomTop"],
      videoZoomLeft: this.defaults["videoZoomLeft"],
      zoneBasis: this.defaults["zoneBasis"],
      close: action(() => {
        this.routerStore.history.push("/schedule");
      }),
      setAbsFeedback: action((key, value) => {
        this.absFeedback = {
          ...this.absFeedback,
          [key]: value
        };
      }),
      setAuditConfiguration: action(value => {
        this.auditConfiguration = value;
      }),
      setAutoplay: action(value => {
        this.autoplay = value;
      }),
      setBatterFilter: action(value => {
        this.batterFilter = observable(value);
      }),
      setBufferConfiguration: action(value => {
        this.bufferConfiguration = value;
      }),
      setCalledFilter: action(value => {
        this.calledFilter = value;
      }),
      setCartoonAngle: action(value => {
        this.cartoonAngle = value;
      }),
      setCorrectnessFilter: action(value => {
        this.correctnessFilter = value;
      }),
      setCurrentVideo: action(value => {
        this.setDisplaySz(false);
        const newVideoSelected = this.currentVideo !== value;
        this.currentVideo = value;
        if (newVideoSelected) {
          this.functions.googleAnalyticsEvent();
        }
      }),
      setCameraSettings: action(value => {
        this.cameraSettings = value;
      }),
      setCalibrationStartIndex: action((pitch, indices) => {
        pitch.calibrationStartIndex = Math.min(...indices.map(key => Number.parseInt(key, 10)));
      }),
      setDisplaySz: action(value => {
        this.displaySz = value;
      }),
      setDisputeReason: action(value => {
        this.disputeReason = value;
      }),
      setGame: action(value => {
        this.game = value;
      }),
      setGamePk: action(value => {
        this.gamePk = value;
      }),
      setHandFilter: action(value => {
        this.handFilter = value;
      }),
      setHoverPitches: action(value => {
        this.hoverPitches = observable(value);
      }),
      setIsMilbGame: action(value => {
        this.isMilbGame = value;
      }),
      setKeyframeOffset: action(event => {
        this.keyframeOffset = event.target.value;
      }),
      setKeyframeUpdateTs: action(value => {
        this.keyframeUpdateTs = value;
      }),
      setPitchInList: action(pitch => {
        let index = this.pitchList.findIndex(p => p.playId === pitch.playId);
        if (index >= 0 && index < this.pitchList.length) {
          this.functions.completePitchFields(pitch);
          pitch.pitchNumber = this.pitchList[index].pitchNumber;
          this.pitchList[index] = pitch;
        }
      }),
      setPitchList: action(value => {
        this.pitchList = value;
      }),
      setPitchListFilter: action(value => {
        this.pitchListFilter = value;
      }),
      setPitcherFilter: action(value => {
        this.pitcherFilter = observable(value);
      }),
      setOpenAtBat: action(value => {
        if (this.openAtBats.has(value)) {
          this.openAtBats.set(value, !this.openAtBats.get(value));
        } else {
          this.openAtBats.set(value, true);
        }
      }),
      setOpenAtBats: action(value => {
        this.openAtBats = value;
      }),
      setSelectedBoxScore: action(value => {
        if (this.selectedBoxScore === value) {
          this.selectedBoxScore = this.defaults["selectedBoxScore"];
        } else {
          this.batterFilter = this.defaults["batterFilter"];
          this.handFilter = this.defaults["handFilter"];
          this.pitcherFilter = this.defaults["pitcherFilter"];
          this.pitchListFilter = this.defaults["pitchListFilter"];
          this.selectedBoxScore = value;
          let hasIncorrect = this.pitchList
            .filter(p => this.functions.selectedBoxScoreFilter(p))
            .filter(p => this.functions.pitchIsIncorrectOrAdjusted(p));
          if (hasIncorrect.length) {
            this.setCalledFilter("called");
            this.setCorrectnessFilter(this.defaults["correctnessFilter"]);
          } else {
            this.setCalledFilter("all");
            this.setCorrectnessFilter({
              acceptable: false,
              correct: false,
              incorrect: false
            });
          }
        }
      }),
      setSelectedPitch: action((value, scroll) => {
        this.selectedPitch = value;
        if (scroll) {
          this.scrollToPitch(value);
          this.disputePopoverOpen = false;
        }
      }),
      setPitchCoordinates: action(value => {
        this.pitchCoordinates = value;
      }),
      setPitchTableRef: action(value => {
        this.pitchTableRef = value;
      }),
      setPitchListRef: action(value => {
        this.pitchListRef = value;
      }),
      setShowCanvas: action(value => {
        this.showCanvas = value;
      }),
      setSzPredict: action((playId, value) => {
        this.szPredict.set(playId, value);
      }),
      setUmpire: action(value => {
        this.umpire = value;
      }),
      setUmpireId: action(value => {
        this.umpireId = value;
      }),
      setUpdatedPitches: action(value => {
        this.updatedPitches = value;
      }),
      setVideoCurrentTime: action(value => {
        this.videoCurrentTime = value;
      }),
      setVideoDuration: action(value => {
        this.videoDuration = value;
      }),
      setVideoExpanded: action(value => {
        this.videoExpanded = value;
      }),
      setVideoCurrentTimeFromListener: action(event => {
        let video = event.target;
        this.setVideoCurrentTime(video.currentTime);
        this.setVideoDuration(video.duration);
      }),
      setXyCoordinatesMap: action(value => {
        value.pitch.xyCoordinatesMap = value.xyCoordinatesMap;
        if (value.xyCoordinatesMap && Object.keys(value.xyCoordinatesMap)) {
          this.setCalibrationStartIndex(value.pitch, Object.keys(value.xyCoordinatesMap));
        }
      }),
      setXySzCoordinatesMap: action(value => {
        value.pitch.xySzCoordinatesMap = value.xySzCoordinatesMap;
      }),
      setXyzCoordinates: action((pitch, xyzs) => {
        pitch.xyzCoordinates = xyzs;
      }),
      snapToVideoStart: action(() => {
        this.videoRef.currentTime = 0;
      }),
      setVideoRef: action(value => {
        if (value) {
          this.videoRef = value;
          this.videoRef.addEventListener("timeupdate", this.setVideoCurrentTimeFromListener);
          this.videoRef.addEventListener("loadedmetadata", this.snapToVideoStart);
          this.videoRef.addEventListener("loadedmetadata", this.setVideoWidth);
          this.videoRef.addEventListener("loadedmetadata", this.functions.checkAutoplay);
        }
      }),
      setVideoWidth: action(() => {
        this.videoWidth = this.videoRef ? this.videoRef.videoWidth : 960;
      }),
      onSeekChange: action(value => {
        this.videoRef.currentTime = value / 1000;
      }),
      toggleAdjustPitchPopover: action(() => {
        this.adjustPitchPopoverOpen = !this.adjustPitchPopoverOpen;
      }),
      toggleCheckPitches: action(() => {
        this.checkPitches = !this.checkPitches;
      }),
      toggleDisputePopover: action(() => {
        this.disputePopoverOpen = !this.disputePopoverOpen;
        const hasDispute = this.selectedPitch && this.selectedPitch.dispute;
        if (
          (hasDispute && this.selectedPitch.dispute.disputeReason && !this.selectedPitch.dispute.review) ||
          (hasDispute && !this.selectedPitch.dispute.resolution)
        ) {
          this.setDisputeReason(this.selectedPitch.dispute.disputeReason);
        }
      }),
      toggleFeedbackPopover: action(() => {
        this.showFeedbackPopover = !this.showFeedbackPopover;
      }),
      toggleHideVideoPopover: action(() => {
        this.hideVideoPopoverOpen = !this.hideVideoPopoverOpen;
      }),
      toggleMarkPitchPopoverOpen: action(() => {
        this.markPitchPopoverOpen = !this.markPitchPopoverOpen;
      }),
      toggleMarkPitchesListPopoverOpen: action(() => {
        this.markPitchesListPopoverOpen = !this.markPitchesListPopoverOpen;
      }),
      toggleShowCanvas: action(() => {
        this.showCanvas = !this.showCanvas;
      }),
      toggleVideoExpanded: action(() => {
        this.videoExpanded = !this.videoExpanded;
      }),
      updateFromUrlParams: action(search => {
        const params = new URLSearchParams(search);
        if (params.has("gamePk")) {
          this.setGamePk(params.get("gamePk"));
          this.setUmpireId(params.get("umpireId"));
          this.functions.getGameViewInformation(this.gamePk, this.umpireId);
        }
      }),
      updatePitchCallCorrectness: action(value => {
        let playId = this.selectedPitch.playId;
        this.functions.updateCallCorrectness(this.gamePk, playId, value);
      }),
      updatePitchFromCallCorrectness: action(value => {
        let pitchIndex = this.pitchList.findIndex(p => p.playId === value.playId);
        this.pitchList[pitchIndex].callCorrectness = value.callCorrectness;
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Pitch " + this.selectedPitch.pitchNumber + " has been updated to " + value.callCorrectness
        });
      }),
      analyzePitch: action(() => {
        const pitch = this.selectedPitch;
        this.zeApi
          .analyzePitches(this.gamePk, [
            {
              playId: pitch.playId
            }
          ])
          .then(data => {
            this.zeApi.getGameViewerPitch(this.gamePk, this.umpireId, pitch.playId).then(gvp => {
              let pitchIndex = this.pitchList.findIndex(p => p.playId === gvp.playId);
              pitch.szTop = gvp.szTop;
              pitch.szBottom = gvp.szBottom;
              pitch.szWidth = gvp.szWidth;
              pitch.szDepth = gvp.szDepth;
              pitch.umpireCallCode = gvp.umpireCallCode;
              pitch.videos = gvp.videos;
              pitch.keyframe = gvp.keyframe;
              if (gvp.pathToSZKeyframe) {
                pitch.pathToSZKeyframe += "?t=" + Date.now();
              }
              this.pitchList[pitchIndex] = pitch;
              this.functions.completePitchFields(this.pitchList[pitchIndex]);
              this.setXyCoordinatesMap({
                pitch: pitch,
                xyCoordinatesMap: null
              });
              this.functions.getXyCoordinates(pitch);
              pitch.callCorrectness = gvp.callCorrectness;
              pitch.errorX = gvp.errorX;
              pitch.errorXDirection = gvp.errorXDirection;
              pitch.errorZ = gvp.errorZ;
              pitch.errorZ = gvp.errorZ;
              this.alertStore.addAlert({
                type: AlertConstants.TYPES.SUCCESS,
                text: "Pitch " + pitch.pitchNumber + " has been analyzed"
              });
            });
          });
      }),
      saveHiddenVideos: action(() => {
        this.zeApi.saveCurrentPitchHiddenVideos(this.gamePk, this.selectedPitch.videos).then(() => {
          this.toggleHideVideoPopover();
        });
      }),
      markPitch: action(() => {
        if (this.selectedPitch.deleted) {
          this.zeApi.unmarkPitch(this.gamePk, this.selectedPitch.playId, this.umpireId).then(() => {
            this.toggleMarkPitchPopoverOpen();
            this.functions.getGameViewInformation(this.gamePk, this.umpireId);
          });
        } else {
          this.zeApi.markPitch(this.gamePk, this.selectedPitch.playId, this.umpireId).then(() => {
            this.toggleMarkPitchPopoverOpen();
            this.functions.getGameViewInformation(this.gamePk, this.umpireId);
          });
        }
      }),
      updateUmpireStances: action(() => {
        this.showUmpireStances = false;
        this.loadingStore.setLoading(true, "Game Viewer", "Updating Umpire Stances", 75);
        let umpireStances = [];
        Object.entries(this.selectedUmpireStances).forEach(([key, value]) => {
          if (value) {
            umpireStances.push(key.toUpperCase());
          }
        });
        this.game.umpireStances = umpireStances;
        let game = this.game;
        game.gamePk = this.gamePk;
        this.zeApi.updateUmpireStances(game, this.umpireId).then(data => {
          this.loadingStore.setLoading(false);
          game.umpireStances = data.entity.umpireStances;
        });
      }),
      showUmpireStancesAction: action(game => {
        this.showUmpireStances = true;
        let umpireStances = {
          Square: this.doesStanceExist("Square", game),
          Knee: this.doesStanceExist("Knee", game),
          Scissors: this.doesStanceExist("Scissors", game),
          Slot: this.doesStanceExist("Slot", game)
        };
        this.selectedUmpireStances = observable(umpireStances);
      }),
      updatePitches: action(() => {
        this.updatedPitches.forEach(pitch => {
          let existing = this.pitchList.find(p => p.playId === pitch.playId);
          if (existing) {
            existing.szTop = pitch.szTop;
            existing.szBottom = pitch.szBottom;
          }
        });
        this.setUpdatedPitches([]);
      }),
      closeUmpireStances: action(() => {
        this.showUmpireStances = this.defaults["showUmpireStances"];
        this.selectedUmpireStances = this.defaults["selectedUmpireStances"];
      }),
      setGameContexts: action(values => {
        this.gameContexts = values;
      }),
      pitchInfo: computed(() => {
        let pitch = this.selectedPitch;
        if (!pitch && this.hoverPitches.length === 1) {
          pitch = this.hoverPitches[0];
        }
        if (pitch) {
          let distance = [];
          let location = [];
          if (pitch.errorX) {
            distance.push(pitch.errorX);
            location.push(pitch.errorXDirection);
          }
          if (pitch.errorZ) {
            distance.push(pitch.errorZ);
            location.push(pitch.errorZDirection);
          }
          if (pitch.edgeDistance) {
            distance.push(pitch.edgeDistance);
          }
          if (pitch.edgeDirection) {
            location.push(pitch.edgeDirection);
          }
          let adjusted = this.functions.isAdjusted(pitch);
          return {
            pitcher: pitch.pitcherName,
            location: location,
            distance: distance,
            adjustment: adjusted ? callCorrectnessDisplayText[pitch.callCorrectness] : null,
            speed: pitch.initialSpeed ? pitch.initialSpeed.toFixed(2) : 0,
            type: pitch.pitchName,
            batterSide: pitch.batterSide,
            challenged: pitch.reviewType ? (pitch.isOverturned ? "Call Overturned" : "Call Confirmed") : null,
            isStrike:
              ((pitch.callCorrectness === CallCorrectnessConstants.CORRECT && pitch.umpireCall === "CALLED_STRIKE") ||
                ((pitch.callCorrectness === CallCorrectnessConstants.INCORRECT ||
                  pitch.callCorrectness === CallCorrectnessConstants.ACCEPTABLE) &&
                  pitch.umpireCall !== "CALLED_STRIKE")) ^ pitch.isOverturned
          };
        } else if (this.hoverPitches.length > 1) {
          let pitcher = this.hoverPitches[0].pitcherName;
          let pitchName = this.hoverPitches[0].pitchName;
          for (let idx = 1; idx < this.hoverPitches.length; idx++) {
            let pitch = this.hoverPitches[idx];
            if (pitch.pitcherName !== pitcher) {
              pitcher = "Multiple";
            }
            if (pitch.pitchName !== pitchName) {
              pitchName = "Multiple";
            }
          }
          return {
            pitcher: pitcher,
            type: pitchName
          };
        } else return {};
      }),
      filtersInUse: computed(() => {
        return {
          batter: this.batterFilter !== this.defaults["batterFilter"],
          called: this.calledFilter !== this.defaults["calledFilter"],
          correctness: this.correctnessFilter !== this.defaults["correctnessFilter"],
          hand: this.handFilter !== this.defaults["handFilter"],
          halfInning: this.selectedBoxScore !== this.defaults["selectedBoxScore"],
          pitcher: this.pitcherFilter !== this.defaults["pitcherFilter"],
          text: this.pitchListFilter !== this.defaults["pitchListFilter"]
        };
      }),
      gameInfo: computed(() => {
        let rightHandedBatters = this.pitchListWithCalledCorrectness.filter(
          p => p.batterSide === "R" || p.batterSide === "Right"
        );
        let leftHandedBatters = this.pitchListWithCalledCorrectness.filter(
          p => p.batterSide === "L" || p.batterSide === "Left"
        );
        let rightHanded = this.functions.calculateScoresheet(rightHandedBatters);
        let leftHanded = this.functions.calculateScoresheet(leftHandedBatters);
        let outsideRhAverage = this.functions.calculateDistanceAverage(this.pitchListWithErrors, "OUTSIDE", "R");
        let insideRhAverage = this.functions.calculateDistanceAverage(this.pitchListWithErrors, "INSIDE", "R");
        let outsideLhAverage = this.functions.calculateDistanceAverage(this.pitchListWithErrors, "OUTSIDE", "L");
        let insideLhAverage = this.functions.calculateDistanceAverage(this.pitchListWithErrors, "INSIDE", "L");
        let highAverage = this.functions.calculateDistanceAverage(this.pitchListWithErrors, "HIGH");
        let lowAverage = this.functions.calculateDistanceAverage(this.pitchListWithErrors, "LOW");
        let errorBreakdown = {
          outsideRh: outsideRhAverage,
          insideRh: insideRhAverage,
          outsideLh: outsideLhAverage,
          insideLh: insideLhAverage,
          high: highAverage,
          low: lowAverage
        };
        return {
          rightHanded: rightHanded,
          leftHanded: leftHanded,
          errorBreakdown: errorBreakdown
        };
      }),
      getBoxScore: computed(() => {
        return this.game && this.game.boxScore
          ? this.functions.mapIncorrects(this.game.boxScore, this.getInningsNotFiltered)
          : [];
      }),
      pitchListFiltered: computed(() => {
        return this.pitchList
          .filter(p => this.functions.pitchCalledFilterResults(p))
          .filter(p => this.functions.pitchCallCorrectnessFilterResults(p))
          .filter(p => this.functions.pitcherFilterResults(p, this.pitcherFilter.slice()))
          .filter(p => this.functions.batterFilterResults(p, this.batterFilter.slice()))
          .filter(p => this.functions.selectedBoxScoreFilter(p))
          .filter(p => this.functions.pitchTextFilterResults(p))
          .filter(p => this.functions.handPitchFilter(p))
          .filter(p => this.functions.gameContextFilter(p));
      }),
      edgePitchList: computed(() => {
        return this.pitchList
          .filter(p => !p.deleted)
          .filter(p => p.edgeDistance)
          .filter(p => p.edgeDistance < 3);
      }),
      pitchListWithCalledCorrectness: computed(() => {
        return this.pitchList.filter(
          p =>
            p.callCorrectness &&
            p.callCorrectness !== "UNKNOWN" &&
            p.callCorrectness &&
            p.callCorrectness !== "NOT_APPLICABLE"
        );
      }),
      pitchListWithErrors: computed(() => {
        return this.pitchList.filter(p => p.callCorrectness === "INCORRECT" && (p.errorX || p.errorZ));
      }),
      getInnings: computed(() => {
        return this.functions.calculateInnings(this.pitchListFiltered);
      }),
      getInningsNotFiltered: computed(() => {
        return this.functions.calculateInnings(this.pitchList);
      }),
      gameDetails: computed(() => {
        return this.functions.calculateScoresheet(this.pitchListWithCalledCorrectness);
      }),
      gameScores: computed(() => {
        let raw = (this.gameDetails.correct + this.gameDetails.acceptable) / this.gameDetails.total;
        let adjusted =
          (this.gameDetails.correct + this.gameDetails.acceptable + this.gameDetails.adjusted) / this.gameDetails.total;
        let correctPitches = this.edgePitchList.filter(
          pitch => ["CORRECT", "ACCEPTABLE", "ADJUSTED"].indexOf(pitch.callCorrectness) >= 0
        );
        return {
          raw: (raw * 100).toFixed(2),
          adjusted: (adjusted * 100).toFixed(2),
          edge: correctPitches.length + " / " + this.edgePitchList.length
        };
      }),
      batterOptions: computed(() => {
        let batters = {};
        this.pitchList.forEach(p => {
          if (!batters[p.batterId]) {
            batters[p.batterId] = {
              value: p.batterId,
              label: p.batterName,
              away: p.topOfInning
            };
          }
        });
        return this.functions.playerFilterOptions(this.game, batters);
      }),
      currentPitchCoordinates: computed(() => {
        if (this.selectedPitch && this.pitchCoordinates) {
          let playId = this.selectedPitch.playId;
          return this.pitchCoordinates[playId] ? this.pitchCoordinates[playId] : {};
        }
        return {};
      }),
      currentCenterField: computed(() => {
        if (this.currentPitchCoordinates && this.currentPitchCoordinates.centerfield) {
          let cf = Object.assign({}, this.currentPitchCoordinates.centerfield);
          if (this.auditConfiguration.auditCameraAngle === "CF") {
            return this.functions.adjustCenterfieldCoordinates(cf);
          } else {
            return cf;
          }
        }
        return {};
      }),
      currentSideView: computed(() => {
        if (this.currentPitchCoordinates && this.currentPitchCoordinates.sideview) {
          let sideview = Object.assign({}, this.currentPitchCoordinates.sideview);
          sideview.ballTrack = CoordinateUtility.adjustCoordinatesArrayForZoom(
            sideview.ballTrack,
            sideview.zoomFactor,
            this.szVideoCenter,
            sideview.videoPan[1],
            sideview.videoPan[0]
          );
          sideview.strikeZoneVertices = CoordinateUtility.adjustCoordinatesArrayForZoom(
            sideview.strikeZoneVertices,
            sideview.zoomFactor,
            this.szVideoCenter,
            sideview.videoPan[1],
            sideview.videoPan[0]
          );
          return sideview;
        }
        return {};
      }),
      currentSzBottomRight: computed(() => {
        if (this.currentPitchCoordinates && this.currentPitchCoordinates.szBottomRight) {
          if (this.auditConfiguration.auditCameraAngle === "CF") {
            return CoordinateUtility.adjustCoordinatesForZoom(
              this.currentPitchCoordinates.szBottomRight,
              this.videoZoomFactor,
              this.videoCenter,
              this.currentVideoZoomLeft,
              this.currentVideoZoomTop
            );
          } else {
            return this.currentPitchCoordinates.szBottomRight;
          }
        }
        return {};
      }),
      currentSzTopLeft: computed(() => {
        if (this.currentPitchCoordinates && this.currentPitchCoordinates.szTopLeft) {
          if (this.auditConfiguration.auditCameraAngle === "CF") {
            return CoordinateUtility.adjustCoordinatesForZoom(
              this.currentPitchCoordinates.szTopLeft,
              this.videoZoomFactor,
              this.videoCenter,
              this.currentVideoZoomLeft,
              this.currentVideoZoomTop
            );
          } else {
            return this.currentPitchCoordinates.szTopLeft;
          }
        }
        return {};
      }),
      gameContextOptions: computed(() => {
        return [
          { label: "Close And Late", value: "closeAndLate" },
          { label: "Decides AB", value: "decidesAtBat" }
        ];
      }),
      pitcherOptions: computed(() => {
        let pitchers = {};
        this.pitchList.forEach(p => {
          if (!pitchers[p.pitcherId]) {
            pitchers[p.pitcherId] = {
              value: p.pitcherId,
              label: p.pitcherName,
              away: !p.topOfInning
            };
          }
        });
        return this.functions.playerFilterOptions(this.game, pitchers);
      }),
      selectedPitchIndex: computed(() => {
        if (!this.selectedPitch || !this.pitchListFiltered) {
          return -1;
        }
        let index = this.pitchListFiltered.findIndex(pitch => pitch.pitchNumber === this.selectedPitch.pitchNumber);
        return index >= 0 ? index : -1;
      }),
      szKneeRow: computed(() => {
        let { startY, endY } = this.functions.calculateSZ();
        let startKneeRow = [this.currentPitchCoordinates.kneeRow, startY];
        let zoomedStartKneeRow = CoordinateUtility.adjustCoordinatesForZoom(
          startKneeRow,
          this.videoZoomFactor,
          this.videoCenter,
          this.currentVideoZoomLeft,
          this.currentVideoZoomTop
        );
        let endKneeRow = [this.currentPitchCoordinates.kneeRow, endY];
        let zoomedEndKneeRow = CoordinateUtility.adjustCoordinatesForZoom(
          endKneeRow,
          this.videoZoomFactor,
          this.videoCenter,
          this.currentVideoZoomLeft,
          this.currentVideoZoomTop
        );
        return [zoomedStartKneeRow, zoomedEndKneeRow];
      }),
      szPitchcastKneeWaist: computed(() => {
        if (!this.selectedPitch || !this.selectedPitch.keyframe) {
          return {};
        }
        return {
          kneeLine: this.selectedPitch.keyframe.kneeLine,
          waistLine: this.selectedPitch.keyframe.waistLine
        };
      }),
      szWaistRow: computed(() => {
        let { startY, endY } = this.functions.calculateSZ();
        let startWaistRow = [this.currentPitchCoordinates.waistRow, startY];
        let zoomedStartWaistRow = CoordinateUtility.adjustCoordinatesForZoom(
          startWaistRow,
          this.videoZoomFactor,
          this.videoCenter,
          this.currentVideoZoomLeft,
          this.currentVideoZoomTop
        );
        let endWaistRow = [this.currentPitchCoordinates.waistRow, endY];
        let zoomedEndWaistRow = CoordinateUtility.adjustCoordinatesForZoom(
          endWaistRow,
          this.videoZoomFactor,
          this.videoCenter,
          this.currentVideoZoomLeft,
          this.currentVideoZoomTop
        );
        return [zoomedStartWaistRow, zoomedEndWaistRow];
      }),
      strikeZoneWidth: computed(() => {
        if (
          this.currentPitchCoordinates &&
          this.currentPitchCoordinates.szBottomRight &&
          this.currentPitchCoordinates.szTopLeft
        ) {
          return this.currentPitchCoordinates.szBottomRight[1] - this.currentPitchCoordinates.szTopLeft[1];
        }
        return 0;
      }),
      szLineLength: computed(() => {
        return 3 * this.strikeZoneWidth;
      }),
      currentVideoZoomTop: computed(() => {
        if (this.displaySz || this.currentVideo.typeName === "Centerfield") {
          if (this.currentPitchCoordinates && this.currentPitchCoordinates.cameraPan) {
            return this.currentPitchCoordinates.cameraPan.videoZoomTop;
          }
          return this.cameraSettings.videoZoomTop ? this.cameraSettings.videoZoomTop : this.videoZoomTop;
        } else {
          return this.currentPitchCoordinates.sideview
            ? this.currentPitchCoordinates.sideview.videoPan[0]
            : this.videoZoomTop;
        }
      }),
      currentVideoZoomLeft: computed(() => {
        if (this.displaySz || this.currentVideo.typeName === "Centerfield") {
          if (this.currentPitchCoordinates && this.currentPitchCoordinates.cameraPan) {
            return this.currentPitchCoordinates.cameraPan.videoZoomLeft;
          }
          return this.cameraSettings.videoZoomLeft ? this.cameraSettings.videoZoomLeft : this.videoZoomLeft;
        } else {
          return this.currentPitchCoordinates.sideview
            ? this.currentPitchCoordinates.sideview.videoPan[1]
            : this.videoZoomLeft;
        }
      }),
      videoZoomFactor: computed(() => {
        if (this.displaySz || this.currentVideo.typeName === "Centerfield") {
          return 2;
        } else {
          return this.currentPitchCoordinates.sideview ? this.currentPitchCoordinates.sideview.zoomFactor : 2;
        }
      }),
      isZoomed: computed(() => {
        return this.currentVideo.typeName === "Centerfield" || this.currentVideo.typeName === "Side";
      }),
      windowText: computed(() => {
        return (
          "GV " +
          this.game.displayName.split(" ")[2] +
          " " +
          (this.game.displayTime
            ? Moment(this.game.displayTime)
                .tz(this.game.venueTimeZone)
                .format(DateConstants.DATE_FORMAT_WITH_TIME)
            : "")
        );
      }),
      markedPitches: computed(() => {
        return this.pitchList.filter(p => p.deleted);
      }),
      hiddenVideos: computed(() => {
        return this.pitchList.filter(p => (p.videos != null ? p.videos.some(v => !v.show) : null));
      }),
      hasEdgeFilter: computed(() => {
        return EDGE_FILTER && this.edgePitchList.length;
      }),
      scrollToPitch: action(pitch => {
        let childrenArray = Array.from(this.pitchTableRef.children);
        let activeRow = childrenArray.find(cs => cs.id === pitch.pitchNumber.toString());
        if (!activeRow) {
          return;
        }

        let tbody = this.pitchListRef;

        let halfTableHeight = Math.floor(tbody.offsetHeight / 2);
        let autoScrollThreshold = Math.ceil(tbody.offsetHeight / 3);
        let offset = Math.max(activeRow.offsetTop - halfTableHeight, 0);
        let targetScrollTop = tbody.scrollTopMax ? Math.min(offset, tbody.scrollTopMax) : offset;
        if (Math.abs(activeRow.offsetTop - (tbody.scrollTop + halfTableHeight)) > autoScrollThreshold) {
          this.pitchListRef.scrollTop = targetScrollTop;
        }
      }),
      resetStore: action(() => {
        this.setBatterFilter(this.defaults["batterFilter"]);
        this.setCalledFilter(this.defaults["calledFilter"]);
        this.setCorrectnessFilter(this.defaults["correctnessFilter"]);
        this.setCurrentVideo(this.defaults["currentVideo"]);
        this.setGame(this.defaults["game"]);
        this.setGamePk(this.defaults["gamePk"]);
        this.setHandFilter(this.defaults["handFilter"]);
        this.setPitcherFilter(this.defaults["pitcherFilter"]);
        this.setPitchList(this.defaults["pitchList"]);
        this.setPitchListFilter(this.defaults["pitchListFilter"]);
        this.setSelectedPitch(this.defaults["selectedPitch"], false);
        this.setUmpire(this.defaults["umpire"]);
        this.setUmpireId(this.defaults["umpireId"]);
        this.videoExpanded = this.defaults["videoExpanded"];
      })
    });

    autorun(() => {
      this.resetStore();
      if (authStore.isLoggedIn && this.routerStore.isGameViewerTab) {
        this.updateFromUrlParams(this.routerStore.location.search);
      }
    });

    reaction(
      () => this.currentVideo,
      () => {
        this.setShowCanvas(true);
      }
    );

    reaction(
      () => this.calledFilter,
      () => {
        if (this.calledFilter !== "challenged" && this.calledFilter !== "called") {
          this.setCorrectnessFilter({
            acceptable: false,
            correct: false,
            incorrect: false,
            edge: false
          });
        }
      }
    );

    reaction(
      () => this.correctnessFilter,
      () => {
        if (this.game.absMode) {
          if (
            this.calledFilter === "all" &&
            (this.correctnessFilter.incorrect || this.correctnessFilter.acceptable || this.correctnessFilter.correct)
          ) {
            this.setCalledFilter("called");
          }
        } else {
          if (this.correctnessFilter.incorrect || this.correctnessFilter.acceptable || this.correctnessFilter.correct) {
            this.setCalledFilter("called");
          }
        }
      }
    );

    reaction(
      () => this.pitchList,
      () => {
        this.pitchList.forEach(p => this.functions.completePitchFields(p));

        // if the url params indicate a pitch number, go to that pitch
        const params = new URLSearchParams(this.routerStore.location.search);
        if (
          params.has(queryParams.pitchNumber) &&
          params.get(queryParams.pitchNumber) &&
          params.has(queryParams.playId) &&
          params.get(queryParams.playId)
        ) {
          return;
        }

        if (params.has(queryParams.pitchNumber) && params.get(queryParams.pitchNumber)) {
          let pitchNumber = Number.parseInt(params.get("pitchNumber"), 10);
          let index = this.pitchList.findIndex(p => p.pitchNumber === pitchNumber);
          if (index >= 0 && index < this.pitchList.length) {
            this.setSelectedPitch(this.pitchList[index], true);
            if (this.pitchListFiltered.findIndex(p => p.pitchNumber === pitchNumber) === -1) {
              this.setCalledFilter("all");
              this.setCorrectnessFilter({
                acceptable: false,
                correct: false,
                incorrect: false
              });
            }
          }
        }

        if (params.has(queryParams.playId) && params.get(queryParams.playId)) {
          let playId = params.get("playId");
          let index = this.pitchList.findIndex(p => p.playId === playId);
          if (index >= 0 && index < this.pitchList.length) {
            this.setSelectedPitch(this.pitchList[index], true);
            if (this.pitchListFiltered.findIndex(p => p.playId === playId) === -1) {
              this.setCalledFilter("all");
              this.setCorrectnessFilter({
                acceptable: false,
                correct: false,
                incorrect: false
              });
            }
          }
        }
      }
    );

    reaction(
      () => this.pitchListFiltered,
      () => {
        if (
          this.selectedPitch == null ||
          this.pitchListFiltered.findIndex(pitch => pitch.pitchNumber === this.selectedPitch.pitchNumber) === -1
        ) {
          this.setSelectedPitch(null, false);
        } else {
          let findIndex = this.pitchListFiltered.findIndex(
            pitch => pitch.pitchNumber === this.selectedPitch.pitchNumber
          );
          this.selectedPitch = this.pitchListFiltered[findIndex];
        }
      }
    );

    reaction(
      () => this.selectedPitch,
      () => {
        let _this = this;
        let pitch = _this.selectedPitch;
        _this.functions.preload();
        _this.setAutoplay(false);
        if (pitch) {
          _this.functions.getAllCoordinates(pitch);
        }
        if (pitch && pitch.videos && pitch.videos.length > 0) {
          _this.setShowCanvas(true);
          const cfPitchcast = _this.functions.getVideoCfOrPitchcast();
          const broadcastPitch = _this.functions.getVideoBroadcast();
          if (this.auditConfiguration.auditCameraAngle === "CF" || this.auditConfiguration.auditCameraAngle === "") {
            if (cfPitchcast.rank !== -1 && cfPitchcast.show) {
              _this.setCurrentVideo(cfPitchcast);
            }
          } else if (this.auditConfiguration.auditCameraAngle === "BROADCAST") {
            _this.setCurrentVideo(broadcastPitch);
          } else {
            let filterElement = pitch.videos.filter(v => v.show)[0];
            if (filterElement) {
              _this.setCurrentVideo(filterElement);
            } else {
              _this.setCurrentVideo({});
            }
          }
        } else {
          _this.setCurrentVideo({});
        }
        if (!pitch) {
          _this.setCartoonAngle(this.defaults["cartoonAngle"]);
        }
        _this.setDisputeReason(this.defaults["disputeReason"]);
        if (authStore.isAdmin) {
          _this.functions.getSzPredict(_this.selectedPitch);
        }
        if (_this.showFeedbackPopover) {
          _this.toggleFeedbackPopover();
        }
        _this.setAbsFeedback("reasonId", pitch?.absFeedback?.reasonId ?? this.defaults["absFeedback"].reasonId);
        _this.setAbsFeedback("description", this.defaults["absFeedback"].description);
      }
    );

    reaction(
      () => now(),
      () => {
        if (this.routerStore.isGameViewerTab && this.checkPitches) {
          let d = new Date(now());
          if (d.getSeconds() % 60 === 0) {
            this.checkUmpirePitches(this.gamePk, this.umpireId, this.pitchList);
          }
        }
      }
    );

    this.hotkeyMap = hotkeyMap;
    this.hotkeyHandlers = {
      nextPitch: event => {
        if (this.functions.hotkeyFocusFilter()) {
          event.preventDefault();
          this.functions.nextPitch(event);
        }
      },
      playPauseHandler: event => {
        if (this.functions.hotkeyFocusFilter()) {
          event.preventDefault();
          this.functions.playPauseHandler(event);
        }
      },
      prevPitch: event => {
        if (this.functions.hotkeyFocusFilter()) {
          event.preventDefault();
          this.functions.previousPitch(event);
        }
      },
      stepBackward: event => {
        if (this.functions.hotkeyFocusFilter()) {
          event.preventDefault();
          this.functions.stepBackward(event);
        }
      },
      stepForward: event => {
        if (this.functions.hotkeyFocusFilter()) {
          event.preventDefault();
          this.functions.stepForward(event);
        }
      }
    };
  }

  doesStanceExist(stance, game) {
    if (!game.umpireStances || !game.umpireStances.length) {
      return false;
    }
    return game.umpireStances.indexOf(stance.toUpperCase()) >= 0;
  }

  checkUmpirePitches(gamePk, umpireId, pitches) {
    this.zeApi.checkStrikeZones(gamePk, umpireId, pitches).then(data => {
      this.setUpdatedPitches(data);
    });
  }
}

export default GameViewerStore;
